import { request, URLs } from '../request';

// eslint-disable-next-line import/prefer-default-export
export const getCurrentUserData = () => {
  return (dispatch, getState) => {
    const { user } = getState();

    //! if the user info already exist, no need to triger the dispatch
    if (user !== null) {
      return;
    }

    request
      .POST(URLs.backoffice.CURRENT_USER)
      .then(response => {
        const userInfo = response.data;
        return dispatch({
          type: 'GET_CURRENT_USER',
          data: userInfo,
        });
      })
      .catch(() => {});
  };
};
