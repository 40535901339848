import React, { useState } from 'react';
import CalendarIcon from '@material-ui/icons/CalendarToday';
// import PropTypes from 'prop-types';

const DateInput = props => {
  const [type, changeType] = useState('text');
  return (
    <div style={{ position: 'relative' }}>
      <input
        type={type}
        onFocus={() => changeType('date')}
        onBlur={() => changeType('text')}
        {...props}
      />
      <CalendarIcon
        style={{ position: 'absolute', top: 20, left: 15, fontSize: 14, color: '#9D9BA1' }}
      />
    </div>
  );
};

export default DateInput;
