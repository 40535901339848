import { fontSize, primaryColor } from '../material-dashboard-react';

const Modal = () => ({
  modal: {
    margin: 'auto',
    width: '40%',
    position: 'absolute',
    top: '5%',
  },
  modal_wide: {
    width: '80%',
  },
  mContainer: {
    backgroundColor: '#fff',
    padding: '10px 30px 20px 30px',
    maxHeight: '70%',
    overflow: 'auto',
    paddingTop: 20,
  },
  mHeader: {
    borderBottom: 'solid #e0e0e0 1px',
    alignItems: 'center',
    paddingBottom: 20,
    '& p': {
      margin: 0,
    },
  },
  mTitle: {
    fontSize: fontSize.xxl,
    color: primaryColor,
    fontWeight: '500',
  },
  mSection: {
    margin: '25px 0',
  },
  mLabel: {
    display: 'block',
    color: '#9f9ca2',
    fontSize: fontSize.m,
    marginBottom: 10,
  },
  mMainInput: {
    fontSize: fontSize.m,
    display: 'flex',
    width: '98%',
    border: 'solid 1px #979797',
    borderRadius: 7,
    height: 52,
    padding: '0 10px',
  },
  mSubInput: {
    fontSize: fontSize.m,
    width: '75%',
    border: 'solid 1px #cecece',
    borderRadius: 7,
    height: 40,
    marginBottom: 10,
    padding: '0 10px',
  },
  mSelect: {
    width: '80%',
  },
  mDeleteIcon: {
    fontSize: fontSize.l,
    color: 'red',
    marginRight: 10,
    border: 'none',
    padding: '0px 10px',
    cursor: 'pointer',
  },
  mFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default Modal;
