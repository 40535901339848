import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/core/CustomButtons/Button';
import CardIcon from 'components/core/Card/CardIcon';
import DateInput from 'components/core/dateInput';
// icons
import SearchIcon from '@material-ui/icons/Search';
// constatns
import { request, URLs } from '../../../request';
import CircularProgress from '@material-ui/core/CircularProgress';
import { truncate } from '../../../utils';
import moment from 'moment';
import AuditReportsStyle from '../../../assets/jss/views/AuditReportsStyle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ModalButton from '../../../components/core/CustomButtons/ModalButton';
import AuditReportReplyForm from '../../../components/Forms/AuditReportReplyForm';
import ExportReport from './ExportReport';

class AuditReportList extends React.Component {
  state = {
    agents: [],
    requests: null,
    requestId: null,
    requestStatus: null,
    agentId: null,
    requestFromDate: null,
    requestToDate: null,
    loading: false,
    rejectReasons: [],
    errorMessage: null,
  };

  componentDidMount() {
    this.fetchAgents();
    this.fetchRejectReasons();
  }

  handleSearchClick = () => {
    const { requestId, requestFromDate, requestToDate } = this.state;

    if (!requestId && !requestFromDate && !requestToDate) {
      this.setState({
        requests: null,
        errorMessage: 'يجب إختيار البحث برقم الطلب او البحث بالتاريخ على الأقل',
      });

      return;
    }

    if ((requestFromDate && !requestToDate) || (!requestFromDate && requestToDate)) {
      this.setState({
        requests: null,
        errorMessage: 'يجب ملئ حقلي التاريخ معا',
      });

      return;
    }

    this.setState({
      loading: true,
    });

    request
      .GET(URLs.backoffice.LIST_AUDIT_REQUEST, {
        request_id: this.state.requestId,
        user_id: this.state.agentId !== 'none' ? this.state.agentId : null,
        request_status: this.state.requestStatus !== 'none' ? this.state.requestStatus : null,
        from: this.state.requestFromDate,
        to: this.state.requestToDate,
      })
      .then(res =>
        this.setState({
          requests: res.data.requests,
          loading: false,
        }),
      );
  };

  fetchAgents = () =>
    request
      .GET(URLs.backoffice.AGENTS, {
        only_audit: true,
      })
      .then(res => this.setState({ agents: res.data.users }));

  fetchRejectReasons = () =>
    request.GET(URLs.evisa.REJECTION_REASONS).then(res => {
      this.setState({
        rejectReasons: res.data.reasons,
      });
    });

  handleReportSubmit = auditId => (notes, decision) => {
    request
      .POST(URLs.backoffice.AUDIT_REPORT, {
        audit_request_id: auditId,
        notes,
        decision,
      })
      .then(response => {
        // Immutably change 'report' inside one of the requests inside the 'requests' state
        // for more info lookup https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
        const updatedReport = response.data.report;
        this.setState(prevState => ({
          requests: prevState.requests.map(auditRequest => {
            if (auditRequest.id !== updatedReport.request_id) {
              return auditRequest;
            }

            return {
              ...auditRequest,
              report: updatedReport,
            };
          }),
        }));
      });
  };

  handleStatusChange = name => e => this.setState({ [name]: e.target.value });

  handleFromDateChange = e => {
    const fromDate = e.target.value;
    const shouldClearToDate = moment(fromDate).isAfter(this.state.requestToDate);
    this.setState(prevState => ({
      requestFromDate: fromDate,
      requestToDate: shouldClearToDate ? '' : prevState.requestToDate,
    }));
  };

  printRejectionReason = rejectionId => {
    const rejection = this.state.rejectReasons.find(
      rejectionReason => parseInt(rejectionReason.id, 10) === rejectionId,
    );

    return rejection.label;
  };

  render() {
    const { classes } = this.props;
    const { requests, rejectReasons } = this.state;

    let allReplied = true;
    if (requests) {
      // eslint-disable-next-line array-callback-return
      requests.map(req => {
        if (!req.report) {
          allReplied = false;
        }
      });
    }

    return (
      <Grid container>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              color="info"
              icon
              title="التقارير"
              subtitle="قائمة الطلبات الخاصة بتقرير وزارة الموارد البشرية والتنمية الاجتماعية"
            >
              <CardIcon color="info" isTableIcon>
                <SearchIcon />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <Grid container>
                <GridItem xs={12} sm={6} md={4}>
                  <input
                    className={classes.filterInput}
                    placeholder="رقم الطلب"
                    onChange={this.handleStatusChange('requestId')}
                    value={this.state.requestId}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Select
                    className={[
                      classes.filterInput,
                      classes.select,
                      !this.state.requestStatus ? classes.placeholder : {},
                    ]}
                    onChange={this.handleStatusChange('requestStatus')}
                    value={this.state.requestStatus ? this.state.requestStatus : ''}
                    displayEmpty
                  >
                    <MenuItem disabled selected value="" className={classes.selectItem}>
                      حالة الطلب
                    </MenuItem>
                    <MenuItem value="none" className={classes.selectItem}>
                      جميع الحالات
                    </MenuItem>
                    <MenuItem value="approved" className={classes.selectItem}>
                      مقبول
                    </MenuItem>
                    <MenuItem value="rejected" className={classes.selectItem}>
                      مرفوض
                    </MenuItem>
                  </Select>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Select
                    className={[
                      classes.filterInput,
                      classes.select,
                      !this.state.agentId ? classes.placeholder : {},
                    ]}
                    onChange={this.handleStatusChange('agentId')}
                    value={this.state.agentId ? this.state.agentId : ''}
                    displayEmpty
                  >
                    <MenuItem disabled selected value="" className={classes.selectItem}>
                      الموظف
                    </MenuItem>
                    <MenuItem value="none" className={classes.selectItem}>
                      جميع الموظفين
                    </MenuItem>
                    {this.state.agents.length
                      ? this.state.agents.map(agent => (
                          <MenuItem value={agent.id} className={classes.selectItem}>
                            {agent.name}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </GridItem>
              </Grid>
              <Grid container justify="center">
                <GridItem xs={12} sm={6} md={4}>
                  <DateInput
                    className={classes.filterInput}
                    placeholder="التدقيق من تاريخ"
                    onChange={this.handleFromDateChange}
                    value={this.state.requestFromDate}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <DateInput
                    className={classes.filterInput}
                    placeholder="الى تاريخ"
                    onChange={this.handleStatusChange('requestToDate')}
                    value={this.state.requestToDate}
                    min={this.state.requestFromDate}
                  />
                </GridItem>
              </Grid>
              <Grid container justify="left">
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    className={classes.searchBtn}
                    color="primary"
                    onClick={this.handleSearchClick}
                  >
                    بحث
                  </Button>
                  {requests && requests.length && allReplied ? (
                    <div
                      style={{
                        float: 'left',
                        marginTop: '30px',
                      }}
                    >
                      <ExportReport data={requests} rejectReasons={rejectReasons} />
                    </div>
                  ) : null}
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
          <RequestList
            requests={requests}
            loading={this.state.loading}
            classes={classes}
            truncate={this.truncate}
            printRejectionReason={this.printRejectionReason}
            onReportSubmit={this.handleReportSubmit}
            errorMessage={this.state.errorMessage}
          />
        </GridItem>
      </Grid>
    );
  }
}

const RequestList = ({
  classes,
  loading,
  requests,
  printRejectionReason,
  onReportSubmit,
  errorMessage,
}) => {
  if (loading) {
    return <CircularProgress className={classes.cardTitleEscalation} />;
  }

  if (!requests) {
    return <div>{errorMessage}</div>;
  }

  return (
    <Card>
      <Paper elevation={0}>
        {/* this div will show a horizontal scroll on small screens */}
        <div style={{ width: '100%', overflowX: 'scroll' }}>
          <Table padding="none">
            <TableHead>
              <TableRow>
                <TableCell align="center">رقم الطلب </TableCell>
                <TableCell align="center">حالة الطلب </TableCell>
                <TableCell align="center">تاريخ الطلب </TableCell>
                <TableCell align="center">إجراء المدقق</TableCell>
                <TableCell align="center">السبب</TableCell>
                <TableCell align="center">إجراء تكامل</TableCell>
                <TableCell align="center">ملاحظات تكامل</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.map(auditRequest => {
                const date = new Date(auditRequest.answer.created_at);
                const day = `${date.getDate()}`.padStart(2, '0');
                const month = `${date.getMonth() + 1}`.padStart(2, '0');
                const year = date.getFullYear();
                return (
                  <TableRow key={auditRequest.id}>
                    <TableCell align="center" component="th" scope="row">
                      {auditRequest.request_id}
                    </TableCell>
                    <TableCell align="center">
                      <span
                        className={`${classes.stateTag} ${
                          classes[`status_${auditRequest.request_status}`]
                        }`}
                      >
                        {auditRequest.request_status === 'approved' ? 'مقبول' : 'مرفوض'}
                      </span>
                    </TableCell>
                    <TableCell align="center">{`${year}-${month}-${day}`}</TableCell>
                    <TableCell align="center">
                      <span
                        className={`${classes.stateTag} ${
                          classes[
                            `status_${auditRequest.answer.status === 1 ? 'approved' : 'rejected'}`
                          ]
                        }`}
                      >
                        {auditRequest.answer.status === 1 ? 'صحيح' : 'خاطئ'}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      {auditRequest.answer.rejection_reasons &&
                        auditRequest.answer.rejection_reasons.map(rejectionId => (
                          <div>{printRejectionReason(rejectionId)}</div>
                        ))}
                    </TableCell>
                    <TableCell align="center">
                      {auditRequest.report && (
                        <span
                          className={`${classes.stateTag} ${
                            classes[
                              `status_${
                                auditRequest.report.decision === 1 ? 'approved' : 'rejected'
                              }`
                            ]
                          }`}
                        >
                          {auditRequest.report.decision === 1 ? 'صحيح' : 'خاطئ'}
                        </span>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {auditRequest.report &&
                        auditRequest.report.notes &&
                        truncate(auditRequest.report.notes, 100, true)}
                    </TableCell>
                    <TableCell align="center">
                      <ModalButton classes={classes} type="primary" iconComponent={RateReviewIcon}>
                        <AuditReportReplyForm
                          decision={auditRequest.report && auditRequest.report.decision}
                          notes={auditRequest.report && auditRequest.report.notes}
                          classes={classes}
                          onSubmit={onReportSubmit(auditRequest.id)}
                        />
                      </ModalButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </Card>
  );
};

RequestList.propTypes = {
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  onReportSubmit: PropTypes.func,
  printRejectionReason: PropTypes.func,
  requests: PropTypes.object,
};

AuditReportList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};

const mapStateToProps = state => {
  return {
    auth: {
      userPermissions: state.auth.userPermissions,
      isRoot: state.auth.isRoot,
    },
  };
};

export default compose(
  withStyles(AuditReportsStyle),
  connect(mapStateToProps),
  withRouter,
)(AuditReportList);
