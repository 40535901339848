const AttachmentStyle = () => ({
  attachment: {
    width: 217,
    height: 40,
    borderRadius: 6,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'rgba(88, 171, 200, 0.25)',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px',
    textAlign: 'start',
    cursor: 'pointer',
  },
  active: {
    backgroundColor: 'rgba(88, 171, 200, 0.25)',
  },
  inactive: {
    backgroundColor: '#fff',
  },
  fileIcon: {
    margin: '0 10px 0 5px',
    color: '#fff',
    width: 30,
    height: 35,
  },
  fileName: {
    fontSize: 14,
    color: '#9f9ca2',
    width: 250,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  fileSize: {
    fontSize: 10,
    color: '#9f9ca2',
  },
  downloadIcon: {
    marginRight: 10,
    color: '#58abc8d6',
    cursor: 'pointer',
    width: 25,
    height: 25,
  },
});

export default AttachmentStyle;
