import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import ProcessingRequestsStyle from 'assets/jss/views/ProcessingRequestsStyle';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { request } from '../../request';
import URLs from '../../request/apis';

class RequestLogs extends React.Component {
  state = {
    logs: [],
  };

  componentDidMount() {
    if (this.props.requestId !== '') {
      request
        .GET(URLs.backoffice.PROCESSING_REQUEST_LOGS, { id: this.props.requestId })
        .then(res => {
          this.setState({
            logs: res.data,
          });
        });
    }
  }

  render() {
    const { classes } = this.props;
    //   const { selectedImageId } = this.state;
    return (
      <Card>
        <CardHeader className={classes.header}>
          <p className={classes.title}> الأحداث المتعلقة بهذا الطلب</p>
        </CardHeader>
        <CardBody>
          {this.state.logs.length === 0 ? (
            <h2>لا يوجد</h2>
          ) : (
            <Grid container>
              <GridItem xs={6} sm={5}>
                <h3>الحدث</h3>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <h3>المنفذ</h3>
              </GridItem>
              <GridItem xs={6} sm={3}>
                <h3>الوقت</h3>
              </GridItem>
            </Grid>
          )}
          {this.state.logs.map(log => (
            <Grid container>
              <GridItem xs={6} sm={5}>
                {log.tags === null ? <span>{log.event}</span> : <span>{log.tags}</span>}
              </GridItem>
              <GridItem xs={6} sm={4}>
                {log.user ? <span>{log.user.name}</span> : <span>EVisa</span>}
              </GridItem>
              <GridItem xs={6} sm={3}>
                <div style={{ direction: 'ltr', textAlign: 'right' }}>{log.created_at}</div>
              </GridItem>
            </Grid>
          ))}
        </CardBody>
      </Card>
    );
  }
}

RequestLogs.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  requestId: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(ProcessingRequestsStyle),
  connect(mapStateToProps),
)(RequestLogs);
