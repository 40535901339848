const initState = JSON.parse(localStorage.getItem('auth') || '{}');

const reducer = (state = initState, { type, data }) => {
  switch (type) {
    case 'VALIDATE_AUTH':
      return { tempToken: data };
    case 'LOGIN':
      return data;
    case 'REFRESH_TOKEN':
      return { ...state, token: data };
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};
export default reducer;
