import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Email from '@material-ui/icons/Email';
import LockOutline from '@material-ui/icons/LockOutlined';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import CardFooter from 'components/core/Card/CardFooter';
import LoginStyle from 'assets/jss/views/LoginStyle';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import axios from 'axios';
import compose from 'recompose/compose';
import Swal from 'sweetalert2';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordConf: '',
    };

    this.handleEmailResetChange = this.handleEmailResetChange.bind(this);
    this.handlePasswordResetChange = this.handlePasswordResetChange.bind(this);
    this.handlePasswordResetConfChange = this.handlePasswordResetConfChange.bind(this);
    this.handleSearchTermSubmit = this.handleSearchTermSubmit.bind(this);
  }

  handleEmailResetChange(evt) {
    this.setState({ email: evt.target.value });
  }

  handlePasswordResetChange(evt) {
    this.setState({ password: evt.target.value });
  }

  handlePasswordResetConfChange(evt) {
    this.setState({ passwordConf: evt.target.value });
  }

  handleSearchTermSubmit() {
    const email = this.state.email;
    const password = this.state.passwordConf;
    const passwordConfirmation = this.state.password;

    const url = process.env.REACT_APP_API_URL;

    const token = window.location.href.split('/').pop();

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    };

    axios
      .post(
        `${url}/v1/password/reset`,
        {
          token,
          email,
          password,
          password_confirmation: passwordConfirmation,
        },
        axiosConfig,
      )
      .then(() => {
        Swal.fire({
          title: 'تم انشاء الحساب',
          text:
            'تم إنشاء وتهيئة كلمة المرور بنجاح, الرجاء الذهاب الى الصفحة الرئيسية للدخول على حسابك',
          type: 'success',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'حسنا',
        }).then(() => {
          this.props.history.push('/');
        });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>إعادة ضبط كلمة المرور</h4>
            </CardHeader>
            <CardBody>
              <Grid container>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    label="البريد الإلكتروني"
                    id="emailReset"
                    type="email"
                    fullWidth
                    margin="normal"
                    onChange={this.handleEmailResetChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    label="الرقم السري"
                    id="passwordReset"
                    type="password"
                    fullWidth
                    margin="normal"
                    onChange={this.handlePasswordResetChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutline />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    label="تأكيد الرقم السري"
                    id="passwordResetConf"
                    type="password"
                    fullWidth
                    margin="normal"
                    onChange={this.handlePasswordResetConfChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutline />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </Grid>
            </CardBody>
            <CardFooter plain>
              <Button color="primary" onClick={this.handleSearchTermSubmit}>
                حسناً
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </Grid>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};
export default compose(
  withStyles(LoginStyle),
  connect(),
)(ResetPassword);
