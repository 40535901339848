import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import SubmitButton from 'components/core/CustomButtons/SubmitButton';
import CardActions from '@material-ui/core/es/CardActions/CardActions';
import TextField from '@material-ui/core/TextField';
import GridItem from 'components/core/Grid/GridItem';
import { FormControl } from '@material-ui/core';

class QualityEscalationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
    };
  }

  handleChange = event => {
    this.setState({
      note: event.target.value,
    });
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.note);
    this.props.onCancel();
  };

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader className={classes.header}>
          <p className={classes.title}> تصعيد للجودة </p>
        </CardHeader>

        <CardBody className={classes.modalContainer}>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl className={classes.row}>
              <TextField label="سبب التصعيد" value={this.state.note} onChange={this.handleChange} />
            </FormControl>
          </GridItem>
        </CardBody>
        <CardActions>
          <GridItem xs={12} sm={12} md={12}>
            <SubmitButton color="primary" onClick={this.handleSubmit}>
              ارسال
            </SubmitButton>
          </GridItem>
        </CardActions>
      </Card>
    );
  }
}

QualityEscalationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default QualityEscalationForm;
