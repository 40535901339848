import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/core/Card/Card';
import CardBody from 'components/core/Card/CardBody';
import CardHeader from 'components/core/Card/CardHeader';

const RequestDetails = ({ classes, title, id, userDetails, disabilityDetails }) => (
  <Card>
    <CardHeader className={classes.header}>
      <p className={classes.title}>{`${title} ( ${id} )`}</p>
    </CardHeader>
    <CardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div className={classes.infoContainer}>
        <p className={classes.label}>اسم مقدم الطلب</p>
        <p className={classes.data}>{userDetails.name}</p>
      </div>
      <div className={classes.infoContainer}>
        <p className={classes.label}>رقم الهوية/ الإقامة</p>
        <p className={classes.data}>{userDetails.id_number}</p>
      </div>
      <div className={classes.infoContainer}>
        <p className={classes.label}>الجنسية</p>
        <p className={classes.data}>{userDetails.nationality}</p>
      </div>
      <div className={classes.infoContainer}>
        <p className={classes.label}>وظيفة مقدم الطلب</p>
        <p className={classes.data}>{userDetails.job}</p>
      </div>
      {parseInt(userDetails.premium_residency, 8) ? (
        <div className={classes.infoContainer}>
          <p className={classes.label}>من حاملي الإقامة المميزة</p>
          <p className={classes.data}>
            {userDetails.premium_residency === '1' ? 'دائمة' : 'مؤقتة'}
          </p>
        </div>
      ) : null}
      {disabilityDetails.isDisable && (
        <div className={classes.infoContainer}>
          <p className={classes.label}>من ذوي الاحتياجات الخاصة</p>
          <p className={classes.data}> نعم </p>
        </div>
      )}
      {disabilityDetails.isDisable && (
        <div className={classes.infoContainer}>
          <p className={classes.label}>اسم الوكيل</p>
          <p className={classes.data}>{disabilityDetails.responsibleName || '-'}</p>
        </div>
      )}
      {disabilityDetails.isDisable && (
        <div className={classes.infoContainer}>
          <p className={classes.label}>رقم هوية الوكيل</p>
          <p className={classes.data}>{disabilityDetails.responsibleId || '-'}</p>
        </div>
      )}
    </CardBody>
  </Card>
);

RequestDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  disabilityDetails: PropTypes.shape({
    isDisable: PropTypes.bool.isRequired,
    responsibleId: PropTypes.string || PropTypes.number,
    responsibleName: PropTypes.string,
  }).isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  userDetails: PropTypes.shape({
    id_number: PropTypes.string.isRequired || PropTypes.number.isRequired,
    job: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
  }).isRequired,
};

export default RequestDetails;
