import EscalatedRequestsStyle from './EscalatedRequestsStyle';
import { primaryColor } from '../material-dashboard-react';

const DetailsStyle = () => ({
  ...EscalatedRequestsStyle(),
  wide: {
    width: '100%',
    minWidth: 296,
  },
  dataContainer: {
    width: '30%',
    paddingRight: 50,
    margin: 0,
    minWidth: 320,
    boxSizing: 'border-box',
  },
  backBtn: {
    border: 'solid 2px',
    borderColor: primaryColor,
    borderRadius: 4,
    backgroundColor: '#fff',
    color: primaryColor,
    height: 40,
  },
});

export default DetailsStyle;
