import React from 'react';
import PropTypes from 'prop-types';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import Footer from 'components/core/Footer/Footer';
import Sidebar from 'components/core/Sidebar/Sidebar';
import dashboardStyle from 'assets/jss/layouts/dashboardStyle';
import logo from 'assets/img/reactlogo.png';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { createBrowserHistory } from 'history';
// views
import AuditSystemDashboard from 'views/AuditSystem/Dashboard';
import DashboardPage from 'views/Dashboard/Dashboard';
import ListUsers from 'views/Users/ListUsers';
import Inquiries from 'views/Inquiries/Inquiries';
import Details from 'views/Inquiries/Details';
import DLDetails from 'views/Inquiries/DLDetails';
import AddUser from 'views/Users/AddUser';
import EditUser from 'views/Users/EditUser';
import ProcessingRequests from 'views/ProcessingRequests/Requests';
import Login from 'views/Login/Login';
import EscalationReasons from 'views/EscalationReasons/ListEscalationReasons';
import EscalatedRequests from 'views/EscalationRequests/ListEscalatedRequests';
import QualityRequests from 'views/QualityRequests/ListQualityRequests';
import BlockReasons from 'views/BlockReasons/ListBlockReasons';
import BlockList from 'views/BlockList/BlockList';
import GoogleAuthentication from './views/GoogleAuthentication/GoogleAuthentication';
// utils
import { isAuthorized, isInSystem } from './utils';
import ProcessingQualityRequests from './views/QualityRequests/ProcessingQualityRequests';
// eslint-disable-next-line max-len
import EscalatedRequestsGuard from './views/EscalationRequests/EscalatedRequestsGuard';
import ModifyRanges from './views/ModifyRanges/ModifyRanges';
import UserDetails from './views/Users/UserDetails';
import ProcessingInquiryRequest from './views/Inquiries/ProcessingRequest/ProcessingInquiryRequest';
import AuditReportList from './views/AuditSystem/Reporting/AuditReportList';
import Ind2IndDetails from './views/Inquiries/Ind2IndDetails';

const hist = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.panelRef = React.createRef();
  }

  state = {
    mobileOpen: false,
  };

  onHandleDrawerToggle = () => {
    this.setState(prev => ({ mobileOpen: prev.mobileOpen }));
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper}>
        <Router history={hist}>
          <React.Fragment>
            {this.props.auth.accessToken ? (
              <Sidebar
                logoText={
                  isInSystem('backoffice', this.props.system)
                    ? 'مكتب معالجة طلبات الأفراد'
                    : 'نظام تدقيق الطلبات'
                }
                logo={logo}
                handleDrawerToggle={this.onHandleDrawerToggle}
                open={this.state.mobileOpen}
                color="blue"
                {...rest}
              />
            ) : null}

            <Switch>
              <Route
                path="/"
                render={() =>
                  this.props.auth.accessToken ? (
                    <MainStack classes={classes} panelRef={this.panelRef} {...rest} />
                  ) : (
                    <AuthStack classes={classes} {...rest} />
                  )
                }
                key="app"
              />
            </Switch>
          </React.Fragment>
        </Router>
      </div>
    );
  }
}

const AuthStack = props => (
  <React.Fragment>
    <Switch>
      <Route
        path="/login"
        render={navProps =>
          isAuthorized('login', props.auth) ? (
            <Redirect to="/" />
          ) : (
            <div className={props.classes.loginPanel}>
              <Login {...navProps} />
            </div>
          )
        }
        key="login"
      />
      <Redirect to="/login" />
    </Switch>
    <Footer />
  </React.Fragment>
);

const MainStack = props => (
  <div className={props.classes.mainPanel} ref={props.panelRef}>
    <div className={props.classes.content}>
      <Switch>
        <Route
          path={['/', '/dashboard']}
          render={navProps => {
            if (isInSystem('backoffice', props.system) && isAuthorized('dashboard', props.auth)) {
              return <DashboardPage {...navProps} />;
            }
            if (isInSystem('audit', props.system)) {
              return <AuditSystemDashboard {...navProps} />;
            }
            return <Redirect to="/login" />;
          }}
          key="dashboard"
          exact
        />
        <Route
          path="/users"
          render={navProps =>
            isAuthorized('users', props.auth) && isInSystem('backoffice', props.system) ? (
              <ListUsers {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="users"
          exact
        />
        <Route
          path="/inquiries/:id/process"
          render={navProps =>
            isAuthorized('showRequests', props.auth) && isInSystem('backoffice', props.system) ? (
              <ProcessingInquiryRequest {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="inquiries"
          exact
        />
        <Route
          path="/inquiries"
          render={navProps =>
            isAuthorized('showRequests', props.auth) && isInSystem('backoffice', props.system) ? (
              <Inquiries {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="inquiries"
          exact
        />
        <Route
          path="/inquiries/list/:page?"
          render={navProps =>
            isAuthorized('showRequests', props.auth) && isInSystem('backoffice', props.system) ? (
              <Inquiries {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="inquiries"
          exact
        />
        <Route
          path="/inquiries/show/:id"
          render={navProps =>
            isAuthorized('showRequests', props.auth) && isInSystem('backoffice', props.system) ? (
              <Details {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="inquiries"
          exact
        />
        <Route
          path="/inquiries/dl/:id"
          render={navProps =>
            isAuthorized('showRequests', props.auth) && isInSystem('backoffice', props.system) ? (
              <DLDetails {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="inquiries"
          exact
        />
        <Route
          path="/inquiries/ind2ind/:id"
          render={navProps =>
            isAuthorized('showRequests', props.auth) && isInSystem('backoffice', props.system) ? (
              <Ind2IndDetails {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="inquiries"
          exact
        />
        <Route
          path="/users/add"
          render={navProps =>
            isAuthorized('users', props.auth) && isInSystem('backoffice', props.system) ? (
              <AddUser {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="addUsers"
          exact
        />
        <Route
          path="/users/:id"
          render={navProps =>
            isAuthorized('users', props.auth) && isInSystem('backoffice', props.system) ? (
              <UserDetails {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="users"
          exact
        />
        <Route
          path="/users/edit/:id"
          render={navProps =>
            isAuthorized('users', props.auth) && isInSystem('backoffice', props.system) ? (
              <EditUser {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="editUsers"
        />
        <Route
          path="/processingRequests"
          render={navProps =>
            isAuthorized('processingRequests', props.auth) &&
            isInSystem('backoffice', props.system) ? (
              <ProcessingRequests {...navProps} type="VISA" title="طلب إصدار تأشيرة" />
            ) : (
              <Redirect to="/" />
            )
          }
          key="processingRequests"
        />
        <Route
          path="/noticesRequests"
          render={navProps =>
            isAuthorized('noticesRequests', props.auth) &&
            isInSystem('backoffice', props.system) ? (
              <ProcessingRequests {...navProps} type="NOTICE" title="طلب إصدار إشعار" />
            ) : (
              <Redirect to="/" />
            )
          }
          key="noticesRequests"
        />
        <Route
          path="/processingEscalated/:id"
          render={navProps =>
            isAuthorized('escalationRequests', props.auth) &&
            isInSystem('backoffice', props.system) ? (
              <EscalatedRequestsGuard {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="processingEscalated"
        />
        <Route
          path="/escalationReasons"
          render={navProps =>
            isAuthorized('escalationReasons', props.auth) &&
            isInSystem('backoffice', props.system) ? (
              <EscalationReasons {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="escalationReasons"
          exact
        />
        <Route
          path="/blockReasons"
          render={navProps =>
            isAuthorized('blockReasons', props.auth) && isInSystem('backoffice', props.system) ? (
              <BlockReasons {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="blockReasons"
          exact
        />
        <Route
          path="/blockList"
          render={navProps =>
            isAuthorized('blockList', props.auth) && isInSystem('backoffice', props.system) ? (
              <BlockList {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="blockList"
        />
        <Route
          path="/escalationRequests"
          render={navProps =>
            isAuthorized('escalationRequests', props.auth) &&
            isInSystem('backoffice', props.system) ? (
              <EscalatedRequests {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="escalationRequests"
        />
        <Route
          exact
          path="/quality"
          render={navProps =>
            isAuthorized('qualityRequests', props.auth) &&
            isInSystem('backoffice', props.system) ? (
              <QualityRequests {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="quality"
        />
        <Route
          path="/quality/:id"
          render={navProps =>
            isAuthorized('qualityRequests', props.auth) &&
            isInSystem('backoffice', props.system) ? (
              <ProcessingQualityRequests {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="quality"
        />
        <Route
          path="/dl"
          render={navProps =>
            isAuthorized('dlRequests', props.auth) && isInSystem('backoffice', props.system) ? (
              <ProcessingRequests {...navProps} type="DL_TRANSFER" title="طلب نقل خدمات" />
            ) : (
              <Redirect to="/" />
            )
          }
          key="dl"
        />
        <Route
          path="/ranges"
          render={navProps =>
            isAuthorized('modifyRanges', props.auth) && isInSystem('backoffice', props.system) ? (
              <ModifyRanges {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="modifyRanges"
        />
        <Route
          path="/audit/reports"
          render={navProps =>
            isAuthorized('auditReports', props.auth) && isInSystem('audit', props.system) ? (
              <AuditReportList {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="auditReports"
        />
        <Route
          path="/googleAuthentication"
          render={navProps =>
            isInSystem('backoffice', props.system) ? (
              <GoogleAuthentication {...navProps} />
            ) : (
              <Redirect to="/" />
            )
          }
          key="googleAuthentication"
          exact
        />
      </Switch>
    </div>
    <Footer />
  </div>
);

const mapStateToProps = state => {
  return {
    auth: {
      accessToken: state.auth.token,
      userPermissions: state.auth.userPermissions,
      isRoot: state.auth.isRoot,
    },
    system: state.system,
  };
};

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps),
)(App);

App.propTypes = {
  accessToken: PropTypes.array,
  auth: PropTypes.object,
  classes: PropTypes.object.isRequired,
  system: PropTypes.object,
};

AuthStack.propTypes = {
  auth: PropTypes.object,
  classes: PropTypes.object,
  system: PropTypes.object,
};

MainStack.propTypes = {
  auth: PropTypes.object,
  classes: PropTypes.object,
  panelRef: PropTypes.any,
  system: PropTypes.object,
};
