import axios from 'axios';
import URLs from './apis';
import { getToken } from '../utils';
import { dispatch } from '../store';
import { REFRESH_TOKEN } from '../actions';
import handleError, { CODES } from './errors';

const BASES = {
  EVISA_BASE: 'EVISA_BASE',
  BACKOFFICE_BASE: 'BACKOFFICE_BASE',
};

export const BASES_URLS = {
  [BASES.EVISA_BASE]: process.env.REACT_APP_EVISA_URL,
  [BASES.BACKOFFICE_BASE]: process.env.REACT_APP_API_URL,
};

const methods = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
};

const axiosWithNoErrorHandling = axios.create();

axiosWithNoErrorHandling.interceptors.response.use(response => {
  const token = response.headers['x-auth-token'];
  if (token) {
    dispatch(REFRESH_TOKEN(token));
  }
  return response;
});

axios.interceptors.response.use(
  response => {
    const token = response.headers['x-auth-token'];
    if (token) {
      dispatch(REFRESH_TOKEN(token));
    }
    return response;
  },
  error => {
    const err = handleError(error);
    return Promise.reject(err);
  },
);

export const templateEngine = (tpl, data) => {
  const re = /{([^}]*)?}/;
  let match;
  /* eslint-disable */
  while ((match = re.exec(tpl))) {
    tpl = tpl.replace(match[0], data[match[1]] || '');

    // to prevent sending empty queries
    if (!data[match[1]]) {
      tpl = tpl.replace(`${match[1]}=`, '');
      tpl = tpl.replace('&&', '&');
      tpl = tpl.replace('?&', '?');
    }
  }
  if (tpl.charAt(tpl.length - 1) === '/') {
    tpl = tpl.substr(0, tpl.length - 1);
  }
  tpl = tpl.replace('/?', '?');
  /* eslint-enable */
  return tpl;
};

const send = (method, url, d, withErrorHandling = true) => {
  if (!url) {
    throw new Error(
      'URL PASSED TO THE REQUEST METHOD IS UNDEFINED. HINT: MAKE SURE THE SPELLING IS CORRECT',
    );
  }
  const client = withErrorHandling ? axios : axiosWithNoErrorHandling;
  const token = getToken();
  const headers = token ? { 'x-auth-token': token, authorization: `Bearer ${token}` } : null;
  const data = d || {};
  const fullURL = templateEngine(url, { ...data, ...BASES_URLS });
  if (method === methods.POST || method === methods.PUT) {
    return client[method](fullURL, data, { headers });
  }
  return client[method](fullURL, {
    headers,
  });
};

const sendPost = (url, data, withErrorHandling = true) =>
  send(methods.POST, url, data, withErrorHandling);

const sendGet = (url, params, withErrorHandling = true) =>
  send(methods.GET, url, params, withErrorHandling);

const sendPut = (url, data, withErrorHandling = true) =>
  send(methods.PUT, url, data, withErrorHandling);

const sendDelete = (url, params, withErrorHandling = true) =>
  send(methods.DELETE, url, params, withErrorHandling);

export const prepareURL = (url, d) => {
  const token = getToken();
  const data = d || {};
  let fullURL = templateEngine(url, { ...data, ...BASES_URLS });
  fullURL = token ? `${fullURL}${url.indexOf('?') === -1 ? '?' : '&'}token=${token}` : token;
  return fullURL;
};

export const request = {
  GET: sendGet,
  POST: sendPost,
  PUT: sendPut,
  DELETE: sendDelete,
};

const getUploadUrl = (fileId, userId) =>
  `${
    process.env.REACT_APP_EVISA_URL
  }/api/download/${fileId}?token=${getToken()}&userId=${userId}&output=embed`;

export { URLs, CODES, getUploadUrl };
