import ModalStyle from '../core/modalStyle';
import ProcessingRequestsStyle from './ProcessingRequestsStyle';

const QualityRequestsStyle = () => ({
  ...ModalStyle(),
  ...ProcessingRequestsStyle(),
  blockReasonSelect: {
    width: '80%',
  },
  note: {
    width: '80%',
  },
  filterLabel: {
    marginBottom: 5,
    color: 'grey',
  },
});

export default QualityRequestsStyle;
