import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// import ZoomIn from '@material-ui/icons/ZoomIn';
import ProcessingRequestsStyle from 'assets/jss/views/ProcessingRequestsStyle';
// utils
import {
  getRequestAttachmentsFromHistoryWithoutDuplication,
  prepareRequestTypeLabel,
} from 'utils/processing_requests';
// core components
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { request, URLs } from '../../request';
// sections components
import CurrentRequest from './currentEscalation';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import PageTaps from '../../components/core/CustomTabs/PageTaps';
import PreviousRequests from '../../components/Requests/PreviousRequests';

class ProcessingRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInformation: [],
      visaInformation: [],
      history: [],
      requestId: '',
      loading: true,
      rejectReasons: [],
      escalatedRequest: null,
      attachments: [],
    };
  }

  componentDidMount() {
    this.fetchRequestInfo();
    this.fetchRejectReasons();
  }

  fetchRequestInfo = () => {
    const id = this.props.match.params.id;

    request.GET(URLs.backoffice.PROCESS_ESCALATION, { id }).then(information => {
      const attachmentsIds = JSON.parse(information.data.attachments[0].uploads_IDs);
      const userInfo = JSON.parse(information.data.ProcessingRequest.user_info);
      const user = Array.isArray(userInfo.user) ? userInfo.user[0] : userInfo.user;
      const userInformation = {
        ...userInfo,
        ...user,
      };
      delete userInformation.user;

      const visaInformation = JSON.parse(information.data.ProcessingRequest.visa_info);
      const type = information.data.ProcessingRequest.type;
      this.setState({
        userInformation,
        requestId: information.data.ProcessingRequest.id,
        escalatedRequest: information.data.EscalatedRequest,
        refrenceId: information.data.ProcessingRequest.reference_id,
        category: information.data.Category,
        reasons: information.data.CategoryReasons,
        visaInformation,
        type,
        loading: false,
        dateCreated: information.data.EscalatedRequest.created_at,
        dateEscalated: information.data.ProcessingRequest.created_at,
        isDisable:
          !!visaInformation.isDisable && Object.keys(visaInformation.isDisable).length !== 0,
      });

      request
        .GET(URLs.backoffice.ATTACHMENTS_HISTORY, {
          user_id: userInformation.id_number,
          type,
        })
        .then(history => {
          this.setState({
            history: history.data,
            attachments: getRequestAttachmentsFromHistoryWithoutDuplication(
              history,
              attachmentsIds,
            ),
          });
        });
    });
  };

  fetchRejectReasons = () =>
    request.GET(URLs.evisa.REJECTION_REASONS).then(res => {
      this.setState({
        rejectReasons: res.data.reasons,
      });
    });

  render() {
    const { classes } = this.props;
    const pages = [];
    pages.push({
      title: 'الطلب الحالي',
      content: (
        <CurrentRequest
          requestId={this.state.requestId}
          refrenceId={this.state.refrenceId}
          userInformation={this.state.userInformation}
          visaInformation={this.state.visaInformation}
          category={this.state.category}
          reasons={this.state.reasons}
          attachments={this.state.attachments}
          isDisable={this.state.isDisable}
          rejectReasons={this.state.rejectReasons}
          escalatedRequest={this.state.escalatedRequest}
          dateCreated={this.state.dateCreated}
          dateEscalated={this.state.dateEscalated}
        />
      ),
    });
    pages.push({
      title: 'الطلبات السابقة',
      content: (
        <PreviousRequests
          classes={classes}
          requests={this.state.history}
          user={this.state.userInformation}
        />
      ),
    });
    return this.state.loading ? (
      <CircularProgress className={classes.progress} />
    ) : (
      <PageTaps
        title={`طلب ${prepareRequestTypeLabel(this.state.type)}`}
        pages={pages}
        classes={classes}
      />
    );
  }
}

ProcessingRequests.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(ProcessingRequestsStyle),
  connect(mapStateToProps),
)(ProcessingRequests);
