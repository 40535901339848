import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// core components
import GridItem from 'components/core/Grid/GridItem';
import { PERMISSIONS } from '../../constants';

const PermissionForm = ({ permissions, onChange }) => (
  <FormControl component="fieldset">
    <Grid container spacing={3}>
      <GridItem>
        <FormGroup>
          <FormLabel component="legend">المعالجة</FormLabel>
          <Permission
            permission={PERMISSIONS.DL_PROCESSING}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            permission={PERMISSIONS.PROCESS_PROCESSING_REQUESTS}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            permission={PERMISSIONS.PROCESS_NOTICE_PROCESSING_REQUESTS}
            permissions={permissions}
            onChange={onChange}
          />
        </FormGroup>
      </GridItem>
      <GridItem>
        <FormGroup>
          <FormLabel component="legend">التصعيد</FormLabel>
          <Permission
            permission={PERMISSIONS.PROCESS_ESCALATED_PROCESSING_REQUESTS}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            permission={PERMISSIONS.QUALITY_PROCESSING}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            permission={PERMISSIONS.MANAGE_ESCALATION_REASONS}
            permissions={permissions}
            onChange={onChange}
          />
        </FormGroup>
      </GridItem>
      <GridItem>
        <FormGroup>
          <FormLabel component="legend">الإستعلامات</FormLabel>
          <Permission
            permission={PERMISSIONS.SEARCH_ISSUE_REQUESTS}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            disabled={!permissions.includes(PERMISSIONS.SEARCH_ISSUE_REQUESTS.KEY)}
            permission={PERMISSIONS.INQUIRY_PROCESSING}
            permissions={permissions}
            onChange={onChange}
          />
        </FormGroup>
      </GridItem>
      <GridItem>
        <FormGroup>
          <FormLabel component="legend">إدارة المستخدمين</FormLabel>
          <Permission
            permission={PERMISSIONS.CREATE_USERS}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            permission={PERMISSIONS.ACTIVATE_DEACTIVATE_USERS}
            permissions={permissions}
            onChange={onChange}
          />
        </FormGroup>
      </GridItem>
      <GridItem>
        <FormGroup>
          <FormLabel component="legend">إعدادات عامة</FormLabel>
          <Permission
            permission={PERMISSIONS.MANAGE_BLOCKING_REASONS}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            permission={PERMISSIONS.BLOCK_CITIZENS}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            permission={PERMISSIONS.MODIFY_RANGES}
            permissions={permissions}
            onChange={onChange}
          />
        </FormGroup>
      </GridItem>
      <GridItem>
        <FormGroup>
          <FormLabel component="legend">نظام التدقيق</FormLabel>
          <Permission
            permission={PERMISSIONS.AUDIT_PROCESSING}
            permissions={permissions}
            onChange={onChange}
          />
          <Permission
            permission={PERMISSIONS.AUDIT_REPORTS}
            permissions={permissions}
            onChange={onChange}
          />
        </FormGroup>
      </GridItem>
    </Grid>
  </FormControl>
);

const Permission = ({ permissions, permission, onChange, disabled = false }) => (
  <FormControlLabel
    control={
      <Checkbox
        disabled={disabled}
        checked={permissions.includes(permission.KEY)}
        onChange={onChange(permission.KEY)}
        value={permission.KEY}
      />
    }
    label={permission.LABEL}
  />
);

PermissionForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
};

Permission.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    KEY: PropTypes.string.isRequired,
    LABEL: PropTypes.string.isRequired,
  }).isRequired,
  permissions: PropTypes.array.isRequired,
};

export default PermissionForm;
