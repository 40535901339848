import InquiriesStyle from './InquiriesStyle';

const DLTransferListStyle = () => ({
  status_WAITING_EMPLOYER_RESPONSE: {
    backgroundColor: '#189AB4',
  },
  status_WAITING_FINANCIAL_DOCUMENTS: {
    backgroundColor: '#189AB4',
  },
  status_PENDING_BACKOFFICE: {
    backgroundColor: '#FFE652',
  },
  status_QUALITY: {
    backgroundColor: '#F8CB2E',
  },
  status_ESCALATED: {
    backgroundColor: '#FFD36E',
  },
  status_REJECTED: {
    backgroundColor: '#B22727',
  },
  status_REJECTED_FOR_FINANCIAL_INELIGIBILITY: {
    backgroundColor: '#B22727',
  },
  status_VALID: {
    backgroundColor: '#59981A',
  },
  status_CANCELLED: {
    backgroundColor: '#EE5007',
  },
  status_UNDER_FINAL_REVIEW: {
    backgroundColor: '#81B622',
  },
  status_COMPLETE: {
    backgroundColor: '#3D550C',
  },
  status_WAITING_PAYMENT: {
    backgroundColor: '#189AB4',
  },
  status_PAYMENT_EXPIRED: {
    backgroundColor: '#541690',
  },
  status_WAITING_ELIGIBILITY_CORRECTION: {
    backgroundColor: '#189AB4',
  },
  status_CANCELLED_FOR_INELIGIBILITY: {
    backgroundColor: '#541690',
  },
  status_RESPONSE_EXPIRED: {
    backgroundColor: '#EE5007',
  },
  status_CANCELLED_FOR_INCOMPLETE_PROCEDURES: {
    backgroundColor: '#EE5007',
  },
  status_WAITING_BACKOFFICE: {
    backgroundColor: '#189AB4',
  },
  status_WAITING_LABORER_APPROVAL: {
    backgroundColor: '#189AB4',
  },
  status_WAITING_EMPLOYER_APPROVAL: {
    backgroundColor: '#189AB4',
  },
  status_EXPIRED_LABORER_APPROVAL: {
    backgroundColor: '#EE5007',
  },
  status_EXPIRED_EMPLOYER_APPROVAL: {
    backgroundColor: '#EE5007',
  },
  status_EXPIRED_FINANCIAL_DOCUMENTS: {
    backgroundColor: '#EE5007',
  },
  status_EXPIRED_PAYMENT: {
    backgroundColor: '#EE5007',
  },
  status_REJECTED_LABORER_APPROVAL: {
    backgroundColor: '#EE5007',
  },
  status_REJECTED_EMPLOYER_APPROVAL: {
    backgroundColor: '#EE5007',
  },
  status_REJECTED_BACKOFFICE: {
    backgroundColor: '#EE5007',
  },
  status_PENDING_HRSD: {
    backgroundColor: '#189AB4',
  },
  status_REJECTED_HRSD: {
    backgroundColor: '#EE5007',
  },
  status_PENDING_CONFIRMATION: {
    backgroundColor: '#189AB4',
  },
  status_COMPLETED: {
    backgroundColor: '#81B622',
  },
  ...InquiriesStyle(),
});

// Green gradiant is for success statuses (complete + valid)
// yellow gradiant is for waiting statuses
// red graidant is for failiure statuses

export default DLTransferListStyle;
