import React from 'react';
import PropTypes from 'prop-types';
import { request, URLs } from '../../request';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import dashboardStyle from 'assets/jss/views/dashboardStyle';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

class Dashboard extends React.Component {
  state = {};

  async componentDidMount() {
    const res = await request.GET(URLs.backoffice.DASHBOARD);
    /* eslint-disable-next-line no-unused-expressions */
    res.data &&
      this.setState({
        averageReponseTime: Math.round(res.data.average_response_time / 60),
        dailyAccomplishment: res.data.daily_accomplishment_for_the_user,
        totalPendingRequests: res.data.total_postponed_requests,
        totalEscalated: res.data.total_escalated_requests,
      });
  }

  render() {
    const { classes } = this.props;
    const {
      totalPendingRequests,
      totalEscalated,
      averageReponseTime,
      dailyAccomplishment,
    } = this.state;
    return (
      <React.Fragment>
        <div className={classes.dashboardTitle}>
          <h2>اللوحة الرئيسية</h2>
        </div>
        <Grid container>
          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitlePending}`}>إجمالي طلبات إصدار التأشيرات المعلقة</p>
              <PendingProcessingRequestsList
                classes={this.props.classes}
                requestType="VISA"
                totalPendingRequests={totalPendingRequests}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitlePending}`}>إجمالي طلبات نقل خدمات من فرد لفرد</p>
              <PendingProcessingRequestsList
                classes={this.props.classes}
                requestType="DLT_INDIVIDUALS"
                totalPendingRequests={totalPendingRequests}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitlePending}`}>إجمالي طلبات نقل خدمات من منشأة لفرد</p>
              <PendingProcessingRequestsList
                classes={this.props.classes}
                requestType="DL_TRANSFER"
                totalPendingRequests={totalPendingRequests}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitlePending}`}>إجمالي طلبات إصدار الإشعارات المعلقة</p>
              <PendingProcessingRequestsList
                classes={this.props.classes}
                requestType="NOTICE"
                totalPendingRequests={totalPendingRequests}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitlePending}`}>
                معدل الإنجاز اليومي لطلبات إصدار التأشيرات
              </p>
              <UserAchievementsList
                classes={this.props.classes}
                requestType="VISA"
                dailyAccomplishment={dailyAccomplishment}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitlePending}`}>إجمالي طلبات نقل خدمات من فرد لفرد</p>
              <UserAchievementsList
                classes={this.props.classes}
                requestType="DLT_INDIVIDUALS"
                dailyAccomplishment={dailyAccomplishment}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitlePending}`}>إجمالي طلبات نقل خدمات من منشأة لفرد</p>
              <UserAchievementsList
                classes={this.props.classes}
                requestType="DL_TRANSFER"
                dailyAccomplishment={dailyAccomplishment}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitlePending}`}>
                معدل الإنجاز اليومي لطلبات إصدار الإشعارات
              </p>
              <UserAchievementsList
                classes={this.props.classes}
                requestType="NOTICE"
                dailyAccomplishment={dailyAccomplishment}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitle} ${classes.cardTitleProcessing}`}>
                {averageReponseTime || averageReponseTime === 0 ? (
                  `${averageReponseTime}`
                ) : (
                  <CircularProgress className={classes.cardTitleProcessing} />
                )}
              </p>
              <p className={classes.cardCategory}>متوسط وقت معالجة الطلبات</p>
              <hr style={{ width: '80%', margin: '10px 0' }} />
              <p className={`${classes.cardTitle} ${classes.cardTitleEscalation}`}>
                {totalEscalated || totalEscalated === 0 ? (
                  totalEscalated
                ) : (
                  <CircularProgress className={classes.cardTitleEscalation} />
                )}
              </p>
              <p className={classes.cardCategory}>إجمالي الطلبات المصعدة</p>
            </Card>
          </GridItem>
        </Grid>
      </React.Fragment>
    );
  }
}

const PendingProcessingRequestsList = ({ classes, requestType, totalPendingRequests }) => (
  <div>
    {totalPendingRequests ? (
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="غير مسند"
            secondary={
              typeof totalPendingRequests[requestType] !== 'undefined' &&
              typeof totalPendingRequests[requestType].initial !== 'undefined'
                ? totalPendingRequests[requestType].initial[0].total
                : 0
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="مسند"
            secondary={
              typeof totalPendingRequests[requestType] !== 'undefined' &&
              typeof totalPendingRequests[requestType].assigned !== 'undefined'
                ? totalPendingRequests[requestType].assigned[0].total
                : 0
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="مصعد للمصعد"
            secondary={
              typeof totalPendingRequests[requestType] !== 'undefined' &&
              typeof totalPendingRequests[requestType].escalated !== 'undefined'
                ? totalPendingRequests[requestType].escalated[0].total
                : 0
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="مصعد للجوده"
            secondary={
              typeof totalPendingRequests[requestType] !== 'undefined' &&
              typeof totalPendingRequests[requestType].quality !== 'undefined'
                ? totalPendingRequests[requestType].quality[0].total
                : 0
            }
          />
        </ListItem>
      </List>
    ) : (
      <CircularProgress className={classes.cardTitleEscalation} />
    )}
  </div>
);

const UserAchievementsList = ({ classes, requestType, dailyAccomplishment }) => (
  <div>
    {dailyAccomplishment ? (
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="تمت الاجابه عليه"
            secondary={
              typeof dailyAccomplishment[requestType] !== 'undefined' &&
              typeof dailyAccomplishment[requestType].answered !== 'undefined'
                ? dailyAccomplishment[requestType].answered[0].total
                : 0
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="تم تصعيده للمصعد"
            secondary={
              typeof dailyAccomplishment[requestType] !== 'undefined' &&
              typeof dailyAccomplishment[requestType].escalated !== 'undefined'
                ? dailyAccomplishment[requestType].escalated[0].total
                : 0
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="تم تصعيده للجوده"
            secondary={
              typeof dailyAccomplishment[requestType] !== 'undefined' &&
              typeof dailyAccomplishment[requestType].quality !== 'undefined'
                ? dailyAccomplishment[requestType].quality[0].total
                : 0
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="تم قبوله"
            secondary={
              typeof dailyAccomplishment[requestType] !== 'undefined' &&
              typeof dailyAccomplishment[requestType].approved !== 'undefined'
                ? dailyAccomplishment[requestType].approved[0].total
                : 0
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="تم رفضه"
            secondary={
              typeof dailyAccomplishment[requestType] !== 'undefined' &&
              typeof dailyAccomplishment[requestType].rejected !== 'undefined'
                ? dailyAccomplishment[requestType].rejected[0].total
                : 0
            }
          />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AssignmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="تم رفضه وحظره"
            secondary={
              typeof dailyAccomplishment[requestType] !== 'undefined' &&
              typeof dailyAccomplishment[requestType].rejected_blocked !== 'undefined'
                ? dailyAccomplishment[requestType].rejected_blocked[0].total
                : 0
            }
          />
        </ListItem>
      </List>
    ) : (
      <CircularProgress className={classes.cardTitleEscalation} />
    )}
  </div>
);

PendingProcessingRequestsList.propTypes = {
  classes: PropTypes.object.isRequired,
  requestType: PropTypes.string.isRequired,
  totalPendingRequests: PropTypes.object.isRequired,
};

UserAchievementsList.propTypes = {
  classes: PropTypes.object.isRequired,
  dailyAccomplishment: PropTypes.object.isRequired,
  requestType: PropTypes.string.isRequired,
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);
