import { PERMISSIONS_TYPES } from './constants';

export const publicRoutes = {
  login: 'login',
  dashboard: 'dashboard',
};

export const protectedRoutes = {
  users: PERMISSIONS_TYPES.CREATE_USERS,
  processingRequests: PERMISSIONS_TYPES.PROCESS_PROCESSING_REQUESTS,
  escalationReasons: PERMISSIONS_TYPES.MANAGE_ESCALATION_REASONS,
  escalationRequests: PERMISSIONS_TYPES.PROCESS_ESCALATED_PROCESSING_REQUESTS,
  blockReasons: PERMISSIONS_TYPES.MANAGE_BLOCKING_REASONS,
  blockList: PERMISSIONS_TYPES.BLOCK_CITIZENS,
  noticesRequests: PERMISSIONS_TYPES.PROCESS_NOTICE_PROCESSING_REQUESTS,
  showRequests: PERMISSIONS_TYPES.SEARCH_ISSUE_REQUESTS,
  qualityRequests: PERMISSIONS_TYPES.QUALITY_PROCESSING,
  dlRequests: PERMISSIONS_TYPES.DL_PROCESSING,
  modifyRanges: PERMISSIONS_TYPES.MODIFY_RANGES,
  auditReports: PERMISSIONS_TYPES.AUDIT_REPORTS,
};

export const ROWS_PER_PAGE = 20;
