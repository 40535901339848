import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// import ZoomIn from '@material-ui/icons/ZoomIn';
import ProcessingRequestsStyle from 'assets/jss/views/ProcessingRequestsStyle';
// utils
import { getRequestAttachmentsFromHistoryWithoutDuplication } from 'utils/processing_requests';
// core components
import AssignPage from 'components/Requests/AssignPage';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { request, URLs } from '../../request';
import Swal from 'sweetalert2';
// sections components
import ProcessingCurrentRequests from './CurrentRequest';
import PageTaps from '../../components/core/CustomTabs/PageTaps';
import PreviousRequests from 'components/Requests/PreviousRequests';
import { AssignAlertTypes } from '../../constants';

const initialState = {
  hasNoRequest: true,
  isFetching: true,
  userInformation: {},
  visaInformation: {},
  history: [],
  categories: [],
  attachments: [],
  rejectReasons: [],
  id: null,
  reference_id: null,
  task: null,
};

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.fetchRequestInfo();
    this.fetchRejectReasons();
  }

  onHandleRequestSubmitted = () => {
    this.setState(previousState => ({
      ...initialState,
      isFetching: false,
      rejectReasons: previousState.rejectReasons,
    }));
  };

  fetchRequestInfo = alertType => {
    request
      .GET(URLs.backoffice.PROCESS_TASK, { is_completed: true, type: this.props.type })
      .then(information => {
        if (!information.data.processingTasks) {
          if (alertType === AssignAlertTypes.NO_REQUESTS) {
            Swal.fire({
              title: 'عذرا',
              text: 'لا يوجد طلبات للمعالجة',
              type: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'حسنا',
            });
          }
          if (alertType === AssignAlertTypes.HAVE_ASSIGNED_REQUEST) {
            Swal.fire({
              title: 'عذرا',
              text: 'لديك طلب قيد المعالجة، فضلا قم بإنهائه كي تستطيع إسناد طلب جديد',
              type: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'حسنا',
            });
          }

          return;
        }

        const visaInformation = JSON.parse(information.data.processingTasks.request.visa_info);
        const userInfo = JSON.parse(information.data.processingTasks.request.user_info);
        const user = Array.isArray(userInfo.user) ? userInfo.user[0] : userInfo.user;
        const userInformation = {
          ...userInfo,
          ...user,
        };
        delete userInformation.user;
        const attachmentsIds = JSON.parse(information.data.uploads);
        this.setState({
          userInformation,
          id: information.data.processingTasks.request.id,
          task: information.data.processingTasks,
          reference_id: information.data.processingTasks.request.reference_id,
          categories: [information.data.category],
          dateCreated: information.data.processingTasks.request.created_at,
          visaInformation,
          hasNoRequest: false,
          isDisable:
            !!visaInformation.isDisable && Object.keys(visaInformation.isDisable).length !== 0,
        });

        request
          .GET(URLs.backoffice.ATTACHMENTS_HISTORY, {
            user_id: userInformation.id_number,
            type: this.props.type,
          })
          .then(history => {
            this.setState({
              history: history.data,
              attachments: getRequestAttachmentsFromHistoryWithoutDuplication(
                history,
                attachmentsIds,
              ),
            });
          });
      })
      .finally(() => {
        this.setState({ isFetching: false });
      });
  };

  handleAssignClicked = () => {
    this.setState({ loading: true });

    request.POST(URLs.backoffice.PROCESS_TASK_ASSIGN, { type: this.props.type }).then(res => {
      if (!res.data.allowed) {
        //! if have an assigned request, refetch it to make sure it's not of this type.
        //! see https://jira.tamkeen.cloud/browse/ASUP-8344 for more info
        this.fetchRequestInfo(AssignAlertTypes.HAVE_ASSIGNED_REQUEST);
        this.setState({ loading: false });
        return;
      }

      const interval = setInterval(() => {
        request.GET(URLs.backoffice.QUEUE_CHECK, { job: res.data.job }).then(queueResponse => {
          if (queueResponse.data.finished) {
            clearInterval(interval);
            this.setState({ loading: false });
            this.fetchRequestInfo(AssignAlertTypes.NO_REQUESTS);
          }
        });
      }, 1000);
    });
  };

  fetchRejectReasons = () =>
    request.GET(URLs.evisa.REJECTION_REASONS).then(res => {
      this.setState({
        rejectReasons: res.data.reasons,
      });
    });

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    const pages = [];
    pages.push({
      title: 'الطلب الحالي',
      content: (
        <ProcessingCurrentRequests
          id={this.state.id}
          userInformation={this.state.userInformation}
          visaInformation={this.state.visaInformation}
          isDisable={this.state.isDisable}
          categories={this.state.categories}
          attachments={this.state.attachments}
          referenceId={this.state.reference_id}
          refresh={this.onHandleRequestSubmitted}
          dateCreated={this.state.dateCreated}
          rejectReasons={this.state.rejectReasons}
          type={this.props.type}
          task={this.state.task}
        />
      ),
    });
    pages.push({
      title: 'الطلبات السابقة',
      content: (
        <PreviousRequests
          requests={this.state.history}
          user={this.state.userInformation}
          classes={this.props.classes}
        />
      ),
    });

    const Content = <PageTaps title={this.props.title} pages={pages} classes={classes} />;

    return (
      <AssignPage
        classes={classes}
        assignLabel="إسناد طلب جديد"
        shouldShowAssign={this.state.hasNoRequest}
        isAssigning={loading}
        onAssignClick={this.handleAssignClicked}
        isInitializing={this.state.isFetching}
        content={Content}
      />
    );
  }
}

Requests.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(ProcessingRequestsStyle),
  connect(mapStateToProps),
)(Requests);
