import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
// core components
// import TextField from '@material-ui/core/TextField';
// import Modal from '@material-ui/core/Modal';
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import axios from 'axios';
import { request, URLs } from '../../request';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import MediaViewer from 'components/core/mediaViewer';
// Styles
import withStyles from '@material-ui/core/styles/withStyles';
import DetailsStyle from 'assets/jss/views/DetailsStyle';
import { REQUEST_STATUS, FINANCIAL_OPTION_TITLE } from '../../constants';
import { withRouter } from 'react-router-dom';
import Attachment from 'components/core/attachment';
import RequestLogs from '../../components/Requests/RequestLogs';
import { rangeType, createRanges, splitRangeString } from '../../utils';

const requestStatuses = [
  '',
  REQUEST_STATUS.IN_PROCESS,
  REQUEST_STATUS.APPROVED,
  REQUEST_STATUS.REJECTED,
  REQUEST_STATUS.CANCELED,
  REQUEST_STATUS.PENDING,
];

const backofficeStatuses = {
  null: 'تحت المعالجة',
  1: 'تحت المعالجة',
  7: 'مصعد',
  8: 'جودة',
};

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      ranges: [],
      data: null,
      selectedAttachment: null,
    };
  }

  componentDidMount() {
    const promises = [];

    promises.push(
      request.GET(URLs.backoffice.SHOW_ISSUE_REQUEST, { id: this.props.match.params.id, page: 1 }),
    );
    promises.push(request.GET(URLs.backoffice.RANGES_ALL));

    Promise.all(promises)
      .then(response => {
        const showRequestResponse = response[0];
        const rangesAllResponse = response[1];

        this.setState({
          data: showRequestResponse.data,
          selectedAttachment: showRequestResponse.data.uploads[0],
          ranges: rangesAllResponse.data.ranges,
        });

        return showRequestResponse;
      })
      .then(showRequestResponse => {
        if (showRequestResponse.data.escalate) {
          return request.GET(URLs.backoffice.ESCALATION_REASONS_CATEGORIES, {
            id: showRequestResponse.data.escalate.escalation_reason_categories_id,
          });
        }

        return null;
      })
      .then(res => res && this.setState({ escalationCategory: res.data.title }))
      .finally(() => {
        this.setState({
          loaded: true,
        });
      });
  }

  handleBackButton = () => this.props.history.goBack();

  getFinancialOption = answers => {
    const arr = answers.map(answer => FINANCIAL_OPTION_TITLE[answer.pivot.financial_option]);
    return arr.filter(a => a);
  };

  getFinancialRange = answers => {
    const userDetails = JSON.parse(this.state.data.user_info);
    return answers
      .filter(answer => {
        // for only the answers that had financial ranges (were really answered)
        return answer.pivot.financial_option != null;
      })
      .map(answer => {
        const financialRanges = this.state.ranges.find(
          element => element.id === answer.pivot.range_id,
        );
        const usedType = answer.pivot.range_type
          ? answer.pivot.range_type
          : rangeType(userDetails, this.state.data.is_disable);
        const ranges = createRanges(
          splitRangeString(financialRanges.ranges[usedType][answer.pivot.financial_option]),
        );
        return ranges.find(element => element.value === answer.pivot.range).label;
      });
  };

  render() {
    const { classes } = this.props;
    const { data, loaded, selectedAttachment, escalationCategory } = this.state;
    const visaInfo = data && data.visa_info ? JSON.parse(data.visa_info) : null;
    const { answers, uploads } = data || {};
    const agents = answers && answers.map(agent => agent.name);
    const escalationReasons =
      data && data.escalate_reason && data.escalate_reason.map(reason => reason.text);
    const financialOptions = answers && this.getFinancialOption(answers);
    const financialRanges = answers && this.getFinancialRange(answers);
    const isDisabled = data && data.is_disable;
    const premiumResidency = data && parseInt(data.premium_residency, 8);
    return (
      <React.Fragment>
        <Grid>
          <p className={classes.title}>طلب إصدار تأشيرة </p>
          {loaded ? (
            <React.Fragment>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader className={classes.header}>
                    <p className={classes.title}> تفاصيل الطلب ( {this.props.match.params.id} )</p>
                  </CardHeader>
                  <CardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                      <p className={classes.label}>اسم مقدم الطلب</p>
                      <p className={`${classes.data} ${classes.wide}`}>{data.user_name}</p>
                    </div>
                    <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                      <p className={classes.label}>رقم الهوية/ الإقامة</p>
                      <p className={`${classes.data} ${classes.wide}`}>{data.id_number}</p>
                    </div>
                    <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                      <p className={classes.label}>وظيفة مقدم الطلب</p>
                      <p className={`${classes.data} ${classes.wide}`}>{data.job}</p>
                    </div>
                    <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                      <p className={classes.label}>الجنسية</p>
                      <p className={`${classes.data} ${classes.wide}`}>
                        {visaInfo ? visaInfo.nationality : null}
                      </p>
                    </div>
                    <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                      <p className={classes.label}>جهة القدوم</p>
                      <p className={`${classes.data} ${classes.wide}`}>
                        {visaInfo ? visaInfo.issuePlace : null}
                      </p>
                    </div>
                    {premiumResidency ? (
                      <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                        <p className={classes.label}>من حاملي الإقامة المميزة</p>
                        <p className={`${classes.data} ${classes.wide}`}>
                          {premiumResidency === 1 ? 'دائمة' : 'مؤقتة'}
                        </p>
                      </div>
                    ) : null}
                    {isDisabled ? (
                      <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                        <p className={classes.label}>من ذوي الاحتياجات الخاصة</p>
                        <p className={`${classes.data} ${classes.wide}`}>نعم</p>
                      </div>
                    ) : null}
                    {isDisabled ? (
                      <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                        <p className={classes.label}>اسم الوكيل</p>
                        <p className={`${classes.data} ${classes.wide}`}>
                          {data.sponsor_name || '-'}
                        </p>
                      </div>
                    ) : null}
                    {isDisabled ? (
                      <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                        <p className={classes.label}>رقم هوية الوكيل</p>
                        <p className={`${classes.data} ${classes.wide}`}>
                          {data.sponsor_id_number || '-'}
                        </p>
                      </div>
                    ) : null}

                    <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                      <p className={classes.label}>حالة الطلب</p>
                      <p className={`${classes.data} ${classes.wide}`}>
                        {data.status === 1
                          ? backofficeStatuses[data.backoffice_status]
                          : requestStatuses[data.status]}
                      </p>
                    </div>
                    {agents ? (
                      <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                        <p className={classes.label}>المسؤول عن الطلب</p>
                        <p className={`${classes.data} ${classes.wide}`}>{agents.join(' ,')}</p>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </GridItem>
              {selectedAttachment ? (
                <GridItem xs={12} sm={12} md={12}>
                  <Card className={classes.attachmentContainer}>
                    <div>
                      <CardHeader className={classes.header}>
                        <p className={classes.title}>المرفق </p>
                      </CardHeader>
                      <CardBody>
                        <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                          <p className={classes.label}>نوع التعريف</p>
                          <p className={`${classes.data} ${classes.wide}`}>
                            {(financialOptions && financialOptions.join(', ')) || '-'}
                          </p>
                        </div>
                        <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                          <p className={classes.label}>الحدود</p>
                          <p className={`${classes.data} ${classes.wide}`}>
                            {(financialRanges && financialRanges.join(', ')) || '-'}
                          </p>
                        </div>
                        {uploads.length > 1 ? (
                          <FormControl variant="outlined" className={classes.formControl}>
                            <div>
                              <p className={classes.attachmentTitle}>المرفقات</p>
                              <div>
                                {uploads.map((att, index) => (
                                  <Attachment
                                    className={classes.attachment}
                                    title={att.original_file_name}
                                    fileId={att.id}
                                    userId={data.id_number}
                                    selected={selectedAttachment.id === att.id}
                                    style={index === 0 && { marginTop: 0 }}
                                    onClick={() => this.setState({ selectedAttachment: att })}
                                  />
                                ))}
                              </div>
                            </div>
                          </FormControl>
                        ) : null}
                      </CardBody>
                    </div>
                    <div className={classes.mediaViewerContainer}>
                      <MediaViewer
                        fileId={selectedAttachment && selectedAttachment.id}
                        mimeType={selectedAttachment && selectedAttachment.mime_type}
                        name={selectedAttachment && selectedAttachment.original_file_name}
                        userId={data.id_number}
                        className={classes.mediaViewer}
                      />
                    </div>
                  </Card>
                </GridItem>
              ) : null}

              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <Grid container>
                      <GridItem xs={6} sm={6} md={6}>
                        <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                          <p className={classes.label}>نوع التصعيد</p>
                          <p className={`${classes.data} ${classes.wide}`}>
                            {escalationCategory || '-'}
                          </p>
                        </div>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                          <p className={classes.label}>تفاصيل التصعيد</p>
                          <p
                            className={`${classes.data} ${classes.wide}`}
                            style={{ minHeight: 40, height: 'auto' }}
                          >
                            {escalationReasons ? escalationReasons.join(', ') : '-'}
                          </p>
                        </div>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <div className={`${classes.infoContainer} ${classes.dataContainer}`}>
                          <p className={classes.label}>مزيد من التفاصيل</p>
                          <p className={`${classes.data} ${classes.wide}`}>
                            {(this.state.data.escalate && this.state.data.escalate.notes) || '-'}
                          </p>
                        </div>
                      </GridItem>
                    </Grid>
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <RequestLogs requestId={data.processing_request_id} />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.actionsContainer}>
                  <Button className={classes.backBtn} onClick={this.handleBackButton}>
                    رجوع
                  </Button>
                </div>
              </GridItem>
            </React.Fragment>
          ) : (
            <div
              style={{
                display: 'flex',
                padding: 30,
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired, push: PropTypes.func.isRequired })
    .isRequired,
  match: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(DetailsStyle),
  connect(mapStateToProps),
  withRouter,
)(Details);
