import React from 'react';
import Button from './Button';
import PropTypes from 'prop-types';

/**
 * This component is intended to be used for form buttons. it will be disabled once the
 * user clicks on it to prevent him/her from clicking the button again and firing the
 * same request twice. Currently, there is no way to revert the disable mechanism seeing
 * that there is no need for it at this time
 */
class SubmitButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }

  /**
   * Sometimes, in some cases, events will fire twice because if the
   * button is associated with a form, one for the click event and one for the form event.
   * preventing the default behaviour resolve that issue
   * @param e: html click event
   */
  handleSubmit = e => {
    // eslint-disable-next-line no-unused-expressions
    e && e.preventDefault && e.preventDefault();
    this.setState({ submitted: true });
  };

  handleClick = e => {
    this.handleSubmit(e);
    this.props.onClick(e);
  };

  render() {
    return (
      <Button
        {...this.props}
        disabled={this.props.disabled || this.state.submitted}
        onClick={this.handleClick}
      />
    );
  }
}

SubmitButton.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'transparent',
  ]),
  disabled: PropTypes.bool,
  justIcon: PropTypes.bool,
  link: PropTypes.bool,
  muiClasses: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  outline: PropTypes.bool,
  round: PropTypes.bool,
  simple: PropTypes.bool,
  // use this to pass the classes props from Material-UI
  size: PropTypes.oneOf(['sm', 'lg']),
};

export default SubmitButton;
