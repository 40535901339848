const LoginStyle = () => ({
  cardTitleRed: {
    color: '#FF0000',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    marginBottom: '3px',
    textDecoration: 'none',
  },
  loginBox: {
    border: 'solid #e6e6e6 1px',
    borderRadius: 1,
    background: '#ffffff',
  },
  forgetPassword: {
    display: 'flex',
    margin: '15px 5px',
  },
});

export default LoginStyle;
