import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Button from 'components/core/CustomButtons/Button';
import SubmitButton from 'components/core/CustomButtons/SubmitButton';
import GridItem from 'components/core/Grid/GridItem';

class EscalationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 0,
      reason: 0,
      details: '',
    };
  }

  handleCategoryChange = event => {
    const category = event.target.value;
    this.setState({
      category,
      reason: 0,
    });

    this.props.onCategoryChange(category);
  };

  handleReasonChange = event => {
    this.setState({
      reason: event.target.value,
    });
  };

  handleDetailsChange = event => {
    this.setState({
      details: event.target.value,
    });
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.category, this.state.reason, this.state.details);
    this.props.onCancel();
  };

  render() {
    const { classes, categories, reasons, onCancel } = this.props;
    return (
      <div className={`${classes.mContainer}`}>
        <div className={classes.mHeader}>
          <p className={classes.title}>تصعيد </p>
        </div>
        <div>
          <p className={classes.mLabel}>اختر سبب التصعيد و تفاصيل التصعيد</p>
        </div>
        <div className={classes.mSection}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              onChange={this.handleCategoryChange}
              value={this.state.category}
              input={<OutlinedInput name="type" />}
              className={classes.escalationSelect}
            >
              <MenuItem value={0}>
                <span style={{ color: '#9f9ca2' }}>نوع التصعيد</span>
              </MenuItem>
              {categories[0].map(category => (
                <MenuItem value={category.id}>{category.title}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              onChange={this.handleReasonChange}
              value={this.state.reason}
              input={<OutlinedInput name="type" />}
              className={classes.escalationSelect}
            >
              <MenuItem value={0}>
                <span style={{ color: '#9f9ca2' }}>تفاصيل التصعيد</span>
              </MenuItem>
              {reasons.map(reason => (
                <MenuItem value={reason.id}>{reason.text}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.mSection}>
          <input
            placeholder="مزيد من التفاصيل"
            onChange={this.handleDetailsChange}
            value={this.state.details}
            className={classes.mMainInput}
            style={{ width: '90%' }}
          />
        </div>
        <GridItem xs={12} sm={12} md={12}>
          <div className={`${classes.actionsContainer} ${classes.mFooter}`}>
            <Button color="danger" outline onClick={onCancel} style={{ marginTop: 10 }}>
              إلغاء
            </Button>
            <SubmitButton
              color="primary"
              style={{ marginTop: 10 }}
              onClick={this.handleSubmit}
              disabled={!this.state.category}
            >
              إرسال
            </SubmitButton>
          </div>
        </GridItem>
      </div>
    );
  }
}

EscalationForm.propTypes = {
  categories: PropTypes.array.isRequired,
  classes: PropTypes.object,
  onCancel: PropTypes.func,
  onCategoryChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reasons: PropTypes.array.isRequired,
};

export default EscalationForm;
