import React from 'react';
import PropTypes from 'prop-types';
// core components
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { request, URLs } from '../../../request';
// Styles
import withStyles from '@material-ui/core/styles/withStyles';
import EscalatedRequestsStyle from 'assets/jss/views/EscalatedRequestsStyle';
import { withRouter } from 'react-router-dom';
import CurrentRequest from '../../../components/Requests/CurrentRequest';
import ModalButton from '../../../components/core/CustomButtons/ModalButton';
import RejectForm from '../../../components/Forms/RejectForm';

class CurrentInquiryRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRejection = reasons => {
    const id = this.props.match.params.id;
    const bodyParameters = {
      id,
      processing_request_id: this.props.requestId,
      rejection_reasons: reasons,
    };
    request.POST(URLs.backoffice.INQUIRY_PROCESSING_REJECT, bodyParameters).then(() => {
      this.props.history.push('/inquiries');
    });
  };

  handleSubmit = (option, range, type) => {
    const id = this.props.match.params.id;

    const bodyParameters = {
      id,
      processing_request_id: this.props.requestId,
      financial_option: option,
      range,
      range_type: type,
    };

    request.POST(URLs.backoffice.INQUIRY_PROCESSING_ACCEPT, bodyParameters).then(() => {
      this.props.history.push('/inquiries');
    });
  };

  render() {
    const {
      classes,
      userInformation,
      visaInformation,
      attachments,
      category,
      reasons,
      rejectReasons,
      refrenceId,
      requestId,
      isDisable,
      dateCreated,
    } = this.props;
    const selectReason = [];
    rejectReasons.map(item => selectReason.push({ name: item.label, id: item.id }));
    const Actions = (
      <React.Fragment>
        <ModalButton label="رفض" type="danger" classes={classes} outline>
          <RejectForm
            classes={classes}
            onSubmit={this.handleRejection}
            rejectReasons={rejectReasons}
            selectReason={selectReason}
          />
        </ModalButton>
      </React.Fragment>
    );

    return (
      <CurrentRequest
        classes={classes}
        requestId={requestId}
        userIdNumber={userInformation.id_number}
        disabilityDetails={{
          isDisable,
          responsibleId: isDisable ? JSON.parse(visaInformation.isDisable).Responsible_ID : null,
          responsibleName: isDisable
            ? JSON.parse(visaInformation.isDisable).Responsible_Name
            : null,
        }}
        category={category}
        reasons={reasons}
        attachments={attachments}
        userDetails={userInformation}
        title="معالجة الطلب"
        actions={Actions}
        id={refrenceId}
        showLogs
        onSubmit={this.handleSubmit}
        dateCreated={dateCreated}
      />
    );
  }
}

CurrentInquiryRequest.propTypes = {
  attachments: PropTypes.string,
  category: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dateCreated: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  isDisable: PropTypes.bool,
  match: PropTypes.object.isRequired,
  reasons: PropTypes.array,
  refrenceId: PropTypes.object,
  rejectReasons: PropTypes.array,
  requestId: PropTypes.string,
  userInformation: PropTypes.object,
  visaInformation: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(EscalatedRequestsStyle),
  connect(mapStateToProps),
  withRouter,
)(CurrentInquiryRequest);
