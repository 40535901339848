import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Button from 'components/core/CustomButtons/Button';
import { IconButton } from '@material-ui/core';

class ModalButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  handleModelToggle = isOpen => () => {
    this.setState({
      isModalOpen: isOpen,
    });
  };

  render() {
    const IconComponent = this.props.iconComponent;
    return (
      <React.Fragment>
        {!IconComponent && (
          <Button
            color={this.props.type}
            outline={this.props.outline}
            onClick={this.handleModelToggle(true)}
          >
            {this.props.label}
          </Button>
        )}
        {IconComponent && (
          <IconButton aria-label="replay" onClick={this.handleModelToggle(true)}>
            <IconComponent color={this.props.type} />
          </IconButton>
        )}
        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleModelToggle(false)}
          className={this.props.classes.modal}
        >
          {React.cloneElement(this.props.children, { onCancel: this.handleModelToggle(false) })}
        </Modal>
      </React.Fragment>
    );
  }
}

ModalButton.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  iconComponent: PropTypes.element,
  label: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  type: PropTypes.string,
};

ModalButton.defaultProps = {
  outline: false,
};

export default ModalButton;
