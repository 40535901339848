import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import footerStyle from 'assets/jss/core/footerStyle';

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <p className={classes.right}>
          <span>
            تطوير شركة&nbsp;
            <a href="http://tamkeentech.sa/" className={classes.a}>
              تمكين للتقنيات
            </a>
            - بدعم من وزارة العمل والتنمية الاجتماعية
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(footerStyle)(Footer);
