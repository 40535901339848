import DetailsStyle from './DetailsStyle';

const AuditRequestStyle = () => ({
  ...DetailsStyle(),
  data: {
    backgroundColor: '#efefef',
    minWidth: 360,
    height: 40,
    padding: '10px 10px 10px 10px',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: '#9f9ca2',
  },
  listData: {
    backgroundColor: '#efefef',
    minWidth: 360,
    padding: '1px 10px 1px 10px',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: '#9f9ca2',
  },
});

export default AuditRequestStyle;
