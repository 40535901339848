const MediaViewerStyle = () => ({
  container: {
    flex: 1,
    position: 'relative',
  },
  mediaContainer: {
    height: 300,
    backgroundColor: '#f9f9f9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'cneter',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    overflow: 'auto',

    '& img': {
      width: '100%',
      height: 'auto',
      margin: 'auto',
      display: 'inline-block',
      transition: 'transform 0.3s',
      transformOrigin: 'top',
    },
    '& iframe': {
      width: '100%',
      border: '2px solid rgba(180,180,180,0.1)',
      borderBottom: 'none',
    },
  },
  tools: {
    position: 'absolute',
    top: 250,
    textAlign: 'center',
    width: '100%',
    height: 30,

    '& > *': {
      backgroundColor: 'rgba(0,0,0,0.65)',
      color: '#FFF',
      border: 'none',
      height: '100%',
    },
    '& button': {
      cursor: 'pointer',
    },
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    '& .filename': {
      width: '100%',
      maxWidth: 200,
    },
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
});

export default MediaViewerStyle;
