import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import LockOutline from '@material-ui/icons/LockOutlined';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardBody from 'components/core/Card/CardBody';
import CardFooter from 'components/core/Card/CardFooter';
import LoginStyle from 'assets/jss/views/LoginStyle';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import axios from 'axios';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
// action
import { LOGIN } from '../../actions';
import { BACKOFFICE_SYSTEM, AUDIT_SYSTEM } from '../../actions/system';
import { USER_TYPES } from '../../constants';

class TwoFA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      openError: false,
      count: 0,
      readableCount: 60,
    };

    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleSearchTermSubmit = this.handleSearchTermSubmit.bind(this);
    this.handleClose = () => {
      this.setState({ openError: false });
    };
  }

  componentDidMount() {
    this.handleSendingCode();
  }

  dispatchCurrentSystem = type => {
    if (type === USER_TYPES.AUDIT_USER) {
      this.props.dispatch(AUDIT_SYSTEM());
    } else {
      this.props.dispatch(BACKOFFICE_SYSTEM());
    }
  };

  handleSendingCode = () => {
    const url = process.env.REACT_APP_API_URL;
    axios
      .post(`${url}/v1/auth/code_via_sms`, [], {
        headers: {
          Authorization: `Bearer ${this.props.tempToken}`,
        },
      })
      .then(() => {
        this.handleResetCounter();
      });
  };

  handleResetCounter = () => {
    this.setState({
      count: 0,
      readableCount: 60,
    });
  };

  handleIncreaseCounter = () => {
    if (this.state.count > 100) {
      return;
    }

    this.setState(oldState => ({
      count: oldState.count + 1.67,
      readableCount: oldState.readableCount - 1,
    }));
  };

  handleCodeChange(evt) {
    this.setState({ code: evt.target.value });
  }

  handleSearchTermSubmit(e) {
    e.preventDefault();
    const code = this.state.code;
    const url = process.env.REACT_APP_API_URL;
    const tempToken = this.props.tempToken;
    const bodyParameters = {
      code,
    };

    axios
      .post(`${url}/v1/auth/verify`, bodyParameters, {
        headers: {
          Authorization: `Bearer ${tempToken}`,
        },
      })
      .then(response => {
        const auth = {
          token: response.data.token,
          isRoot: response.data.isRoot,
          userPermissions: response.data.userPermissions,
          type: response.data.type,
        };
        this.props.dispatch(LOGIN(auth));
        this.dispatchCurrentSystem(auth.type);
        this.props.history.push('/dashboard');
      })
      .catch(() => this.setState({ openError: true }));
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <form onSubmit={this.handleSearchTermSubmit}>
              <LinearDeterminate
                count={this.state.count}
                onIncreaseCounter={this.handleIncreaseCounter}
              />
              <CardBody>
                <Grid container>
                  <GridItem xs={12} sm={12} md={12}>
                    <p style={{ textAlign: 'center', fontWeight: 500, fontSize: 24 }}>
                      مصادقة الدخول
                    </p>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      label="الرمز"
                      id="code"
                      type="number"
                      fullWidth
                      margin="normal"
                      onChange={this.handleCodeChange}
                      autoFocus
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutline />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
              <CardFooter plain>
                <Grid>
                  <GridItem>
                    <p>يرجى الانتظار {this.state.readableCount} ثانية حتى تتمكن من إعادة الإرسال</p>
                  </GridItem>
                  <GridItem />
                </Grid>
                <Button color="primary" type="submit">
                  مصادقة
                </Button>
                <Button
                  color="secondry"
                  disabled={this.state.count <= 100}
                  onClick={this.handleSendingCode}
                >
                  إعادة الإرسال
                </Button>

                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={this.state.openError}
                  autoHideDuration={6000}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">الرمز المدخل غير صحيح.</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.handleClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </Grid>
    );
  }
}

const LinearDeterminate = ({ count, onIncreaseCounter }) => {
  React.useEffect(() => {
    const timer = setInterval(onIncreaseCounter, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <LinearProgress variant="determinate" value={count} />
      <LinearProgress variant="determinate" value={count} color="secondary" />
    </div>
  );
};

LinearDeterminate.propTypes = {
  count: PropTypes.number.isRequired,
  onIncreaseCounter: PropTypes.func.isRequired,
};

TwoFA.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  tempToken: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    tempToken: state.auth.tempToken,
  };
};

export default compose(
  withStyles(LoginStyle),
  connect(mapStateToProps),
  withRouter,
)(TwoFA);
