import { dispatch } from '../store';
import { LOGOUT } from '../actions';
import conflicts from './confilct_errors';
import Swal from 'sweetalert2';

export const CODES = {
  INVALID_CREDINTIALS: 101,
  UNPROCESSED_ENTITY: 422,
  CONFLICT: 409,
  UNAUTHORUZED: 401,
  FORBIDDEN: 403,
  LOCKED: 423,
  SERVER_ERROR: 500,
  BAD_REQUEST: 400,
};

// BackOffice Error Handle Method
export default e => {
  function drawErrorList(errors) {
    const errs = Object.values(errors);
    const classname = 'error-msg-right';

    let dom = `<ul class="${classname}">`;
    errs.forEach(err => {
      dom += `<li>${err[0]}</li>`;
    });
    dom += '</ul>';

    return dom;
  }
  const error = e;
  const response = error && error.response;
  const data = response && response.data;
  const loggedOut = () => {
    dispatch(LOGOUT());
    Swal.fire({
      title: 'عذرا',
      text: 'يبدو أن فترة الجلسة انتهت، الرجاء اعادة تسجيل الدخول',
      type: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حسنا',
    });
  };

  if (
    data.message &&
    (data.message.includes('User is not logged in') ||
      data.message.includes('Token is Expired') ||
      data.message.includes('Unauthenticated'))
  ) {
    return loggedOut();
  }

  if (data && data.error) {
    if (
      data.message &&
      (data.message.includes('User is not logged in') ||
        data.message.includes('Token is Expired') ||
        data.message.includes('Unauthenticated'))
    ) {
      return loggedOut();
    }
    if (data.error.code === CODES.INVALID_CREDINTIALS) {
      // Handling invalid credintials
      Swal.fire({
        title: 'عذرا',
        text: 'البيانات المدخلة غير صحيحة، نرجو التأكد من صحة البيانات المدخلة',
        type: 'error',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
      });
    }
    if (data.error.code === CODES.LOCKED) {
      // Handling invalid credintials
      Swal.fire({
        title: 'عذرا',
        text: 'تم تعطيل الحساب، الرجاء التواصل مع مدير النظام',
        type: 'error',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
      });
    }
    if (data.error.code === CODES.UNAUTHORUZED) {
      return loggedOut();
    }
    if (data.error.code === CODES.FORBIDDEN) {
      // Handling if the user permissions changed while the session is still active
      Swal.fire({
        title: 'عذرا',
        text: 'لا يمكنك تنفيذ هذا الأمر',
        type: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
      });
    }
    if (data.error.code === CODES.UNPROCESSED_ENTITY || data.error.code === CODES.BAD_REQUEST) {
      // Handling if the user permissions changed while the session is still active
      let message =
        response.data.error.message ||
        `الرجاء التأكد من المدخلات, ${response.errors ? response.errors.title[0] : ''}`;
      message = message.replace(/\./g, '</br>');
      Swal.fire({
        title: 'عذرا',
        html: message,
        type: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
      });
    }
    if (data.error.code === CODES.CONFLICT) {
      Swal.fire({
        title: 'عذرا',
        text: conflicts[data.error.status] ? conflicts[data.error.status] : conflicts.GENERAL,
        type: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
      }).then(() => {
        if (!data.error.status === 'ACCESSING_A_PROCESSED_REQUEST') {
          window.location.reload();
        }
      });
    }
    if (data.error.code === CODES.SERVER_ERROR) {
      Swal.fire({
        title: 'عذرا',
        text: 'حدث خطأ ما',
        type: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
      });
    }
  } else if (data.errors) {
    Swal.fire({
      type: 'error',
      title: 'فضلا تأكد من صحة البيانات المقدمة',
      html: drawErrorList(data.errors),
      confirmButtonColor: '#58ABC8',
      confirmButtonText: 'حسنا',
    });
  } else {
    Swal.fire({
      title: 'عذرا',
      text: 'حدث خطأ ما',
      type: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حسنا',
    });
  }
  return error;
};
