import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Email from '@material-ui/icons/MailOutline';
import LockOutline from '@material-ui/icons/LockOutlined';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardBody from 'components/core/Card/CardBody';
import LoginStyle from 'assets/jss/views/LoginStyle';
import PropTypes from 'prop-types';
import TextField from 'components/core/CustomInput/CustomInput';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CardContent from '@material-ui/core/CardContent';
import { connect } from 'react-redux';
import axios from 'axios';
import compose from 'recompose/compose';
import ForgetPassword from './ForgetPassword';
import ResetPassword from './ResetPassword';
import TwoFA from './TwoFA';
import { VALIDATE_AUTH } from '../../actions';

class Login extends React.Component {
  state = {};

  componentDidMount() {
    if (window.location.href.indexOf('reset') > -1) {
      this.setState({ reset: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleEmailChange = evt => {
    this.setState({ email: evt.target.value });
  };

  handlePasswordChange = evt => {
    this.setState({ password: evt.target.value });
  };

  handleOpenForgetPassword = () => {
    this.setState({ forget: true });
  };

  handleCancelForgetPassword = () => {
    this.setState({ forget: false });
  };

  handleSearchTermSubmit = e => {
    e.preventDefault();
    const email = this.state.email;
    const password = this.state.password;
    const url = process.env.REACT_APP_API_URL;
    axios.post(`${url}/v1/auth/login`, { email, password }).then(res => {
      if (res.status === 200) {
        this.props.dispatch(VALIDATE_AUTH(res.data.access_token));
        this.setState({ TwoFA: true });
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      /* eslint-disable-next-line no-nested-ternary */
      this.state.TwoFA ? (
        <TwoFA />
      ) : /* eslint-disable-next-line no-nested-ternary */
      this.state.forget ? (
        <ForgetPassword onCancel={this.handleCancelForgetPassword} />
      ) : this.state.reset ? (
        <ResetPassword history={this.props.history} />
      ) : (
        <Grid container direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={4}>
            {this.state.status ? (
              <Card className={classes.card}>
                <CardContent>
                  <h5 className={classes.cardTitleRed}>نعتذر. تم تعطيل حساب المستخدم</h5>
                </CardContent>
              </Card>
            ) : null}
            <form className={classes.loginBox} onSubmit={this.handleSearchTermSubmit}>
              <CardBody>
                <Grid container>
                  <GridItem xs={12} sm={12} md={12}>
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 500,
                        fontSize: 24,
                        margin: '20px 0',
                      }}
                    >
                      تسجيل الدخول
                    </p>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      label="البريد الإلكتروني"
                      id="email"
                      type="email"
                      fullWidth
                      margin="normal"
                      onChange={this.handleEmailChange}
                      autoFocus
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email style={{ color: 'lightgrey', fontSize: 18 }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      label="الرقم السري"
                      id="password"
                      type="password"
                      fullWidth
                      margin="normal"
                      onChange={this.handlePasswordChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutline style={{ color: 'lightgrey', fontSize: 16 }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
              <div>
                <div style={{ display: 'flex' }}>
                  <Button type="submit" color="primary" style={{ margin: 'auto' }}>
                    دخول
                  </Button>
                </div>
                <div className={classes.forgetPassword}>
                  <button
                    type="button"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                    }}
                    onClick={this.handleOpenForgetPassword}
                  >
                    استعادة كلمة المرور؟
                  </button>
                </div>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">الرجاء التحقق من البيانات المدخلة.</span>}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.handleClose}
                    >
                      <CloseIcon />
                    </IconButton>,
                  ]}
                />
              </div>
            </form>
          </GridItem>
        </Grid>
      )
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};
export default compose(
  withStyles(LoginStyle),
  connect(),
)(Login);
