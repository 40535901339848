import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
// core components
import headerLinksStyle from 'assets/jss/core/headerLinksStyle';

const HeaderLinks = () => {
  return <div />;
};

export default withStyles(headerLinksStyle)(HeaderLinks);
