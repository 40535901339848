const backoffice = {
  CURRENT_USER: '{BACKOFFICE_BASE}/v1/auth/me',
  LOGOUT: '{BACKOFFICE_BASE}/v1/auth/logout',
  SEND_OTP: '{BACKOFFICE_BASE}/v1/auth/code_via_sms',
  DASHBOARD: '{BACKOFFICE_BASE}/v1/dashboard',
  USERS: '{BACKOFFICE_BASE}/v1/users/{user_id}',
  USER_ACTIVATION: '{BACKOFFICE_BASE}/v1/users/changeActive/{user_id}',
  /* Escalation */
  PROCESS_ESCALATION: '{BACKOFFICE_BASE}/v1/escalated_requests/{id}',
  ASSIGN_ESCALATION: '{BACKOFFICE_BASE}/v1/escalated_requests/{id}/assign',
  UNASSIGN_ESCALATION: '{BACKOFFICE_BASE}/v1/escalated_requests/{id}/unassign',
  ADD_ESCALATION_REASON: '{BACKOFFICE_BASE}/v1/escalation_reasons/add',
  UPDATE_ESCALATION_REASON: '{BACKOFFICE_BASE}/v1/escalation_reasons/update',
  ESCALATION_REASONS_ACTIVATION:
    '{BACKOFFICE_BASE}/v1/escalation_reasons/change_active/{category_id}',
  ESCALATION_REASONS_CATEGORIES:
    // eslint-disable-next-line max-len
    '{BACKOFFICE_BASE}/v1/escalation_reasons/categories/{id}?escalate_to={escalate_to}&include_system={include_system}',
  ESCALATION_REASONS: '{BACKOFFICE_BASE}/v1/escalation_reasons/category_reasons/{category_id}',
  ESCALATED_REQUESTS:
    '{BACKOFFICE_BASE}/v1/escalated_requests?' +
    'type={type}&from={from}&to={to}&name={name}&page={page}&escalation_reason={escalation_reason}',
  /* Block */
  BLOCKING_REASONS:
    '{BACKOFFICE_BASE}/v1/blocking_reasons/{reason_id}?trashed={trashed}&active={active}',
  BLOCKING_REASONS_ACTIVATION: '{BACKOFFICE_BASE}/v1/blocking_reasons/change_active/{reason_id}',
  BLOCKED_USERS:
    '{BACKOFFICE_BASE}/v1/blocked_users/{user_id}?' +
    'active={active}&from={from}&to={to}&reason_id={reason_id}&id_number={id_number}' +
    '&user_id={agent_id}',
  BLOCK_HISTORY: '{BACKOFFICE_BASE}/v1/blocked_users:history/{user_id}',
  AUDIT_HISTORY: '{BACKOFFICE_BASE}/v1/audit_logs/history/{module}/{id}',
  /* Processing Requests */
  PROCESS_TASK_ASSIGN: '{BACKOFFICE_BASE}/v1/processing_tasks/assign',
  PROCESS_TASK: '{BACKOFFICE_BASE}/v1/processing_tasks?uncompleted={is_completed}&type={type}',
  QUEUE_CHECK: '{BACKOFFICE_BASE}/v1/processing_tasks/queue/{job}/finished',
  GET_USER_TASK: '{BACKOFFICE_BASE}/v1/processing_tasks/user/{user_id}',
  CANCEL_USER_TASK: '{BACKOFFICE_BASE}/v1/processing_tasks/user/{user_id}/cancel/{task_id}',
  REJECTION: '{BACKOFFICE_BASE}/v1/escalated_requests:reject',
  SUBMIT_REQUEST_ANSWER: '{BACKOFFICE_BASE}/v1/answers',
  SUBMIT_REQUEST_REJECT: '{BACKOFFICE_BASE}/v1/answers/reject',
  SHOW_ISSUE_REQUEST: '{BACKOFFICE_BASE}/v1/show_issue_request?id={id}',
  SHOW_DL_REQUEST: '{BACKOFFICE_BASE}/v1/show_dl_request?id={id}',
  SHOW_IND2IND_REQUEST: '{BACKOFFICE_BASE}/v1/show_ind2ind_request?id={id}',
  PROCESSING_REQUEST_LOGS: '{BACKOFFICE_BASE}/v1/process_request_logs?id={id}',
  /* Quality */
  ESCALATE_TO_QUALITY: '{BACKOFFICE_BASE}/v1/escalateToQuality',
  QUALITY_REQUESTS:
    '{BACKOFFICE_BASE}/v1/quality?' +
    'type={type}&from={from}&to={to}&name={name}&page={page}&escalation_reason={escalation_reason}',
  PROCESS_QUALITY: '{BACKOFFICE_BASE}/v1/quality/process/{id}',
  PROCESS_QUALITY_ASSIGN: '{BACKOFFICE_BASE}/v1/quality/process/{id}/assign',
  PROCESS_QUALITY_UNASSIGN: '{BACKOFFICE_BASE}/v1/quality/process/{id}/unassign',
  PROCESS_QUALITY_ACCEPT: '{BACKOFFICE_BASE}/v1/quality/process/{id}/accept',
  PROCESS_QUALITY_REJECT: '{BACKOFFICE_BASE}/v1/quality/process/{id}/reject',
  /* Users */
  AGENTS:
    '{BACKOFFICE_BASE}/v1/users?' +
    'include_system={include_system}&only_audit={only_audit}&exclude_audit={exclude_audit}',
  /* Ranges */
  RANGES: '{BACKOFFICE_BASE}/v1/ranges',
  RANGES_ALL: '{BACKOFFICE_BASE}/v1/ranges/all',
  /* Inquiry */
  INQUIRY_ISSUE_REQUSTS:
    '{BACKOFFICE_BASE}/v1/inquiry/issue_requests?page={page}&request_id={request_id}' +
    '&date={date}&status={status}&request_channel={request_channel}' +
    '&id_number={id_number}&gio={gio}&request_from={request_from}' +
    '&request_to={request_to}&processing_from={processing_from}' +
    '&processing_to={processing_to}&is_disable={is_disable}' +
    '&visa_type={visa_type}&processed_by={agentId}',
  INQUIRY_LEGACY_DL_TRANCFER:
    '{BACKOFFICE_BASE}/v1/inquiry/legacy_dl_transfer?page={page}' +
    '&request_id={request_id}&status={status}&id_number={id_number}' +
    '&laborer_iqama={laborer_iqama}&current_sponsor={current_sponsor}' +
    '&created_from={created_from}&created_to={created_to}' +
    '&updated_from={updated_from}&updated_to={updated_to}',
  INQUIRY_DL_TRANSFER:
    '{BACKOFFICE_BASE}/v1/inquiry/dl_transfer?page={page}&id={id}&status={status}' +
    '&id_number={id_number}&iqama_number={iqama_number}&current_sponsor={current_sponsor}&' +
    'created_from={created_from}&created_to={created_to}' +
    '&processed_from={processed_from}&processed_to={processed_to}',
  INQUIRY_IND2IND_TRANSFER:
    '{BACKOFFICE_BASE}/v1/inquiry/ind2ind_transfer?page={page}&id={id}&status={status}' +
    '&new_sponsor={new_sponsor}&current_sponsor={current_sponsor}&laborer={laborer}&' +
    'created_from={created_from}&created_to={created_to}' +
    '&processed_from={processed_from}&processed_to={processed_to}',
  INQUIRY_EXISTS: '{BACKOFFICE_BASE}/v1/inquiry/exists',
  INQUIRY_PROCESSING: '{BACKOFFICE_BASE}/v1/inquiry/process/{id}',
  INQUIRY_PROCESSING_ACCEPT: '{BACKOFFICE_BASE}/v1/inquiry/process/{id}/accept',
  INQUIRY_PROCESSING_REJECT: '{BACKOFFICE_BASE}/v1/inquiry/process/{id}/reject',
  ATTACHMENTS_HISTORY: '{BACKOFFICE_BASE}/v1/history/{user_id}/{type}',
  /* Audit System */
  AUDIT_DASHBOARD: '{BACKOFFICE_BASE}/v1/audit/dashboard',
  AUDIT_TASK_ASSIGN: '{BACKOFFICE_BASE}/v1/audit/tasks/assign',
  AUDIT_ACTIVE_TASK: '{BACKOFFICE_BASE}/v1/audit/tasks/active',
  AUDIT_ACCEPT_REQUEST: '{BACKOFFICE_BASE}/v1/audit/tasks/{task_id}/accept',
  AUDIT_REJECT_REQUEST: '{BACKOFFICE_BASE}/v1/audit/tasks/{task_id}/reject',
  SHOW_AUDIT_REQUEST: '{BACKOFFICE_BASE}/v1/audit/requests/show/{id}',
  AUDIT_REPORT: '{BACKOFFICE_BASE}/v1/audit/requests/report',
  LIST_AUDIT_REQUEST:
    '{BACKOFFICE_BASE}/v1/audit/requests?' +
    'request_id={request_id}&request_status={request_status}&user_id={user_id}&from={from}&to={to}',
};
export default backoffice;
