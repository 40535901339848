import React from 'react';
import PropTypes from 'prop-types';
import { request, URLs } from '../../request';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import SubmitButton from 'components/core/CustomButtons/SubmitButton';
import Grid from '@material-ui/core/Grid';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import { TextField } from '@material-ui/core';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import ModifyRangesStyle from 'assets/jss/views/ModifyRangesStyle';
import { createRanges, splitRangeString } from '../../utils';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import { AUDIT_ACTIONS } from '../../constants';

class ModifyRanges extends React.Component {
  state = {
    loading: true,
    saudi: {
      bank: '',
      salary: '',
    },
    foreigners: {
      bank: '',
      salary: '',
    },
    disablity: {
      bank: '',
      salary: '',
    },
    recordHistory: [],
  };

  componentWillMount() {
    request.GET(URLs.backoffice.RANGES).then(response => {
      this.setState({
        loading: false,
        saudi: response.data.saudi,
        foreigners: response.data.foreigners,
        disablity: response.data.disablity,
      });
    });

    request.GET(URLs.backoffice.AUDIT_HISTORY, { module: 'Ranges', id: '1' }).then(res => {
      this.setState({
        recordHistory: res.data,
      });
    });
  }

  onUpdatePivots = type => option => event => {
    event.persist();
    this.setState(prevState => {
      const copy = Object.assign({}, prevState[type]);
      copy[option] = event.target.value;
      return { [type]: copy };
    });
  };

  handleSubmit = () => {
    request
      .POST(URLs.backoffice.RANGES, {
        ranges: this.state,
      })
      .then(() => {
        Swal.fire({
          title: 'تم التغيير بنجاح',
          text: 'تم تغيير إجابات القدرات المالية بنجاح',
          type: 'success',
          confirmButtonText: 'تحديث الصفحة',
        }).then(() => {
          window.location.reload();
        });
      });
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return (
        <Grid container justify="center">
          <CircularProgress className={classes.progress} />
        </Grid>
      );
    }
    return (
      <Grid>
        <GridItem>
          <RangesForOption
            onUpdatePivots={this.onUpdatePivots('saudi')}
            title="القدرة المالية للمواطنين"
            optionPivots={this.state.saudi}
            classes={classes}
            type={'saudi'}
          />
          <RangesForOption
            onUpdatePivots={this.onUpdatePivots('foreigners')}
            title="القدرة المالية للأجانب"
            optionPivots={this.state.foreigners}
            classes={classes}
            type={'foreigners'}
          />
          <RangesForOption
            onUpdatePivots={this.onUpdatePivots('disablity')}
            title="القدرة المالية لذوي الإعاقة"
            optionPivots={this.state.disablity}
            classes={classes}
            type={'disablity'}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.actionsContainer}>
            <SubmitButton color="primary" onClick={this.handleSubmit}>
              حفظ التغييرات
            </SubmitButton>
          </div>
        </GridItem>
        <AuditsCard audits={this.state.recordHistory} classes={this.props.classes} />
      </Grid>
    );
  }
}

// eslint-disable-next-line react/prop-types
const RangesForOption = ({ optionPivots, onUpdatePivots, classes, title, type }) => (
  <Grid container>
    <GridItem item xs={6}>
      <RangePivotsForm
        optionPivots={optionPivots}
        title={title}
        onUpdatePivots={onUpdatePivots}
        classes={classes}
        type={type}
      />
    </GridItem>
    <GridItem item xs={3}>
      <RangeRender title="شهادة بنكية" pivots={optionPivots.bank} classes={classes} type={type} />
    </GridItem>
    <GridItem item xs={3}>
      <RangeRender title="راتب" pivots={optionPivots.salary} classes={classes} type={type} />
    </GridItem>
  </Grid>
);

const RangePivotsForm = ({ optionPivots, onUpdatePivots, classes, title }) => {
  return (
    <Card>
      <CardHeader className={classes.header}>
        <p className={classes.title}>{title}</p>
      </CardHeader>
      <CardBody>
        <h3>الشهادة البنكية</h3>
        <TextField
          fullWidth
          dir="ltr"
          value={optionPivots.bank}
          margin="normal"
          required
          onChange={onUpdatePivots('bank')}
        />
      </CardBody>
      <CardBody>
        <h3>الراتب</h3>
        <TextField
          fullWidth
          dir="ltr"
          value={optionPivots.salary}
          margin="normal"
          required
          onChange={onUpdatePivots('salary')}
        />
      </CardBody>
    </Card>
  );
};

// eslint-disable-next-line react/prop-types
const RangeRender = ({ pivots, title, classes, type }) => {
  const rangeArray = splitRangeString(pivots);
  const ranges = createRanges(rangeArray, type, title);
  return (
    <Card>
      <CardHeader className={classes.header}>
        <p className={classes.title}>{title}</p>
      </CardHeader>
      <CardBody>
        {ranges.map(range => (
          <h4>{range.label}</h4>
        ))}
      </CardBody>
    </Card>
  );
};

const AuditsCard = ({ audits, classes }) => (
  <Card>
    <CardHeader className={classes.header}>
      <p className={classes.title}>الأحداث المتعلقة</p>
    </CardHeader>
    <CardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
      {audits.length === 0 ? (
        <h2>لا يوجد</h2>
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                الحدث
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                تاريخ الحدث
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                بواسطة
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                تفاصيل
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {audits.map(his => (
              <TableRow>
                <TableCell className={classes.cell}>{AUDIT_ACTIONS[his.event]}</TableCell>
                <TableCell className={classes.lgCell}>{his.datetime}</TableCell>
                <TableCell className={classes.cell}>{his.user}</TableCell>
                <TableCell
                  className={classes.lgCell}
                  dangerouslySetInnerHTML={{ __html: his.details }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </CardBody>
  </Card>
);
RangesForOption.propTypes = {
  classes: PropTypes.object.isRequired,
  onUpdatePivots: PropTypes.func.isRequired,
  optionPivots: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

RangePivotsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onUpdatePivots: PropTypes.func.isRequired,
  optionPivots: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

RangeRender.propTypes = {
  classes: PropTypes.object.isRequired,
  pivots: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ModifyRanges.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

AuditsCard.propTypes = {
  audits: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    access_token: state,
  };
};

export default compose(
  withStyles(ModifyRangesStyle),
  connect(mapStateToProps),
)(ModifyRanges);
