import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import GridItem from 'components/core/Grid/GridItem';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

class PageTaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
    };
  }

  componentWillMount() {
    this.setState({ tab: this.props.match.params.page || 0 });
  }

  handleClick = tab => () => {
    this.setState({ tab });
    this.props.history.push(this.props.pages[tab].link);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid>
        <GridItem xs={12} sm={12} md={12}>
          <p className={classes.title}> {this.props.title} </p>
          <Tabs
            titles={this.props.pages.map(page => page.title)}
            classes={classes}
            tab={this.state.tab}
            onClick={this.handleClick}
          />
          <VerticalLine position={this.state.tab} classes={classes} />
        </GridItem>
        {this.props.pages[this.state.tab].content}
      </Grid>
    );
  }
}

const Tabs = ({ classes, titles, onClick, tab }) => (
  <div style={{ display: 'flex' }}>
    {titles.map((title, index) => (
      <button
        className={`${classes.tab} ${tab === index ? classes.active : ''}`}
        onClick={onClick(index)}
        type="button"
      >
        {title}
      </button>
    ))}
  </div>
);

const VerticalLine = ({ classes, position }) => (
  <div style={{ position: 'relative' }}>
    <hr className={classes.divider} />
    <div
      style={{
        width: position % 2 === 0 ? 170 : 190,
        // transform: `translateX(${isFirst ? 0 : '-180px'})`,
        transform: `translateX(${position * -180}px)`,
      }}
      className={classes.activeLine}
    />
  </div>
);

Tabs.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  tab: PropTypes.number,
  titles: PropTypes.array,
};

VerticalLine.propTypes = {
  classes: PropTypes.object,
  position: PropTypes.number,
};

PageTaps.propTypes = {
  classes: PropTypes.object,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string.isRequired,
};

export default compose(withRouter)(PageTaps);
