import React from 'react';
import PropTypes from 'prop-types';
// core components
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import axios from 'axios';
import { request, URLs } from '../../../request';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
// Styles
import withStyles from '@material-ui/core/styles/withStyles';
import AuditRequestStyle from 'assets/jss/views/AuditRequestStyle';
import { withRouter } from 'react-router-dom';
import {
  getRequestAttachmentsFromHistoryWithoutDuplication,
  prepareRequestTypeLabel,
} from '../../../utils';
import CurrentAuditRequest from './CurrentAuditReqeust';
import PreviousRequests from '../../../components/Requests/PreviousRequests';
import PageTaps from '../../../components/core/CustomTabs/PageTaps';
import Swal from 'sweetalert2';

class ProcessingAuditRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      userDetails: {},
      // auditedRequest: {},
      automationDetails: {},
      request: {},
      history: [],
      attachments: [],
      backofficeDetails: {},
      ranges: [],
      disablityInfo: null,
      rejectReasons: [],
      requestRejectionReasons: [],
    };
  }

  componentDidMount() {
    this.retrieveReqeustInformation();
    this.retrieveRanges();
    this.retrieveRejectReasons();
  }

  retrieveReqeustInformation = () => {
    request
      .GET(URLs.backoffice.SHOW_AUDIT_REQUEST, { id: this.props.task.audit_request_id })
      .then(res => {
        const currentRequest = res.data.auditRequest;
        const backofficeDetails = res.data.backofficeDetails;
        // const auditedRequest = JSON.parse(res.data.auditRequest.request_info);
        const automationDetails = JSON.parse(res.data.auditRequest.automation_info);
        const automationDisablityInfo = JSON.parse(automationDetails.disability_info);
        const disablityInfo = automationDisablityInfo
          ? {
              isDisable: true,
              responsibleId: automationDisablityInfo.Responsible_ID,
              responsibleName: automationDisablityInfo.Responsible_Name,
            }
          : null;
        const userDetails = {
          ...JSON.parse(res.data.auditRequest.user_info),
          job: automationDetails.occupation,
          nationality: automationDetails.nationality,
          premium_residency: automationDetails.premium_residency,
        };

        this.setState({
          request: currentRequest,
          automationDetails,
          // auditedRequest,
          userDetails,
          disablityInfo,
          backofficeDetails,
          loading: false,
        });

        request
          .GET(URLs.backoffice.ATTACHMENTS_HISTORY, {
            user_id: userDetails.id_number,
            type: currentRequest.request_type,
          })
          .then(response => {
            const history = response.data;
            const attachments = this.getAttachments(response);
            this.setState({
              history,
              attachments,
            });
          });

        request
          .GET(URLs.evisa.REQUEST_REJECTON_REASONS, {
            type: currentRequest.request_type,
            id: currentRequest.request_id,
          })
          .then(response => {
            this.setState({
              requestRejectionReasons: response.data.rejection_reasons,
            });
          });
      });
  };

  getAttachments = history => {
    if (this.state.request.processed_by !== 'backoffice') {
      return null;
    }
    const attachmentsIds = JSON.parse(
      this.state.backofficeDetails.processingRequest.attachments[0].uploads_IDs,
    );

    return getRequestAttachmentsFromHistoryWithoutDuplication(history, attachmentsIds);
  };

  retrieveRanges = () => {
    request.GET(URLs.backoffice.RANGES_ALL).then(res => {
      this.setState({
        ranges: res.data.ranges,
      });
    });
  };

  retrieveRejectReasons = () =>
    request.GET(URLs.evisa.REJECTION_REASONS).then(res => {
      this.setState({
        rejectReasons: res.data.reasons,
      });
    });

  handleAcceptance = () => {
    request
      .POST(URLs.backoffice.AUDIT_ACCEPT_REQUEST, {
        task_id: this.props.task.id,
      })
      .then(() => {
        Swal.fire({
          title: 'تم التنفيذ',
          text: 'تم تسجيل الطلب كإجراء صحيح',
          type: 'success',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'حسنا',
        }).then(() => {
          this.props.onTaskFinished();
        });
      });
  };

  handleRejection = (reasons, decisionBy, notes) => {
    request
      .POST(URLs.backoffice.AUDIT_REJECT_REQUEST, {
        task_id: this.props.task.id,
        rejection_reasons: reasons,
        decision_by: decisionBy,
        notes,
      })
      .then(() => {
        Swal.fire({
          title: 'تم التنفيذ',
          text: 'تم تسجيل الطلب كإجراء صحيح',
          type: 'success',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'حسنا',
        }).then(() => {
          this.props.onTaskFinished();
        });
      });
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return (
        <div
          style={{
            display: 'flex',
            padding: 30,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    const pages = [];
    pages.push({
      title: 'الطلب الحالي',
      content: (
        <CurrentAuditRequest
          classes={classes}
          request={this.state.request}
          userDetails={this.state.userDetails}
          automationDetails={this.state.automationDetails}
          backofficeDetails={this.state.backofficeDetails}
          disablityInfo={this.state.disablityInfo}
          attachments={this.state.attachments}
          ranges={this.state.ranges}
          onAccept={this.handleAcceptance}
          onReject={this.handleRejection}
          rejectReasons={this.state.rejectReasons}
          requestRejectionReasons={this.state.requestRejectionReasons}
        />
      ),
    });
    pages.push({
      title: 'الطلبات السابقة',
      content: (
        <PreviousRequests
          classes={classes}
          requests={this.state.history}
          user={this.state.userDetails}
        />
      ),
    });

    return (
      <PageTaps
        title={`طلب ${prepareRequestTypeLabel(this.state.request.request_type)}`}
        pages={pages}
        classes={classes}
      />
    );
  }
}

ProcessingAuditRequest.propTypes = {
  classes: PropTypes.object.isRequired,
  onTaskFinished: PropTypes.func.isRequired,
  task: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(AuditRequestStyle),
  connect(mapStateToProps),
  withRouter,
)(ProcessingAuditRequest);
