import React from 'react';
import 'react-widgets/dist/css/react-widgets.css';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import SubmitButton from 'components/core/CustomButtons/SubmitButton';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import { Multiselect } from 'react-widgets';

class RejectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: [],
    };
  }

  handleRejectReasonChange = value => {
    const array = [];
    // eslint-disable-next-line no-unused-vars
    value.map(data => array.push(data.id));
    this.setState({ reasons: array });
  };

  handleRejection = () => {
    this.props.onSubmit(this.state.reasons);
    this.props.onCancel();
  };

  render() {
    const { classes, selectReason = [], onCancel } = this.props;
    const TagItem = ({ item }) => (
      <span>
        <strong>{item.name}</strong>
      </span>
    );

    return (
      <Card>
        <CardHeader className={classes.header}>
          <p className={classes.title}>رفض الطلب</p>
        </CardHeader>
        <CardBody className={classes.modalContainer}>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl className={classes.row}>
              <label className={classes.mLabel}>سبب الرفض</label>
              <div>
                <Multiselect
                  data={selectReason}
                  tagComponent={TagItem}
                  textField="name"
                  valueField="id"
                  onChange={value => this.handleRejectReasonChange(value)}
                  caseSensitive={false}
                  filter="contains"
                />
              </div>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.modalFooter}>
              <Button color="danger" outline onClick={onCancel}>
                الغاء
              </Button>
              <SubmitButton
                color="primary"
                onClick={this.handleRejection}
                disabled={!this.state.reasons[0]}
              >
                إرسال
              </SubmitButton>
            </div>
          </GridItem>
        </CardBody>
      </Card>
    );
  }
}

RejectForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  selectReason: PropTypes.array.isRequired,
};

export default RejectForm;
