/* eslint-disable react/sort-comp */
import React from 'react';
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
// core components
import PropTypes from 'prop-types';
import 'assets/css/EscalationReasonsStyle.css';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid/Grid';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import CardIcon from 'components/core/Card/CardIcon';
import Icon from '@material-ui/icons/Help';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { request, URLs } from '../../request';
import Modal from '@material-ui/core/Modal';
import Swal from 'sweetalert2';
// styles
import EscalationReasonsStyle from 'assets/jss/views/EscalationReasonsStyle';
import { AUDIT_ACTIONS, AUDIT_MODULES } from '../../constants';
import HistoryIcon from '@material-ui/icons/History';
import Switch from 'react-switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import { Tooltip } from '@material-ui/core';

class ListEscalationReasons extends React.Component {
  state = {
    data: [],
    loading: true,
    alert: false,
    isNewReasonOpen: false,
    isSelectedReasonOpen: false,
    subInputs: [],
    escalate_to: '1',
    recordHistory: [],
    filter_escalate_to: '',
  };

  componentDidMount() {
    request
      .GET(URLs.backoffice.ESCALATION_REASONS_CATEGORIES)
      .then(respond => {
        this.setState({
          loading: false,
          data: respond.data,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  isEscalationUsedBySystem = escalation => {
    return escalation.system_id !== 0;
  };

  handleFilterEscalateTo = event => {
    this.setState({ filter_escalate_to: event.target.value });
  };

  handleSearchSubmit = () => {
    request
      .GET(URLs.backoffice.ESCALATION_REASONS_CATEGORIES, {
        escalate_to: this.state.filter_escalate_to,
      })
      .then(respond => {
        this.setState({
          loading: false,
          data: respond.data,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleClickOpen = id => {
    this.setState({ alert: true, idNum: id });
  };

  handleClose = () => {
    this.setState({ alert: false });
  };

  /* ****** add escalation reason ******* */

  handleOpenNewReason = () => this.setState({ isNewReasonOpen: true });

  handleNewReasonChange = e => this.setState({ newReasonValue: e.target.value });

  handleSubmitAddReason = () => {
    let check = true;
    const title = this.state.newReasonValue;
    const text = this.state.subInputs
      ? Object.values(this.state.subInputs).map(sub => sub.value)
      : [];
    const escalateTo = this.state.escalate_to;
    // eslint-disable-next-line array-callback-return
    text.map(item => {
      if (item === '') {
        check = false;
      }
    });
    if (check) {
      request
        .POST(URLs.backoffice.ADD_ESCALATION_REASON, {
          title,
          text,
          escalate_to: escalateTo,
        })
        .then(res => {
          const result = res.data[0];
          this.setState(prev => ({
            data: [
              ...prev.data,
              {
                id: result.id,
                title: result.title,
                is_active: result.is_active,
                escalate_to: result.escalate_to,
              },
            ],
          }));
          this.handleCloseNewReason();
        });
    } else {
      this.handleCloseNewReason();
      Swal.fire({
        title: 'عذرا',
        text: 'البيانات المدخلة غير صحيحة، نرجو التأكد من آدخال سبب فرعي',
        type: 'error',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
      });
    }
  };

  handleCloseNewReason = () =>
    this.setState({
      isNewReasonOpen: false,
      selectedReasonValue: null,
      subInputs: [],
    });

  /* ****** update escalation reason ******* */

  handleEditButton = reason => {
    request.GET(URLs.backoffice.ESCALATION_REASONS, { category_id: reason.id }).then(res => {
      this.setState({
        isSelectedReasonOpen: true,
        selectedReasonId: reason.id,
        selectedReasonValue: res.data[0].title,
        escalate_to: res.data[0].escalate_to.toString(),
        subInputs: res.data[0].reason
          ? res.data[0].reason.map(subReason => ({ key: subReason.id, value: subReason.text }))
          : res.data[0].reason,
      });
    });
  };

  handleSelectedReasonChange = e => this.setState({ selectedReasonValue: e.target.value });

  handleSubmitUpdateReason = () => {
    let check = true;
    const title = this.state.selectedReasonValue;
    const text = this.state.subInputs
      ? Object.values(this.state.subInputs).map(sub => sub.value)
      : [];
    const escalateTo = this.state.escalate_to;
    // eslint-disable-next-line array-callback-return
    text.map(item => {
      if (item === '') {
        check = false;
      }
    });
    if (check) {
      const bodyParameters = {
        title,
        escalation_reason_categories_id: this.state.selectedReasonId,
        text,
        escalate_to: escalateTo,
      };

      request.POST(URLs.backoffice.UPDATE_ESCALATION_REASON, bodyParameters).then(res => {
        this.updateCategory(res.data);
      });
      this.handleCloseSelectedReason();
    } else {
      this.handleCloseSelectedReason();
      Swal.fire({
        title: 'عذرا',
        text: 'البيانات المدخلة غير صحيحة، نرجو التأكد من آدخال سبب فرعي',
        type: 'error',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
      });
    }
  };

  handleCloseSelectedReason = () =>
    this.setState({
      isSelectedReasonOpen: false,
      selectedReasonId: null,
      selectedReasonValue: null,
      subInputs: [],
    });

  /* ****** delete escalation reason ******* */

  handleDeleteSubmit = () => {
    const id = this.state.idNum;
    request.DELETE(URLs.backoffice.ESCALATION_REASONS_CATEGORIES, { id }).then(() => {
      this.setState(data => ({
        data: data.data.filter(reason => reason.id !== id),
      }));
      this.handleClose();
    });
  };

  /* ****** helping escalation functions ******* */

  handleNewSubReasonChange = ({ key, value }) =>
    this.setState(prev => ({
      subInputs: prev.subInputs.map(el => {
        const sub = el;
        if (sub.key === key) {
          sub.value = value;
        }
        return sub;
      }),
    }));

  handleAddSubInput = () => {
    this.setState(prev => ({
      counter: prev.counter + 1 || 1,
      subInputs: [...prev.subInputs, { key: `input${prev.counter || 0}`, value: '' }],
    }));
  };

  handleRemoveSubInput = key => {
    this.setState(prev => {
      const updatedSubInputs = prev.subInputs.filter(inp => inp.key !== key);
      return {
        counter: prev.counter - 1,
        subInputs: updatedSubInputs,
      };
    });
  };

  handlescalateToChange = val => {
    this.setState({ escalate_to: val });
  };

  handleHistoryOpen = record => {
    this.setState({ modalLoading: true, isHistoryOpen: true });
    request
      .GET(URLs.backoffice.AUDIT_HISTORY, { module: 'EscalationReasons', id: record.id })
      .then(res => {
        this.setState({
          recordHistory: res.data,
          modalLoading: false,
        });
      });
  };

  handleHistoryClose = () =>
    this.setState({
      isHistoryOpen: false,
      recordHistory: [],
    });

  handleToggleActivation = (id, value) =>
    request
      .POST(URLs.backoffice.ESCALATION_REASONS_ACTIVATION, { category_id: id, is_active: value })
      .then(res => {
        this.updateCategory(res.data);
      });

  updateCategory = updatedCategory => {
    const arr = this.state.data.map(category => {
      if (category.id === updatedCategory.id) {
        return updatedCategory;
      }
      return category;
    });
    this.setState({ data: [...arr] });
  };

  render() {
    const { classes } = this.props;
    const { recordHistory, modalLoading } = this.state;

    return (
      <div>
        <div>
          <Grid container>
            <GridItem xs={2} sm={2} md={2}>
              <h5>نوع التصنيف </h5>
              <Select
                style={{ display: 'flex', flex: 1 }}
                onChange={this.handleFilterEscalateTo}
                value={this.state.filter_escalate_to}
              >
                <MenuItem value="">الجميع</MenuItem>
                <MenuItem value="1">المصعد</MenuItem>
                <MenuItem value="2">الجوده</MenuItem>
              </Select>
            </GridItem>

            <GridItem xs={4} sm={4} md={4} style={{ marginTop: '50px' }}>
              <Button color="primary" onClick={this.handleSearchSubmit} justIcon>
                <SearchIcon />
              </Button>
            </GridItem>
          </Grid>
          <Grid container>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader
                  color="info"
                  icon
                  title="اسباب التصعيد"
                  subtitle="تجد هنا قائمة من اسباب التصعيد"
                >
                  <CardIcon color="info" isTableIcon>
                    <Icon />
                  </CardIcon>

                  <div style={{ display: 'inline-block', float: 'left' }}>
                    <Button
                      color="info"
                      style={{ marginTop: 20, fontWeight: 700 }}
                      onClick={this.handleOpenNewReason}
                      link
                    >
                      إضافة سبب جديد
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Grid container justify="center">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {this.state.loading === true ? (
                      <CircularProgress className={classes.progress} />
                    ) : !this.state.data.length ? (
                      <h3>لا يوجد تصنيفات</h3>
                    ) : (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>اسم التصنيف</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>نوع التصنيف</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>تفعيل</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.data.map(el => (
                            <TableRow key={el.id}>
                              <TableCell>{el.title}</TableCell>
                              <TableCell>{el.escalate_to === 1 ? 'المصعد' : 'الجوده'}</TableCell>
                              <TableCell>
                                <span dir="ltr">
                                  <Switch
                                    onChange={() =>
                                      this.handleToggleActivation(el.id, !el.is_active)
                                    }
                                    checked={el.is_active}
                                    onColor="#28a745"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    height={20}
                                    width={40}
                                  />
                                </span>
                              </TableCell>
                              <TableCell className={classes.actionsCell}>
                                <Button
                                  color="info"
                                  className={classes.button}
                                  onClick={() => this.handleEditButton(el)}
                                  id={el.id}
                                  link
                                >
                                  <Edit color="secondary" />
                                </Button>

                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                                <Tooltip
                                  title="لا يمكن حذف سبب تصعيد مستعمل من قبل النظام"
                                  disableFocusListener={!this.isEscalationUsedBySystem(el)}
                                  disableHoverListener={!this.isEscalationUsedBySystem(el)}
                                  disableTouchListener={!this.isEscalationUsedBySystem(el)}
                                >
                                  <span>
                                    <Button
                                      color="danger"
                                      disabled={this.isEscalationUsedBySystem(el)}
                                      className={classes.button}
                                      onClick={e => this.handleClickOpen(el.id, e)}
                                      id={el.id}
                                      link
                                    >
                                      <Delete color="error" />
                                    </Button>
                                  </span>
                                </Tooltip>
                                <Dialog
                                  open={this.state.alert}
                                  onClose={this.handleClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {'هل أنت متأكد ؟'}
                                  </DialogTitle>
                                  <DialogActions>
                                    <Button onClick={this.handleClose} color="primary">
                                      لا
                                    </Button>
                                    <Button
                                      onClick={() => this.handleDeleteSubmit(el)}
                                      color="primary"
                                    >
                                      نعم
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                <Button onClick={() => this.handleHistoryOpen(el)} link>
                                  <HistoryIcon color="primary" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </div>
        <Modal
          className={classes.modal}
          open={this.state.isNewReasonOpen}
          onClose={this.handleCloseNewReason}
        >
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.title}>سبب تصعيد جديد</p>
            </div>
            <div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="newReason" className={classes.mLabel}>
                    سبب التصعيد
                  </label>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <input
                    id="newReason"
                    value={this.state.newReasonValue}
                    onChange={this.handleNewReasonChange}
                    className={classes.mMainInput}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                </GridItem>
              </div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="newReason" className={classes.mLabel}>
                    سبب فرعي
                  </label>
                </GridItem>
                {this.state.subInputs
                  ? Object.values(this.state.subInputs).map(inp => (
                      <GridItem xs={12} sm={12} md={12}>
                        <input
                          id={inp.key}
                          value={inp.value}
                          onChange={e =>
                            this.handleNewSubReasonChange({ key: inp.key, value: e.target.value })
                          }
                          className={classes.mSubInput}
                        />
                        <button
                          type="button"
                          className={classes.mDeleteIcon}
                          onClick={() => this.handleRemoveSubInput(inp.key)}
                        >
                          x
                        </button>
                      </GridItem>
                    ))
                  : null}

                <div style={{ textAlign: 'end' }}>
                  <Button color="info" onClick={this.handleAddSubInput} link>
                    إضافة سبب فرعي جديد
                  </Button>
                </div>
              </div>

              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="escalateTo" className={classes.mLabel}>
                    نوع التصنيف
                  </label>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <label>
                    <Radio
                      checked={this.state.escalate_to === '1'}
                      onChange={e => this.handlescalateToChange(e.target.value)}
                      value="1"
                      icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                      classes={{
                        checked: classes.radio,
                      }}
                    />
                    المصعد
                  </label>

                  <label>
                    <Radio
                      checked={this.state.escalate_to === '2'}
                      onChange={e => this.handlescalateToChange(e.target.value)}
                      value="2"
                      icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                      classes={{
                        checked: classes.radio,
                      }}
                    />
                    الجوده
                  </label>
                </GridItem>
              </div>

              <div className={classes.mFooter}>
                <Button
                  color="danger"
                  outline
                  onClick={this.handleCloseNewReason}
                  style={{ marginTop: 10 }}
                >
                  إلغاء
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: 10 }}
                  onClick={this.handleSubmitAddReason}
                  disabled={!this.state.newReasonValue}
                >
                  إرسال
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className={classes.modal}
          open={this.state.isSelectedReasonOpen}
          onClose={this.handleCloseSelectedReason}
        >
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.title}>تعديل سبب التصعيد</p>
            </div>
            <div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="resonTitle" className={classes.mLabel}>
                    سبب التصعيد
                  </label>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <input
                    id="resonTitle"
                    value={this.state.selectedReasonValue}
                    onChange={this.handleSelectedReasonChange}
                    className={classes.mMainInput}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                </GridItem>
              </div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="newReason" className={classes.mLabel}>
                    سبب فرعي
                  </label>
                </GridItem>
                {this.state.subInputs
                  ? Object.values(this.state.subInputs).map(inp => (
                      <GridItem xs={12} sm={12} md={12}>
                        <input
                          id={inp.key}
                          value={inp.value}
                          onChange={e =>
                            this.handleNewSubReasonChange({ key: inp.key, value: e.target.value })
                          }
                          className={classes.mSubInput}
                        />
                        <button
                          type="button"
                          className={classes.mDeleteIcon}
                          onClick={() => this.handleRemoveSubInput(inp.key)}
                        >
                          x
                        </button>
                      </GridItem>
                    ))
                  : null}

                <div style={{ textAlign: 'end' }}>
                  <Button color="info" onClick={this.handleAddSubInput} link>
                    إضافة سبب فرعي جديد
                  </Button>
                </div>
              </div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="escalateTo" className={classes.mLabel}>
                    نوع التصنيف
                  </label>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <label>
                    <Radio
                      checked={this.state.escalate_to === '1'}
                      onChange={e => this.handlescalateToChange(e.target.value)}
                      value="1"
                      icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                      classes={{
                        checked: classes.radio,
                      }}
                    />
                    المصعد
                  </label>

                  <label>
                    <Radio
                      checked={this.state.escalate_to === '2'}
                      onChange={e => this.handlescalateToChange(e.target.value)}
                      value="2"
                      icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                      classes={{
                        checked: classes.radio,
                      }}
                    />
                    الجوده
                  </label>
                </GridItem>
              </div>

              <div className={classes.mFooter}>
                <Button
                  color="danger"
                  outline
                  onClick={this.handleCloseSelectedReason}
                  style={{ marginTop: 10 }}
                >
                  إلغاء
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: 10 }}
                  onClick={this.handleSubmitUpdateReason}
                  disabled={!this.state.selectedReasonValue}
                >
                  إرسال
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className={`${classes.modal} ${classes.modal_wide} ${classes.historyModal}`}
          open={this.state.isHistoryOpen}
          onClose={this.handleHistoryClose}
        >
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.mTitle}>الأحداث المتعلقة</p>
            </div>

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    النوع
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    الحدث
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    تاريخ الحدث
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    بواسطة
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    تفاصيل
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {recordHistory.map(his => (
                  <TableRow>
                    <TableCell className={classes.cell}>{AUDIT_MODULES[his.module]}</TableCell>
                    <TableCell className={classes.cell}>{AUDIT_ACTIONS[his.event]}</TableCell>
                    <TableCell className={classes.lgCell}>{his.datetime}</TableCell>
                    <TableCell className={classes.cell}>{his.user}</TableCell>
                    <TableCell
                      className={classes.lgCell}
                      dangerouslySetInnerHTML={{ __html: his.details }}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {modalLoading ? (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <CircularProgress />
              </div>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  }
}

ListEscalationReasons.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};

export default withStyles(EscalationReasonsStyle)(ListEscalationReasons);
