import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
// core components
import GridItem from 'components/core/Grid/GridItem';
import RequestDetails from '../../../components/Requests/RequestDetails';
import AutomationAuditedRequest from '../../../components/Requests/AutomationAuditedRequest';
import BackofficeAuditedRequest from '../../../components/Requests/BackofficeAuditedRequest';
import SubmitButton from 'components/core/CustomButtons/SubmitButton';
import ModalButton from '../../../components/core/CustomButtons/ModalButton';
import AuditRejectForm from '../../../components/Forms/AuditRejectForm';

class CurrentAuditRequest extends React.Component {
  componentDidMount() {}

  handleRejection = () => {};

  render() {
    const {
      classes,
      request,
      userDetails,
      backofficeDetails,
      automationDetails,
      disablityInfo,
      attachments,
      ranges,
      rejectReasons,
      requestRejectionReasons,
    } = this.props;
    const selectReason = [];
    rejectReasons.map(item => selectReason.push({ name: item.label, id: parseInt(item.id, 10) }));
    return (
      <Grid>
        <GridItem xs={12} sm={12} md={12}>
          <RequestDetails
            classes={classes}
            title={'تفاصيل الطلب'}
            id={request.request_id}
            userDetails={userDetails}
            disabilityDetails={disablityInfo || {}}
          />
          <AutomationAuditedRequest
            classes={classes}
            automationDetails={automationDetails}
            status={request.request_status}
            rejectionReasons={requestRejectionReasons}
          />
          {request.processed_by === 'backoffice' && (
            <BackofficeAuditedRequest
              attachments={attachments}
              userIdNumber={userDetails.id_number}
              classes={classes}
              backofficeDetails={backofficeDetails}
              userDetails={userDetails}
              ranges={ranges}
              status={request.request_status}
              isDisable={!!disablityInfo}
            />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.actionsContainer}>
            <SubmitButton color="primary" onClick={this.props.onAccept}>
              إجراء صحيح
            </SubmitButton>
            <ModalButton classes={classes} type="danger" label="إجراء خاطئ">
              <AuditRejectForm
                classes={classes}
                selectReason={selectReason}
                onSubmit={this.props.onReject}
              />
            </ModalButton>
          </div>
        </GridItem>
      </Grid>
    );
  }
}

CurrentAuditRequest.propTypes = {
  attachments: PropTypes.object.isRequired,
  automationDetails: PropTypes.object.isRequired,
  backofficeDetails: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  disablityInfo: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  ranges: PropTypes.object.isRequired,
  rejectReasons: PropTypes.array.isRequired,
  request: PropTypes.object.isRequired,
  requestRejectionReasons: PropTypes.array.isRequired,
  userDetails: PropTypes.object.isRequired,
};

export default CurrentAuditRequest;
