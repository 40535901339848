export const AUDIT_SYSTEM = () => {
  return {
    type: 'AUDIT_SYSTEM',
  };
};

export const BACKOFFICE_SYSTEM = () => {
  return {
    type: 'BACKOFFICE_SYSTEM',
  };
};

export const SWITCH_SYSTEM = () => {
  localStorage.setItem('system', 'backoffice');
  return {
    type: 'SWITCH_SYSTEM',
  };
};
