import ModalStyle from '../core/modalStyle';
import { grayColor, infoColor, fontSize } from 'assets/jss/material-dashboard-react';

const UserDetailsStyle = () => ({
  header: {
    borderBottom: 'solid #e0e0e0 1px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontSize: fontSize.xl,
    flex: 1,
    color: infoColor,
  },
  subtitle: {
    color: grayColor,
    minHeight: 'auto',
    fontWeight: '300',
    textDecoration: 'none',
  },
  infoContainer: {
    marginRight: 50,
  },

  row: {
    marginBottom: 20,
    width: '100%',
  },

  label: {
    color: '#9f9ca2',
    fontSize: fontSize.m,
  },
  data: {
    backgroundColor: '#efefef',
    width: 220,
    height: 40,
    padding: '10px 0 10px 10px',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: '#9f9ca2',
  },
  actionsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },

  permissionBlock: {
    display: 'inline-block',
    borderRadius: 4,
    minHeight: 28,
    fontSize: 15,
    padding: '7px 10px',
    margin: 5,
    fontWeight: 300,
    color: '#fff',
    textAlign: 'center',
    backgroundColor: '#53A653',
  },

  ...ModalStyle(),
});

export default UserDetailsStyle;
