import React from 'react';
import 'react-widgets/dist/css/react-widgets.css';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import SubmitButton from 'components/core/CustomButtons/SubmitButton';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import { TextField, Select, OutlinedInput, MenuItem } from '@material-ui/core';

class AuditReportReplyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: props.notes ? props.notes : '',
      decision: props.decision ? props.decision : 0,
    };
  }

  handleNotesChange = event => {
    this.setState({
      notes: event.target.value,
    });
  };

  handleDecisionChange = event => {
    this.setState({
      decision: event.target.value,
    });
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.notes, this.state.decision);
    this.props.onCancel();
  };

  render() {
    const { classes, onCancel } = this.props;
    return (
      <Card>
        <CardHeader className={classes.header}>
          <p className={classes.title}>تعليق على الاجراء</p>
        </CardHeader>
        <CardBody className={classes.modalContainer}>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl className={classes.row}>
              <label className={classes.mLabel}>الإجراء المتخذ من قبل تكامل</label>
              <div>
                <Select
                  onChange={this.handleDecisionChange}
                  value={this.state.decision}
                  input={<OutlinedInput name="type" />}
                  className={classes.escalationSelect}
                >
                  <MenuItem value={0}>
                    <span style={{ color: '#9f9ca2' }}>الإجراء</span>
                  </MenuItem>
                  <MenuItem value={1}>
                    <span>صحيح</span>
                  </MenuItem>
                  <MenuItem value={2}>
                    <span>خاطئ</span>
                  </MenuItem>
                </Select>
              </div>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl className={classes.row}>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                label="الملاحظات من قبل تكامل"
                value={this.state.notes}
                onChange={this.handleNotesChange}
              />
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.modalFooter}>
              <Button color="danger" outline onClick={onCancel}>
                الغاء
              </Button>
              <SubmitButton
                color="primary"
                onClick={this.handleSubmit}
                disabled={!this.state.decision}
              >
                إرسال
              </SubmitButton>
            </div>
          </GridItem>
        </CardBody>
      </Card>
    );
  }
}

AuditReportReplyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  decision: PropTypes.number,
  notes: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default AuditReportReplyForm;
