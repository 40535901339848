/* eslint-disable no-unused-vars */

import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/core/Card/Card';
import CardBody from 'components/core/Card/CardBody';
import CardHeader from 'components/core/Card/CardHeader';

const getItem = (laborsDetails, key) => {
  return laborsDetails ? laborsDetails[key] : '';
};

const AutomationAuditedRequest = ({
  classes,
  automationDetails,
  status,
  rejectionReasons = [],
}) => {
  const laborDetails = JSON.parse(automationDetails.labors_details);
  return (
    <Card>
      <CardHeader className={classes.header}>
        <p className={classes.title}>تفاصيل الأتمتة</p>
      </CardHeader>
      <CardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className={classes.infoContainer}>
          <p className={classes.label}>الحالة الإجتماعية</p>
          <p className={classes.data}>{automationDetails.key}</p>
        </div>
        <div className={classes.infoContainer}>
          <p className={classes.label}>عدد المكفولين</p>
          <p className={classes.data}>{automationDetails.workers}</p>
        </div>
        <div className={classes.infoContainer}>
          <p className={classes.label}>التأشيرات والكفالة الرجالية</p>
          <p className={classes.data}>{getItem(laborDetails, 'male_count')}</p>
        </div>
        <div className={classes.infoContainer}>
          <p className={classes.label}>التأشيرات والكفالة النسائية</p>
          <p className={classes.data}>{getItem(laborDetails, 'female_count')}</p>
        </div>
        <div className={classes.infoContainer}>
          <p className={classes.label}>التأشيرات والكفالة للعامل المنزلي</p>
          <p className={classes.data}>{getItem(laborDetails, 'male_house_worker_count')}</p>
        </div>
        <div className={classes.infoContainer}>
          <p className={classes.label}>التأشيرات والكفالة للعاملة المنزلية</p>
          <p className={classes.data}>{getItem(laborDetails, 'female_house_worker_count')}</p>
        </div>
        <div className={classes.infoContainer}>
          <p className={classes.label}>هل كان قادرا ماليا؟</p>
          <p className={classes.data}>
            {parseInt(automationDetails.isFinanciallyCapable, 10) ? 'نعم' : 'لا'}
          </p>
        </div>
        <div className={classes.infoContainer}>
          <p className={classes.label}>الحالة النهائية</p>
          <p className={classes.data}> {status === 'approved' ? 'مقبول' : 'مرفوض'} </p>
        </div>
        {rejectionReasons.length > 0 && (
          <div className={classes.infoContainer}>
            <p className={classes.label}>أسباب الرفض</p>
            <p className={classes.listData}>
              <ul>
                {rejectionReasons.map(reason => (
                  <li>{reason}</li>
                ))}
              </ul>
            </p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

AutomationAuditedRequest.propTypes = {
  automationDetails: PropTypes.shape({
    key: PropTypes.string.isRequired || PropTypes.number.isRequired,
    workers: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  disabilityDetails: PropTypes.shape({
    isDisable: PropTypes.bool.isRequired,
    responsibleId: PropTypes.string || PropTypes.number,
    responsibleName: PropTypes.string,
  }).isRequired,
  rejectionReasons: PropTypes.array,
  status: PropTypes.string.isRequired,
};

export default AutomationAuditedRequest;
