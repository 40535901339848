import React from 'react';
import Grid from '@material-ui/core/Grid';
import GridItem from 'components/core/Grid/GridItem';
import { Card } from '@material-ui/core';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import QRCode from 'react-qr-code';
import { request, URLs } from '../../request';

class GoogleAuthentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleKey: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    request
      .POST(URLs.backoffice.CURRENT_USER)
      .then(result => this.setState({ googleKey: result.data.google2FA_key }));
  };

  render() {
    const { googleKey } = this.state;
    const link = 'otpauth://totp';
    const constName = '/BACKOFFICE';
    const secret = `?secret=${googleKey}`;
    const url = `${link}${constName}${secret}`;
    return (
      <div>
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                icon
                title="Google Authentication"
                subtitle=" نبذة تعريفية عن كيفية تنزيل و استعمال google authenticator"
              />
              <CardBody>
                <Grid container>
                  <p>التحقق في خطوتين :</p>
                  <p>
                    عند تمكين ميزة التحقق بخطوتين (المعروفة أيضًا باسم المصادقة الثنائية)، أنت بذلك
                    تضيف طبقة أمان إضافية إلى حسابك. يمكنك تسجّل الدخول باستخدام شيء تعرفه (كلمة
                    المرور) وشيء تحصل عليه (رمز يتم إرساله إلى هاتفك). بدلاً من انتظار الرسائل
                    النصية، احصل على رموز تحقق مجانًا من تطبيق Authenticator. حيث إنه يعمل حتى إذا
                    كان هاتفك في وضع عدم الاتصال. التطبيق للايفون والايباد{' '}
                    <a href="https://apps.apple.com/gw/app/google-authenticator/id388497605">
                      {' '}
                      Apple{' '}
                    </a>{' '}
                    تطبيق{' '}
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                      Android
                    </a>{' '}
                    بعد تثبيت تطبيق قوقل لتحقق بخطوتين يمكنك مسح ال QR Code التالي ليتم التفعيل
                    والبدء باستقبال الرموز للدخول للنظام
                  </p>
                </Grid>
                <Grid container justify="center">
                  {googleKey && <QRCode value={url} size="150" framed />}
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </div>
    );
  }
}

export default GoogleAuthentication;
