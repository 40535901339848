import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// core components
import HeaderLinks from 'components/core/Header/HeaderLinks';
import sidebarStyle from 'assets/jss/core/sidebarStyle';
import compose from 'recompose/compose';
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UserGIcon from '@material-ui/icons/GroupAdd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HelpIcon from '@material-ui/icons/Help';
import RepeatIcon from '@material-ui/icons/Repeat';
import BlockIcon from '@material-ui/icons/Block';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import InquiryIcon from '@material-ui/icons/Info';
// utils
import { isAuthorized, isInSystem } from '../../../utils';
// Redux
import { dispatch } from '../../../store';
import { LOGOUT } from '../../../actions';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { SWITCH_SYSTEM } from '../../../actions/system';
import { USER_TYPES } from '../../../constants';
import { request, URLs } from '../../../request';

const Sidebar = ({ ...props }) => {
  const { classes, logoText, auth, system } = props;
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    if (routeName === '') {
      return props.location.pathname === '/';
    }
    return props.location.pathname.indexOf(routeName) > -1;
  }

  const switchSystem = () => {
    props.dispatch(SWITCH_SYSTEM());
    props.history.push('/');
  };

  const links = (
    <List className={classes.list}>
      <NavLink to="/" className={classes.item} key="dashboard">
        <ListItem
          button
          className={`${classes.itemLink} ${
            activeRoute('') || activeRoute('dashboard') ? 'active' : ''
          }`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="الرئيسية" className={classes.itemText} disableTypography />
        </ListItem>
      </NavLink>

      {isAuthorized('users', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/users" className={classes.item} key="users">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('users') ? 'active' : ''}  `}
          >
            <ListItemIcon className={classes.itemIcon}>
              <UserGIcon />
            </ListItemIcon>
            <ListItemText primary="المستخدمين" className={classes.itemText} disableTypography />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('showRequests', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/inquiries/list" className={classes.item} key="inquiries">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('inquiries') ? 'active' : ''}  `}
          >
            <ListItemIcon className={classes.itemIcon}>
              <InquiryIcon />
            </ListItemIcon>
            <ListItemText primary="الاستعلامات" className={classes.itemText} disableTypography />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('processingRequests', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/processingRequests" className={classes.item} key="processingRequests">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('processingRequests') ? 'active' : ''}`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary="طلبات إصدار التأشيرات"
              className={`${classes.itemText} ${
                activeRoute('processingRequests') ? 'active' : ''
              } `}
              disableTypography
            />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('noticesRequests', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/noticesRequests" className={classes.item} key="noticesRequests">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('noticesRequests') ? 'active' : ''}`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary="طلبات إصدار الإشعارات"
              className={classes.itemText}
              disableTypography
            />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('dlRequests', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/dl" className={classes.item} key="dlRequests">
          <ListItem button className={`${classes.itemLink} ${activeRoute('dl') ? 'active' : ''}`}>
            <ListItemIcon className={classes.itemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary="طلبات نقل الخدمات"
              className={classes.itemText}
              disableTypography
            />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('escalationRequests', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/escalationRequests" className={classes.item} key="escalationRequests">
          <ListItem
            button
            className={`${classes.itemLink} ${
              activeRoute('escalationRequests') || activeRoute('processingEscalated')
                ? 'active'
                : ''
            }`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary="طلبات التصعيد"
              className={`${classes.itemText} ${
                activeRoute('escalationRequests') ? 'active' : ''
              } `}
              disableTypography
            />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('qualityRequests', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/quality" className={classes.item} key="qualityRequests">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('quality') ? 'active' : ''}`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary="طلبات الجودة"
              className={`${classes.itemText} ${
                activeRoute('escalationRequests') ? 'active' : ''
              } `}
              disableTypography
            />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('escalationReasons', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/escalationReasons" className={classes.item} key="escalationReasons">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('escalationReasons') ? 'active' : ''}`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="اسباب التصعيد" className={classes.itemText} disableTypography />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('blockReasons', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/blockReasons" className={classes.item} key="blockedReasons">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('blockReasons') ? 'active' : ''}`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="اسباب الحظر" className={classes.itemText} disableTypography />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('blockList', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/blockList" className={classes.item} key="blockList">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('blockList') ? 'active' : ''}`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <BlockIcon />
            </ListItemIcon>
            <ListItemText primary="قائمة الحظر" className={classes.itemText} disableTypography />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('modifyRanges', auth) && isInSystem('backoffice', system) ? (
        <NavLink to="/ranges" className={classes.item} key="modifyRanges">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('ranges') ? 'active' : ''}`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <QuestionAnswer />
            </ListItemIcon>
            <ListItemText
              primary="إجابات القدرة المالية"
              className={classes.itemText}
              disableTypography
            />
          </ListItem>
        </NavLink>
      ) : null}

      {isAuthorized('auditReports', auth) && isInSystem('audit', system) ? (
        <NavLink to="/audit/reports" className={classes.item} key="auditReports">
          <ListItem
            button
            className={`${classes.itemLink} ${activeRoute('/audit/reports') ? 'active' : ''}`}
          >
            <ListItemIcon className={classes.itemIcon}>
              <InquiryIcon />
            </ListItemIcon>
            <ListItemText primary="التقارير" className={classes.itemText} disableTypography />
          </ListItem>
        </NavLink>
      ) : null}

      {isInSystem('backoffice', system) ? (
        <NavLink to="/googleAuthentication" className={classes.item} key="googleAuthentication">
          <ListItem
            button
            className={`${classes.itemLink} ${
              activeRoute('googleAuthentication') ? 'active' : ''
            }  `}
          >
            <ListItemIcon className={classes.itemIcon}>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText
              primary="Google Authenticator"
              className={classes.itemText}
              disableTypography
            />
          </ListItem>
        </NavLink>
      ) : null}

      <button
        type="button"
        className={classes.item}
        key="login"
        onClick={() => {
          request.POST(URLs.backoffice.LOGOUT);
          dispatch(LOGOUT());
        }}
        style={{
          padding: 0,
          background: 'transparent',
          border: 'none',
          width: '100%',
        }}
      >
        <ListItem button className={`${classes.itemLink} ${activeRoute('login') ? 'active' : ''}`}>
          <ListItemIcon className={classes.itemIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="تسجيل خروج" className={classes.itemText} disableTypography />
        </ListItem>
      </button>
    </List>
  );
  const brand = (
    <ListItem className={classes.itemLink}>
      {auth.userType === USER_TYPES.BACKOFFICE_AUDIT_USER && (
        <ListItemIcon className={classes.itemIcon}>
          <IconButton aria-label="switch" onClick={switchSystem}>
            <RepeatIcon />
          </IconButton>
        </ListItemIcon>
      )}
      <ListItemText primary={logoText} className={classes.itemText} disableTypography />
    </ListItem>
  );
  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            {links}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  auth: PropTypes.object,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  dispatch: PropTypes.func,
  handleDrawerToggle: PropTypes.func,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  open: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  system: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    auth: {
      accessToken: state.auth.token,
      userPermissions: state.auth.userPermissions,
      isRoot: state.auth.isRoot,
      userType: state.auth.type,
    },
    system: {
      current: state.system.current,
    },
  };
};

export default compose(
  withStyles(sidebarStyle),
  withRouter,
  connect(mapStateToProps),
)(Sidebar);
