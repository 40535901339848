import { publicRoutes, protectedRoutes } from '../constants';

/* eslint-disable import/prefer-default-export */

export const isAuthorized = (key, { accessToken, isRoot, userPermissions }) =>
  (accessToken && publicRoutes[key]) ||
  isRoot ||
  (userPermissions && userPermissions.includes(protectedRoutes[key]));

export const hasPermission = (permission, { isRoot, userPermissions }) =>
  isRoot || (userPermissions && userPermissions.includes(permission));

export const getToken = () => {
  const cachedToken = localStorage.getItem('Token');
  const cachedAuthToken = localStorage.getItem('auth')
    ? JSON.parse(localStorage.getItem('auth')).token
    : null;

  return cachedToken || cachedAuthToken;
};

export const isInSystem = (systemName, system) => {
  return systemName === system.current;
};

/**
 * Since the user object sometimes return as an array and sometimes as an object
 * we use this function to check the type and return the user object
 * @param userObject: the user object/array returned from the backend
 * @returns the user object
 */
export const getUser = userObject => {
  return Array.isArray(userObject) ? userObject[0] : userObject;
};
