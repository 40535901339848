import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProgressButton from '../core/CustomButtons/ProgressButton';
import Grid from '@material-ui/core/Grid';

/**
 * The main part of any assign page
 * @param classes: Styles
 * @param shouldShowAssign: Ask to display the assign button
 * @param isInitializing: Indicates that the page is still deciding whether to show the
 *        content or show the assign button
 * @param isAssigning: whether it's on the process of assigning or not
 * @param onAssignClick: callback when user click the assign button
 * @param assignLabel: assign button label
 * @param content: the content to be shown when the user is assigned
 * @returns JSX Element
 * @constructor
 */
const AssignPage = ({
  classes,
  shouldShowAssign = false,
  isInitializing = false,
  isAssigning = false,
  onAssignClick,
  assignLabel,
  content,
}) => {
  if (isInitializing) {
    return (
      <Grid container justify="center">
        <CircularProgress className={classes.progress} />
      </Grid>
    );
  }

  if (shouldShowAssign) {
    return (
      <Grid container justify="center">
        <ProgressButton
          loading={isAssigning}
          onClick={onAssignClick}
          classes={classes}
          label={assignLabel}
        />
      </Grid>
    );
  }

  return <React.Fragment> {content} </React.Fragment>;
};

AssignPage.propTypes = {
  assignLabel: PropTypes.string,
  classes: PropTypes.object,
  content: PropTypes.element,
  isAssigning: PropTypes.bool,
  isInitializing: PropTypes.bool,
  onAssignClick: PropTypes.func,
  shouldShowAssign: PropTypes.bool,
};

export default AssignPage;
