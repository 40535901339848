import { successColor } from 'assets/jss/material-dashboard-react';

const dashboardStyle = {
  successText: {
    color: successColor,
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  stats: {
    color: '#999999',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
    },
  },
  card: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: 200,
    boxShadow: '0 3px 3px 0 rgba(10, 60, 95, 0.08)',
  },
  cardTitlePending: {
    color: '#95cb8a !important',
    fontWeight: 'bold',
  },
  cardTitleEscalation: {
    color: '#74a2c9 !important',
  },
  cardTitleProcessing: {
    color: '#4abbb7 !important',
  },
  cardTitleDone: {
    color: '#e17582 !important',
  },
  dashboardTitle: {
    padding: '0 15px',
  },
  cardCategory: {
    color: '#999999',
    margin: '0',
    fontSize: '12px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '12px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitle: {
    color: '#3C4858',
    marginTop: '10px',
    minHeight: 'auto',
    fontWeight: '300',
    marginBottom: '3px',
    textDecoration: 'none',
    fontSize: '50px',
    '& small': {
      color: '#777',
      fontSize: '35%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',

    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

export default dashboardStyle;
