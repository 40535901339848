import { grayColor, infoColor } from 'assets/jss/material-dashboard-react';
import ModalStyle from '../core/modalStyle';

const BlockListStyle = () => ({
  title: {
    color: infoColor,
    fontSize: '24px',
    display: 'block',
    marginTop: 5,
    marginBottom: 5,
  },
  subtitle: {
    color: grayColor,
    minHeight: 'auto',
    fontWeight: '300',
    textDecoration: 'none',
    display: 'block',
  },
  actionsCell: {
    textAlign: 'end',
    padding: 0,
  },
  note: {
    width: '80%',
  },
  historyModal: {
    width: '60% !important',
  },
  lgCell: {
    minWidth: 120,
    padding: 0,
  },
  cell: {
    padding: 0,
  },
  filterLabel: {
    marginBottom: 5,
    color: 'grey',
  },
  ...ModalStyle(),
});

export default BlockListStyle;
