import React from 'react';
import ReactExport from 'react-data-export';
import Button from 'components/core/CustomButtons/Button';
import PropTypes from 'prop-types';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default class ExportReport extends React.Component {
  printRejectionReasons = rejectionReasons => {
    let reasons = '';
    // eslint-disable-next-line array-callback-return
    rejectionReasons.map(rejectionId => {
      const rejection = this.props.rejectReasons.find(
        rejectionReason => parseInt(rejectionReason.id, 10) === rejectionId,
      );
      reasons += `${rejection.label}\n`;
    });

    return reasons;
  };

  render() {
    const data = this.props.data;
    const rows = [];
    let i = 0;

    const rowsStyle = {
      style: {
        alignment: { vertical: 'center', horizontal: 'center', readingOrder: 2 },
        fill: { patternType: 'solid', fgColor: { rgb: '8DFFFFFF' } },
        font: { sz: '14', color: { rgb: 'F6050C17' } },
        border: {
          top: { style: 'medium', color: { rgb: 'FF02163E' } },
          bottom: { style: 'medium', color: { rgb: 'FF02163E' } },
          left: { style: 'medium', color: { rgb: 'FF02163E' } },
          right: { style: 'medium', color: { rgb: 'FF02163E' } },
        },
      },
    };

    const headersStyle = {
      style: {
        paddingTop: '8px',
        paddingBottom: '8px',
        fill: { patternType: 'solid', fgColor: { rgb: 'FF32508D' } },
        font: { sz: '18', color: { rgb: '8DF8F6F6' } },
        alignment: { vertical: 'center', horizontal: 'center', readingOrder: 2 },
        border: {
          top: { style: 'medium', color: { rgb: 'FF02163E' } },
          bottom: { style: 'medium', color: { rgb: 'FF02163E' } },
          left: { style: 'medium', color: { rgb: 'FF02163E' } },
          right: { style: 'medium', color: { rgb: 'FF02163E' } },
        },
      },
    };

    // eslint-disable-next-line array-callback-return
    data.map(request => {
      i += 1;
      const requestStatus = request.request_status === 'approved' ? 'مقبول' : 'مرفوض';
      const answerStatussRowStyle = JSON.parse(JSON.stringify(rowsStyle));
      const reportDecisionRowStyle = JSON.parse(JSON.stringify(rowsStyle));
      let answerStatus = 'خاطئ';
      answerStatussRowStyle.style.fill.fgColor.rgb = 'FFEF807D';
      if (request.answer.status === 1) {
        answerStatus = 'صحيح';
        answerStatussRowStyle.style.fill.fgColor.rgb = 'FFB1CE94';
      }

      let reportDecision = 'خاطئ';
      reportDecisionRowStyle.style.fill.fgColor.rgb = 'FFEF807D';
      let reportNotes = '';
      let rejectionReasons = '';

      if (request.report) {
        if (request.report.decision === 1) {
          reportDecision = 'صحيح';
          reportDecisionRowStyle.style.fill.fgColor.rgb = 'FFB1CE94';
        }
        if (request.report.notes) {
          reportNotes = request.report.notes;
        }
      }

      const date = new Date(request.answer.created_at);
      const day = `${date.getDate()}`.padStart(2, '0');
      const month = `${date.getMonth() + 1}`.padStart(2, '0');
      const year = date.getFullYear();
      const requestDate = `${year}-${month}-${day}`;
      if (request.answer.rejection_reasons) {
        rejectionReasons = this.printRejectionReasons(request.answer.rejection_reasons);
      }

      rows.push([
        { value: '' },
        { value: i, ...headersStyle },
        { value: request.request_id, ...rowsStyle },
        { value: requestDate.toString(), ...rowsStyle },
        { value: requestStatus, ...rowsStyle },
        { value: answerStatus.toString(), ...answerStatussRowStyle },
        { value: rejectionReasons.toString(), ...rowsStyle },
        { value: reportDecision.toString(), ...reportDecisionRowStyle },
        { value: reportNotes.toString(), ...rowsStyle },
      ]);
    });

    const dataSet = [
      {
        ySteps: 1,
        columns: [
          { title: '', width: { wpx: 30 }, ...rowsStyle },
          { title: 'م', width: { wpx: 40 }, ...headersStyle },
          { title: 'رقم الطلب', width: { wpx: 100 }, ...headersStyle },
          { title: 'تاريخ الطلب', width: { wpx: 120 }, ...headersStyle },
          { title: 'حالة الطلب', width: { wpx: 120 }, ...headersStyle },
          { title: 'الإجراء المتخذ من قبل الوزارة', width: { wpx: 220 }, ...headersStyle },
          { title: 'سبب التصنيف كإجراء خاطئ من قبل الوزارة', width: { wpx: 350 }, ...headersStyle },
          { title: 'الإجراء المتخذ من قبل تكامل', width: { wpx: 210 }, ...headersStyle },
          { title: 'الملاحظات من قبل تكامل', width: { wpx: 300 }, ...headersStyle },
        ],
        data: rows,
      },
    ];

    return (
      <div>
        <ExcelFile element={<Button color="gray">تصدير</Button>} filename="Audit-Report">
          <ExcelSheet dataSet={dataSet} name="Audit-Report" />
        </ExcelFile>
      </div>
    );
  }
}

ExportReport.propTypes = {
  data: PropTypes.array,
  rejectReasons: PropTypes.array,
};
