import React from 'react';
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
// core components
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid/Grid';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardIcon from 'components/core/Card/CardIcon';
import Icon from '@material-ui/icons/Help';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { request, URLs } from '../../request';
import Modal from '@material-ui/core/Modal';
// styles
import 'assets/css/EscalationReasonsStyle.css';
import BlockReasonsStyle from 'assets/jss/views/BlockReasons';
import { AUDIT_ACTIONS } from '../../constants';
import HistoryIcon from '@material-ui/icons/History';
import Switch from 'react-switch';

class ListBlockReasons extends React.Component {
  state = {
    data: [],
    loading: true,
    recordHistory: [],
  };

  componentDidMount() {
    request.GET(URLs.backoffice.BLOCKING_REASONS).then(res => {
      this.setState({
        loading: false,
        data: res.data,
      });
    });
  }

  /* ****** Add Reason ******* */

  handleOpenAddReason = () => this.setState({ isNewReasonOpen: true });

  handleNewReasonChange = e => this.setState({ newReasonValue: e.target.value });

  handleNewReasonSubmit = () => {
    const reason = this.state.newReasonValue;
    request.POST(URLs.backoffice.BLOCKING_REASONS, { reason }).then(res => {
      const { data } = res;
      this.addLocalDate(data.id, data.reason);
      this.handleCloseNewReason();
    });
  };

  addLocalDate = (id, reason) => {
    this.setState(prev => ({ data: [...prev.data, { id, reason }] }));
  };

  handleCloseNewReason = () => this.setState({ isNewReasonOpen: false, newReasonValue: '' });

  /* ****** Update Reason ******* */

  handleOpenEditReason = ({ id, value }) =>
    this.setState({ selectedReasonId: id, selectedReasonValue: value, isSelectedReasonOpen: true });

  handleSelectedReasonChange = e => this.setState({ selectedReasonValue: e.target.value });

  handleUpdateReasonSubmit = () => {
    request
      .PUT(URLs.backoffice.BLOCKING_REASONS, {
        reason_id: this.state.selectedReasonId,
        reason: this.state.selectedReasonValue,
      })
      .then(() => {
        this.updateLocalDate(this.state.selectedReasonId, this.state.selectedReasonValue);
        this.handleCloseSelectedReason();
      });
  };

  updateLocalDate = (id, reason) => {
    this.setState(prev => {
      const arr = prev.data.map(res => {
        const reasonObject = res;
        if (reasonObject.id === id) {
          reasonObject.reason = reason;
        }
        return reasonObject;
      });
      return { data: arr };
    });
  };

  handleCloseSelectedReason = () => this.setState({ isSelectedReasonOpen: false });

  /* ****** Delete Reason ******* */

  handleOpenDelete = item => {
    this.setState({ alert: true, reason_id: item.id });
  };

  handleDeleteButton = () => {
    request
      .DELETE(URLs.backoffice.BLOCKING_REASONS, { reason_id: this.state.reason_id })
      .then(() => {
        this.setState(prev => ({
          data: prev.data.filter(reason => reason.id !== prev.reason_id),
        }));
        this.handleCloseDelete();
      });
  };

  handleCloseDelete = () => {
    this.setState({ alert: false });
  };

  handleHistoryOpen = record => {
    this.setState({ modalLoading: true, isHistoryOpen: true });
    request
      .GET(URLs.backoffice.AUDIT_HISTORY, { module: 'BlockReasons', id: record.id })
      .then(res => {
        this.setState({
          recordHistory: res.data,
          modalLoading: false,
        });
      });
  };

  handleHistoryClose = () =>
    this.setState({
      isHistoryOpen: false,
      recordHistory: [],
    });

  handleToggleActivation = (id, value) =>
    request
      .POST(URLs.backoffice.BLOCKING_REASONS_ACTIVATION, { reason_id: id, is_active: value })
      .then(res => {
        this.updateReason(res.data);
      });

  updateReason = updatedReason => {
    const arr = this.state.data.map(reason => {
      if (reason.id === updatedReason.id) {
        return updatedReason;
      }
      return reason;
    });
    this.setState({ data: [...arr] });
  };

  render() {
    const { classes } = this.props;
    const {
      loading,
      data,
      alert,
      isNewReasonOpen,
      newReasonValue,
      selectedReasonValue,
      isSelectedReasonOpen,
      recordHistory,
      modalLoading,
    } = this.state;
    return (
      <div>
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                icon
                title="أسباب الحظر"
                subtitle="تجد هنا قائمة من أسباب الحظر"
              >
                <CardIcon color="info" isTableIcon>
                  <Icon />
                </CardIcon>

                <div style={{ display: 'inline-block', float: 'left' }}>
                  <Button
                    color="info"
                    style={{ marginTop: 20, fontWeight: 700 }}
                    link
                    onClick={this.handleOpenAddReason}
                  >
                    إضافة سبب جديد
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Grid container justify="center">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {loading === true ? (
                    <CircularProgress className={classes.progress} />
                  ) : !data.length ? (
                    <h3>لا يوجد أسباب حظر</h3>
                  ) : (
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>اسم السبب</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>تفعيل</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map(el => (
                          <TableRow key={el.id}>
                            <TableCell>{el.reason}</TableCell>
                            <TableCell>
                              <span dir="ltr">
                                <Switch
                                  onChange={() => this.handleToggleActivation(el.id, !el.is_active)}
                                  checked={el.is_active}
                                  onColor="#28a745"
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  height={20}
                                  width={40}
                                />
                              </span>
                            </TableCell>
                            <TableCell className={classes.actionsCell}>
                              <Button
                                className={classes.button}
                                id={el.id}
                                onClick={() =>
                                  this.handleOpenEditReason({ id: el.id, value: el.reason })
                                }
                                link
                              >
                                <Edit color="secondary" />
                              </Button>

                              <Button
                                className={classes.button}
                                onClick={() => this.handleOpenDelete(el)}
                                id={el.id}
                                link
                              >
                                <Delete color="error" />
                              </Button>
                              <Button onClick={() => this.handleHistoryOpen(el)} link>
                                <HistoryIcon color="primary" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
        <Dialog
          open={alert}
          onClose={this.handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'هل أنت متأكد ؟'}</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDelete} color="primary">
              لا
            </Button>
            <Button onClick={() => this.handleDeleteButton()} color="primary">
              نعم
            </Button>
          </DialogActions>
        </Dialog>

        <Modal className={classes.modal} open={isNewReasonOpen} onClose={this.handleCloseNewReason}>
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.title}>سبب حظر جديد</p>
            </div>
            <div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="newReason" className={classes.mLabel}>
                    سبب الحظر
                  </label>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <input
                    id="newReason"
                    value={newReasonValue}
                    onChange={this.handleNewReasonChange}
                    className={classes.mMainInput}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                </GridItem>
              </div>
              <div className={`${classes.mFooter}`}>
                <Button
                  color="danger"
                  outline
                  onClick={this.handleCloseNewReason}
                  style={{ marginTop: 10 }}
                >
                  إلغاء
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: 10 }}
                  onClick={this.handleNewReasonSubmit}
                  disabled={!newReasonValue}
                >
                  إرسال
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className={classes.modal}
          open={isSelectedReasonOpen}
          onClose={this.handleCloseSelectedReason}
        >
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.title}>تعديل سبب الحظر</p>
            </div>
            <div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="updateReason" className={classes.mLabel}>
                    سبب الحظر
                  </label>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <input
                    id="updateReason"
                    value={selectedReasonValue}
                    onChange={this.handleSelectedReasonChange}
                    className={classes.mMainInput}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />
                </GridItem>
              </div>
              <div className={`${classes.mFooter}`}>
                <Button
                  color="danger"
                  outline
                  onClick={this.handleCloseSelectedReason}
                  style={{ marginTop: 10 }}
                >
                  إلغاء
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: 10 }}
                  onClick={this.handleUpdateReasonSubmit}
                  disabled={!selectedReasonValue}
                >
                  إرسال
                </Button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          className={`${classes.modal} ${classes.historyModal}`}
          open={this.state.isHistoryOpen}
          onClose={this.handleHistoryClose}
        >
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.mTitle}>الأحداث المتعلقة</p>
            </div>

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    الحدث
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    تاريخ الحدث
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    بواسطة
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    تفاصيل
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {recordHistory.map(his => (
                  <TableRow>
                    <TableCell className={classes.cell}>{AUDIT_ACTIONS[his.event]}</TableCell>
                    <TableCell className={classes.lgCell}>{his.datetime}</TableCell>
                    <TableCell className={classes.cell}>{his.user}</TableCell>
                    <TableCell
                      className={classes.lgCell}
                      dangerouslySetInnerHTML={{ __html: his.details }}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {modalLoading ? (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <CircularProgress />
              </div>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  }
}

ListBlockReasons.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};
export default compose(
  withStyles(BlockReasonsStyle),
  connect(),
)(ListBlockReasons);
