import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

class Download extends React.Component {
  download = (target, fileName) => {
    fetch(target, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.blob())
      .then(response => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {});
  };

  render() {
    const { target, fileName } = this.props;
    return (
      <a
        href={target}
        onClick={e => {
          e.preventDefault();
          this.download(target, fileName);
        }}
      >
        <IconButton>
          <GetAppIcon />
        </IconButton>
      </a>
    );
  }
}

Download.propTypes = {
  fileName: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
};

export default Download;
