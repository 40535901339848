import React from 'react';
import PropTypes from 'prop-types';
import Progress from '@material-ui/core/CircularProgress/CircularProgress';
import Button from 'components/core/CustomButtons/Button';

const progressStyles = {
  alignSelf: 'center',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 10,
};

const ProgressButton = ({ classes, label, loading = false, onClick }) => {
  return (
    <Button
      color="primary"
      round
      className={classes.newAssignBtn}
      onClick={onClick}
      disabled={loading}
      style={loading ? { width: 200 } : {}}
    >
      {label}
      {loading && <Progress style={progressStyles} />}
    </Button>
  );
};

ProgressButton.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ProgressButton;
