import { primaryColor, successColor } from '../material-dashboard-react';
import { fontSize } from 'assets/jss/material-dashboard-react';

const InquiriesStyle = () => ({
  filterInput: {
    border: 'solid 1px',
    borderColor: '#D4D4D4',
    borderRadius: 4,
    textAlign: 'center',
    width: '100%',
    height: 30,
    margin: '10px 0',
  },
  select: {
    padding: 1,
    fontSize: 14,
    '&:before': {
      borderColor: 'transparent',
      borderWidth: '0 !important',
    },
    '&:after': {
      borderColor: 'transparent',
      borderWidth: '0 !important',
    },
    '& *:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectItem: {
    fontSize: 14,
  },
  placeholder: {
    color: '#888',
  },
  searchBtn: {
    float: 'right',
    marginTop: 30,
    marginRight: -5,
    borderRadius: 4,
  },
  label: {
    display: 'inline-block',
    textAlign: 'center',
    color: '#9B9B9B',
    width: '16%',
  },
  labelsRow: {
    padding: '5px 10px',
  },
  dataRow: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F8F7F7',
    borderRadius: 4,
    height: 50,
    padding: '0 12px',
    marginBottom: 20,
  },
  data: {
    display: 'inline-block',
    textAlign: 'center',
    color: '#4A4A4A',
    width: '16%',
  },
  l: {
    width: '20%',
  },
  stateTag: {
    display: 'inline-block',
    width: 105,
    borderRadius: 4,
    minHeight: 28,
    fontSize: 15,
    fontWeight: 300,
    color: '#fff',
    textAlign: 'center',
  },
  status2: {
    backgroundColor: '#53A653',
  },
  status4: {
    backgroundColor: 'rgb(233,110,110)',
  },
  status3: {
    backgroundColor: 'rgb(159,156,162)',
  },
  status5: {
    backgroundColor: 'rgb(255, 216, 0)',
  },
  status9: {
    backgroundColor: 'rgb(211, 104, 227)',
  },
  status11: {
    backgroundColor: 'rgb(131, 15, 120)',
  },
  status1: {
    backgroundColor: 'rgb(255, 216, 0)',
  },
  statusnull: {
    backgroundColor: 'rgb(255, 216, 0)',
  },
  status7: {
    backgroundColor: '#2F5597',
  },
  status8: {
    backgroundColor: '#FF0100',
  },
  dl_status1: {
    backgroundColor: 'rgb(255, 216, 0)',
  },
  dl_status2: {
    backgroundColor: 'rgb(255, 216, 0)',
  },
  dl_status3: {
    backgroundColor: 'rgb(159,156,162)',
  },
  dl_status4: {
    backgroundColor: '#53A653',
  },
  dl_status5: {
    backgroundColor: 'rgb(233,110,110)',
  },
  dl_status6: {
    backgroundColor: 'rgb(225, 147, 237)',
  },
  dl_statusnull: {
    backgroundColor: 'rgb(255, 216, 0)',
  },
  dl_status7: {
    backgroundColor: '#2F5597',
  },
  dl_status8: {
    backgroundColor: '#FF0100',
  },
  showBtn: {
    border: 'solid 2px',
    borderColor: primaryColor,
    borderRadius: 4,
    backgroundColor: '#fff',
    color: primaryColor,
    height: 40,
    '&:hover,&:focus': {
      color: '#000',
    },
  },
  processBtn: {
    border: 'solid 2px',
    borderColor: successColor,
    borderRadius: 4,
    backgroundColor: '#fff',
    color: successColor,
    height: 40,
    '&:hover,&:focus': {
      color: '#000',
    },
  },
  processBtnDisabled: {
    border: 'solid 1px',
    borderColor: '#77bf7a',
    borderRadius: 4,
    backgroundColor: '#fff',
    color: successColor,
    height: 40,
    '&:hover,&:focus': {
      color: '#000',
    },
  },
  tab: {
    fontSize: fontSize.xl,
    width: 180,
    height: 42,
    padding: 0,
    paddingLeft: 10,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#9f9ca2',
    transition: '0.6s',
    textAlign: 'start',
  },
  active: {
    color: '#293d4d',
  },
  activeLine: {
    height: 5,
    backgroundColor: '#58abc8',
    position: 'absolute',
    top: -2,
    transition: '0.4s',
  },
  divider: {
    opacity: 0.21,
    borderColor: '#979797',
  },
});

export default InquiriesStyle;
