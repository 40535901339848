import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import Grid from '@material-ui/core/Grid';
// import ZoomIn from '@material-ui/icons/ZoomIn';
// core components
import GridItem from 'components/core/Grid/GridItem';
import SubmitButton from 'components/core/CustomButtons/SubmitButton';
import Card from 'components/core/Card/Card';
import CardBody from 'components/core/Card/CardBody';
// import axios from 'axios';
import RequestDetails from './RequestDetails';
import AnswerCard from './AnswerCard';
import RequestDates from './RequestDates';
import { FINANCIAL_OPTION_TITLE } from '../../constants';
import RequestLogs from './RequestLogs';
import { request, URLs } from '../../request';
import { rangeType, createRanges, splitRangeString } from '../../utils';

class CurrentRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeType: rangeType(this.props.userDetails, this.props.disabilityDetails.isDisable),
      ranges: {},
      options: [],
      attachmentType: 0,
      attachmentValue: -1,
    };
  }

  componentDidMount() {
    const type = this.state.rangeType;
    request.GET(`${URLs.backoffice.RANGES}?${type}=1`).then(response => {
      this.setState({
        ranges: {
          bank: splitRangeString(response.data[type].bank),
          salary: splitRangeString(response.data[type].salary),
        },
      });
    });
  }

  prepareOptions = value => {
    const ranges = this.state.ranges;
    const type = this.state.rangeType;
    if (value === 0) {
      this.setState({
        options: [],
        attachmentValue: -1,
      });
    } else if (value === 1) {
      this.setState({
        options: ranges.salary ? createRanges(ranges.salary, type, 'راتب') : [],
        attachmentValue: -1,
      });
    } else if (value === 2) {
      this.setState({
        options: ranges.bank ? createRanges(ranges.bank, type, '') : [],
        attachmentValue: -1,
      });
    }
  };

  handleAttachTypeChange = e => {
    this.setState({ attachmentType: e.target.value });
    this.prepareOptions(e.target.value);
  };

  handleAttachmentValueChange = e => {
    this.setState({ attachmentValue: e.target.value });
  };

  handleSubmit = () => {
    const option = this.state.attachmentType === 1 ? 'salary' : 'bank';
    const range = this.state.attachmentValue;
    const type = this.state.rangeType;
    this.props.onSubmit(option, range, type);
  };

  render() {
    const {
      classes,
      id,
      title,
      userDetails,
      disabilityDetails,
      attachments,
      userIdNumber,
      category,
      reasons,
      notes,
      escalationNotes,
      requestId,
      showLogs,
      dateCreated,
      dateEscalated,
    } = this.props;

    return (
      <Grid>
        <GridItem xs={12} sm={12} md={12}>
          <RequestDetails
            classes={classes}
            title={title}
            id={id}
            userDetails={userDetails}
            disabilityDetails={disabilityDetails}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <AnswerCard
            attachmentOptions={this.state.options}
            attachments={attachments}
            attachmentTypes={Object.values(FINANCIAL_OPTION_TITLE)}
            chosenOption={this.state.attachmentValue}
            chosenType={this.state.attachmentType}
            onTypeChange={this.handleAttachTypeChange}
            onValueChange={this.handleAttachmentValueChange}
            userIdNumber={userIdNumber}
            classes={classes}
          />
        </GridItem>

        {category && (
          <GridItem xs={12} sm={12} md={12}>
            <EscalationReasons reasons={reasons} category={category} notes={notes} />
          </GridItem>
        )}

        {escalationNotes && (
          <GridItem xs={12} sm={12} md={12}>
            <EscalationNote notes={escalationNotes} />
          </GridItem>
        )}

        {showLogs && (
          <GridItem xs={12} sm={12} md={12}>
            <RequestLogs requestId={requestId} />
          </GridItem>
        )}

        <GridItem xs={12} sm={12} md={12}>
          <RequestDates created={dateCreated} escalated={dateEscalated} />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.actionsContainer}>
            {this.props.actions}
            <SubmitButton
              color="primary"
              onClick={this.handleSubmit}
              disabled={!this.state.attachmentType || this.state.attachmentValue === -1}
            >
              إرسال
            </SubmitButton>
          </div>
        </GridItem>
      </Grid>
    );
  }
}

const EscalationReasons = ({ category, reasons, notes }) => (
  <Card>
    <CardBody>
      <Grid container>
        <GridItem xs={6} sm={6} md={6}>
          <h4>سبب التصعيد</h4>
          <h4 style={{ color: 'red' }}>{category.title}</h4>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <h4>تفاصيل التصعيد</h4>
          <h4>
            <ul style={{ color: 'red' }}>
              {reasons.map($val => (
                <li>{$val.text}</li>
              ))}
            </ul>
          </h4>
        </GridItem>
        {notes && (
          <GridItem xs={6} sm={6} md={4}>
            <h4>مزيد من التفاصيل</h4>
            <h4 style={{ color: 'red' }}>{notes}</h4>
          </GridItem>
        )}
      </Grid>
    </CardBody>
  </Card>
);

const EscalationNote = ({ notes }) => (
  <Card>
    <CardBody>
      <Grid container>
        <GridItem xs={6} sm={6} md={6}>
          <h4>ملاحظات المصعد</h4>
          <h4 style={{ color: 'red' }}>{notes}</h4>
        </GridItem>
      </Grid>
    </CardBody>
  </Card>
);

EscalationNote.propTypes = {
  notes: PropTypes.string,
};

EscalationReasons.propTypes = {
  category: PropTypes.object,
  notes: PropTypes.string,
  reasons: PropTypes.array,
};

CurrentRequest.propTypes = {
  actions: PropTypes.element,
  attachments: PropTypes.array.isRequired,
  category: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dateCreated: PropTypes.string,
  dateEscalated: PropTypes.string,
  disabilityDetails: PropTypes.shape({
    isDisable: PropTypes.bool.isRequired,
    responsibleId: PropTypes.string || PropTypes.number,
    responsibleName: PropTypes.string,
  }).isRequired,
  escalationNotes: PropTypes.string,
  id: PropTypes.string.isRequired || PropTypes.number.isRequired,
  notes: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  reasons: PropTypes.array,
  requestId: PropTypes.string,
  showLogs: PropTypes.bool,
  title: PropTypes.string.isRequired,
  userDetails: PropTypes.shape({
    id_number: PropTypes.string.isRequired || PropTypes.number.isRequired,
    isSaudi: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
  }).isRequired,
  userIdNumber: PropTypes.string.isRequired || PropTypes.number.isRequired,
};

export default CurrentRequest;
