import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import MediaViewer from 'components/core/mediaViewer';
import Attachment from 'components/core/attachment';
import { rangeType, createRanges, splitRangeString } from '../../utils';
import { FINANCIAL_OPTION_TITLE } from '../../constants';

class BackofficeAuditedRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAttachment: this.props.attachments[0]
        ? this.props.attachments[0]
        : { isUndefined: true },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.selectedAttachment.isUndefined) {
      this.setState({
        selectedAttachment: nextProps.attachments[0]
          ? nextProps.attachments[0]
          : { isUndefined: true },
      });
    }
  }

  getFinancialOption = answer => {
    if (!answer || !answer.financial_option || this.props.ranges.length === 0) {
      return '';
    }
    return FINANCIAL_OPTION_TITLE[answer.financial_option];
  };

  getFinancialRange = answer => {
    if (!answer || !answer.financial_option || this.props.ranges.length === 0) {
      return '';
    }

    const usedType = answer.range_type
      ? answer.range_type
      : rangeType(this.props.userDetails, this.props.isDisable);
    const financialRanges = this.props.ranges.find(element => element.id === answer.range_id);
    const ranges = createRanges(
      splitRangeString(financialRanges.ranges[usedType][answer.financial_option]),
    );
    return ranges.find(element => element.value === answer.range).label;
  };

  getAnswerAsString = answer => {
    return `${this.getFinancialOption(answer)}, ${this.getFinancialRange(answer)}`;
  };

  isEscalationAnswer = () => {
    return !!this.props.backofficeDetails.escalationRequest;
  };

  isInquiryAnswer = () => {
    return !!this.props.backofficeDetails.answers.inquiryAnswer;
  };

  isProcessorAnswer = () => {
    return !this.isEscalationAnswer() && !this.isInquiryAnswer();
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.attachmentContainer}>
        <div>
          <CardHeader className={classes.header}>
            <p className={classes.title}>حالة الطلب</p>
          </CardHeader>
          <CardBody>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {this.isProcessorAnswer() && (
                <ProcessorAnswers
                  classes={classes}
                  getAnswerAsString={this.getAnswerAsString}
                  answers={this.props.backofficeDetails.answers}
                />
              )}
              {this.isEscalationAnswer() && (
                <EscalationAnswers
                  classes={classes}
                  getAnswerAsString={this.getAnswerAsString}
                  answer={this.props.backofficeDetails.answers.escalationAnswer}
                  reason={this.props.backofficeDetails.escalationRequest.escalation_reason.title}
                />
              )}
              {this.isInquiryAnswer() && (
                <InquiryAnswer
                  classes={classes}
                  getAnswerAsString={this.getAnswerAsString}
                  answer={this.props.backofficeDetails.answers.inquiryAnswer}
                />
              )}
            </div>
            <FormControl variant="outlined" className={classes.formControl}>
              <div>
                <p className={classes.attachmentTitle}>المرفقات</p>
                <div>
                  {this.props.attachments.map((attachment, index) => (
                    <Attachment
                      className={classes.attachment}
                      title={attachment.original_file_name}
                      fileId={attachment.id}
                      userId={this.props.userIdNumber}
                      selected={this.state.selectedAttachment.id === attachment.id}
                      style={index === 0 && { marginTop: 0 }}
                      onClick={() => this.setState({ selectedAttachment: attachment })}
                      showDownload
                    />
                  ))}
                </div>
              </div>
            </FormControl>
          </CardBody>
        </div>
        <div className={classes.mediaViewerContainer}>
          <MediaViewer
            fileId={this.state.selectedAttachment.id}
            mimeType={this.state.selectedAttachment.mime_type}
            name={this.state.selectedAttachment.original_file_name}
            userId={this.props.userIdNumber}
            className={classes.mediaViewer}
          />
        </div>
      </Card>
    );
  }
}

const ProcessorAnswers = ({ classes, getAnswerAsString, answers }) => (
  <React.Fragment>
    <div className={classes.infoContainer}>
      <p className={classes.label}>اجابة المعالج الاول</p>
      <p className={classes.data}>{getAnswerAsString(answers.processingAnswers[0])}</p>
    </div>
    <div className={classes.infoContainer}>
      <p className={classes.label}>اجابة المعالج الثاني</p>
      <p className={classes.data}>{getAnswerAsString(answers.processingAnswers[1])}</p>
    </div>
  </React.Fragment>
);

const InquiryAnswer = ({ classes, getAnswerAsString, answer }) => (
  <React.Fragment>
    <div className={classes.infoContainer}>
      <p className={classes.label}>اجابة المعالج</p>
      <p className={classes.data}>{getAnswerAsString(answer)}</p>
    </div>
  </React.Fragment>
);

const EscalationAnswers = ({ classes, reason, answer, getAnswerAsString }) => (
  <React.Fragment>
    <div className={classes.infoContainer}>
      <p className={classes.label}>سبب التصعيد</p>
      <p className={classes.data}>{reason}</p>
    </div>
    <div className={classes.infoContainer}>
      <p className={classes.label}>قرار المصعد</p>
      <p className={classes.data}>{getAnswerAsString(answer)}</p>
    </div>
  </React.Fragment>
);

ProcessorAnswers.propTypes = {
  answers: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  getAnswerAsString: PropTypes.func.isRequired,
};

InquiryAnswer.propTypes = {
  answer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getAnswerAsString: PropTypes.func.isRequired,
};

EscalationAnswers.propTypes = {
  answer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  getAnswerAsString: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
};

BackofficeAuditedRequest.propTypes = {
  attachments: PropTypes.array.isRequired,
  backofficeDetails: PropTypes.shape({
    answers: PropTypes.shape({
      escalationAnswer: PropTypes.object,
      inquiryAnswer: PropTypes.object,
      processingAnswers: PropTypes.array,
    }),
    escalationRequest: PropTypes.object.isRequired,
    processingRequest: PropTypes.object.isRequired,
  }),
  classes: PropTypes.object.isRequired,
  isDisable: PropTypes.bool.isRequired,
  ranges: PropTypes.array.isRequired,
  userDetails: PropTypes.object.isRequired,
  userIdNumber: PropTypes.string.isRequired || PropTypes.number.isRequired,
};

export default BackofficeAuditedRequest;
