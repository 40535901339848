/* eslint-disable import/prefer-default-export */

export const PERMISSIONS_TYPES = {
  PROCESS_PROCESSING_REQUESTS: 'PROCESS_PROCESSING_REQUESTS',
  CREATE_USERS: 'CREATE_USERS',
  MANAGE_ESCALATION_REASONS: 'MANAGE_ESCALATION_REASONS',
  PROCESS_ESCALATED_PROCESSING_REQUESTS: 'PROCESS_ESCALATED_PROCESSING_REQUESTS',
  BLOCK_CITIZENS: 'BLOCK_CITIZENS',
  MANAGE_BLOCKING_REASONS: 'MANAGE_BLOCKING_REASONS',
  PROCESS_NOTICE_PROCESSING_REQUESTS: 'PROCESS_NOTICE_PROCESSING_REQUESTS',
  SEARCH_ISSUE_REQUESTS: 'SEARCH_ISSUE_REQUESTS',
  QUALITY_PROCESSING: 'QUALITY_PROCESSING',
  DL_PROCESSING: 'DL_PROCESSING',
  MODIFY_RANGES: 'MODIFY_RANGES',
  INQUIRY_PROCESSING: 'INQUIRY_PROCESSING',
  AUDIT_REPORTS: 'AUDIT_REPORTS',
};

export const PERMISSIONS = {
  PROCESS_PROCESSING_REQUESTS: {
    KEY: 'PROCESS_PROCESSING_REQUESTS',
    LABEL: 'طلبات إصدار التأشيرات',
  },
  CREATE_USERS: {
    KEY: 'CREATE_USERS',
    LABEL: 'إنشاء مستخدمين',
  },
  MANAGE_ESCALATION_REASONS: {
    KEY: 'MANAGE_ESCALATION_REASONS',
    LABEL: 'أسباب التصعيد',
  },
  PROCESS_ESCALATED_PROCESSING_REQUESTS: {
    KEY: 'PROCESS_ESCALATED_PROCESSING_REQUESTS',
    LABEL: 'قائمة الطلبات المصعدة',
  },
  BLOCK_CITIZENS: {
    KEY: 'BLOCK_CITIZENS',
    LABEL: 'قائمة الحظر',
  },
  MANAGE_BLOCKING_REASONS: {
    KEY: 'MANAGE_BLOCKING_REASONS',
    LABEL: 'أسباب الحظر',
  },
  PROCESS_NOTICE_PROCESSING_REQUESTS: {
    KEY: 'PROCESS_NOTICE_PROCESSING_REQUESTS',
    LABEL: 'طلبات إصدار الإشعارات',
  },
  SEARCH_ISSUE_REQUESTS: {
    KEY: 'SEARCH_ISSUE_REQUESTS',
    LABEL: 'الاستعلامات',
  },
  QUALITY_PROCESSING: {
    KEY: 'QUALITY_PROCESSING',
    LABEL: 'قائمة طلبات الجودة',
  },
  DL_PROCESSING: {
    KEY: 'DL_PROCESSING',
    LABEL: 'قائمة طلبات نقل الخدمات',
  },
  MODIFY_RANGES: {
    KEY: 'MODIFY_RANGES',
    LABEL: 'تعديل إجابات القدرة المالية',
  },
  ACTIVATE_DEACTIVATE_USERS: {
    KEY: 'ACTIVATE_DEACTIVATE_USERS',
    LABEL: 'تفعيل/تعطيل مستخدمين',
  },
  INQUIRY_PROCESSING: {
    KEY: 'INQUIRY_PROCESSING',
    LABEL: 'معالجة الطلبات (الإستعلامات)',
  },
  AUDIT_PROCESSING: {
    KEY: 'AUDIT_PROCESSING',
    LABEL: 'معالجة الطلبات (نظام التدقيق)',
  },
  AUDIT_REPORTS: {
    KEY: 'AUDIT_REPORTS',
    LABEL: 'التقارير (نظام التدقيق)',
  },
};

export const FINANCIAL_OPTION = {
  SALARY: 'salary',
  BANK: 'bank',
};

export const FINANCIAL_OPTION_TITLE = {
  [FINANCIAL_OPTION.SALARY]: 'كشف الراتب',
  [FINANCIAL_OPTION.BANK]: 'شهادة بنكية',
};

export const BLOCK_HISTORY_ACTIONS = {
  'back_office.blacklist.add': 'أضيف الى القائمة',
  'back_office.blacklist.deactivate': 'تمت إزالة الحظر',
  'back_office.blacklist.activate': 'تمت اعادة الحظر',
  'back_office.blacklist.update': 'تم التحديث',
};

export const FINANCIAL_RANGE = {
  [FINANCIAL_OPTION.BANK]: [
    { label: 'من 35,000 إلى 79,999', value: 79999 },
    { label: 'من 80,000 إلى 119,999', value: 119999 },
    { label: 'من 120,000 إلى 249,999', value: 249999 },
    { label: 'من 250,000 إلى 499,999', value: 499999 },
    { label: ' أكثر من 500,000', value: Number.MAX_VALUE },
  ],
  [FINANCIAL_OPTION.SALARY]: [
    { label: 'من 5,000 إلى 7,999', value: 7999 },
    { label: 'من 8,000 إل14,999', value: 14999 },
    { label: 'من 15,000 إلى 29,999', value: 29999 },
    { label: 'من 30000 إلى 39,999', value: 39999 },
    { label: 'أكثر من 40,000', value: Number.MAX_VALUE },
  ],
};

export const REQUEST_STATUS = {
  APPROVED: 'مقبول',
  CANCELED: 'ملغى',
  IN_PROCESS: 'تحت المعالجة',
  PENDING: 'بانتظار الدفع',
  REJECTED: 'مرفوض',
  EXPIRED: 'منتهي',
  WAITING_ECONTRACT: 'بانتظار إصدار العقد',
  WAITING_FOR_COMPANY: 'بانتظار موافقة المنشأة',
  WAITING_FOR_MINISTRY: 'تحت المراجعة النهائية',
  PAYMENT_EXPIRED: "منتهي لعدم الدفع",
};

export const DL_REQUEST_STATUS = {
  WAITING_EMPLOYER_RESPONSE: 'بإنتظار رد صاحب العمل',
  PENDING_BACKOFFICE: 'تحت المعالجة',
  REJECTED: 'مرفوضة',
  REJECTED_FOR_FINANCIAL_INELIGIBILITY: 'غير قادر مالياً',
  VALID: 'سارٍ',
  CANCELLED: 'ملغي من قِبل صاحب العمل',
  WAITING_NIC_CORRECTION: 'بإنتظار التصحيح',
  CANCELLED_FOR_INELIGIBILITY: 'ملغي لعدم الأهلية',
  UNDER_FINAL_REVIEW: 'تحت المراجعة النهائية',
  COMPLETE: 'مُكتمل',
  WAITING_PAYMENT: 'بإنتظار الدفع',
  PAYMENT_EXPIRED: 'انتهى الدفع',
  WAITING_FINANCIAL_DOCUMENTS: 'بإنتظار رفع إثبات القدرة المالية',
  WAITING_ELIGIBILITY_CORRECTION: 'بإنتظار التصحيح من العميل',
  RESPONSE_EXPIRED: 'منتهي',
  CANCELLED_FOR_INCOMPLETE_PROCEDURES: 'ملغي لعدم اكمال الاجراءات',
  ESCALATED: 'مصعد',
  QUALITY: 'الجودة',
};

export const IND2IND_REQUEST_STATUS = {
  WAITING_LABORER_APPROVAL: 'بإنتظار موافقة العامل',
  WAITING_EMPLOYER_APPROVAL: 'بإنتظار موافقة صاحب العمل الجديد',
  WAITING_BACKOFFICE: 'تحت المعالجة',
  WAITING_PAYMENT: 'بإنتظار الدفع من صاحب العمل الجديد',
  WAITING_FINANCIAL_DOCUMENTS: 'بإنتظار ارفاق الملفات ',
  EXPIRED_LABORER_APPROVAL: 'منتهي من قبل العامل',
  EXPIRED_EMPLOYER_APPROVAL: 'منتهي من قبل صاحب العمل الجديد',
  EXPIRED_FINANCIAL_DOCUMENTS: 'منتهي لعدم ارفاق الملفات ',
  EXPIRED_PAYMENT: 'منتهي من قبل صاحب العمل الجديد',
  REJECTED_LABORER_APPROVAL: 'مرفوض من قبل العامل',
  REJECTED_EMPLOYER_APPROVAL: 'مرفوض من قبل صاحب العمل الجديد',
  REJECTED_BACKOFFICE: 'مرفوض لعدم الاهلية',
  PENDING_HRSD: 'تحت الإجراءات النهائية',
  REJECTED_HRSD: 'ملغي لعدم اكمال الإجراءات',
  PENDING_CONFIRMATION: 'بإنتظار تأكيد استلام العامل',
  COMPLETED: 'مكتمل',
  ESCALATED: 'مصعد',
  QUALITY: 'الجودة',
};

export const BACKOFFICE_STATUSES = {
  null: 'تحت المعالجة',
  1: 'تحت المعالجة',
  2: 'مسند',
  3: 'تمت الإجابة عليه',
  4: 'مصعد',
  5: 'جودة',
  6: ' مقبول',
  7: 'مرفوض',
  8: 'مرفوض وتم الحظر',
};

export const FARMER_VISA_REQUEST_SOURCE = {
  ONLINE: 'مساند اونلاين',
  GIO: 'مكاتب الأفراد الحكومية',
  OUTSIDE: 'من خارج مساند',
};

export const AUDIT_MODULES = {
  EscalationReasonCategories: 'سبب تصعيد رئيسي',
  EscalationReasons: 'سبب تصعيد فرعي',
  Users: 'المستخدم',
  UserPermissions: 'صلاحيات المستخدم',
};

export const AUDIT_ACTIONS = {
  Login: 'تسجيل دخول',
  created: 'انشاء',
  updated: 'تعديل',
  update_permissions: 'تعديل صلاحيات',
  deleted: 'حذف',
};

export const REQUEST_TYPES = {
  VISA: {
    KEY: 'VISA',
    LABEL: 'التأشيرات',
  },
  NOTICE: {
    KEY: 'NOTICE',
    LABEL: 'الإشعارات',
  },
  DL_TRANSFER: {
    KEY: 'DL_TRANSFER',
    LABEL: 'نقل الخدمات',
  },
  DLT_INDIVIDUALS: {
    KEY: 'DLT_INDIVIDUALS',
    LABEL: 'نقل الخدمات فرد لفرد',
  },
};

export const USER_TYPES = {
  BACKOFFICE_USER: 'BACKOFFICE_USER',
  AUDIT_USER: 'AUDIT_USER',
  BACKOFFICE_AUDIT_USER: 'BACKOFFICE_AUDIT_USER',
};

export const AssignAlertTypes = {
  NO_REQUESTS: 'NO_REQUESTS',
  HAVE_ASSIGNED_REQUEST: 'HAVE_ASSIGNED_REQUEST',
};
