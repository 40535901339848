import ModalStyle from '../core/modalStyle';
import { grayColor, infoColor, fontSize } from 'assets/jss/material-dashboard-react';
import { primaryColor } from '../material-dashboard-react';

const ProcessingRequestsStyle = () => ({
  tableText: {
    minHeight: 'auto',
    height: 40,
    minWidth: 114,
    width: 'auto',
    display: 'inline-block',
    // marginTop: '-1em',
    // marginBottom: '-1em',
    verticalAlign: 'middle',

    marginRight: '4px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '1.42857143',
    fontFamily: 'FrutigerRegular !important',
  },
  newAssignBtn: {
    fontSize: fontSize.l,
    width: 150,
    height: 50,
  },
  header: {
    borderBottom: 'solid #e0e0e0 1px',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontSize: fontSize.xl,
    flex: 1,
    color: infoColor,
  },
  subtitle: {
    color: grayColor,
    minHeight: 'auto',
    fontWeight: '300',
    textDecoration: 'none',
  },
  infoContainer: {
    marginRight: 50,
  },

  row: {
    marginBottom: 20,
    width: '100%',
  },

  label: {
    color: '#9f9ca2',
    fontSize: fontSize.m,
  },
  data: {
    backgroundColor: '#efefef',
    width: 220,
    height: 40,
    padding: '10px 0 10px 10px',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: '#9f9ca2',
  },
  attachmentSelect: {
    width: 230,
    height: 40,
    marginRight: 50,
    '& :focus': {
      backgroundColor: 'transparent',
    },
  },
  attachmentContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      flex: 1,
    },
  },
  mediaViewerContainer: {
    padding: 20,
  },
  mediaViewer: {
    height: '100%',
  },
  actionsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  tab: {
    fontSize: fontSize.xl,
    width: 180,
    height: 42,
    padding: 0,
    paddingLeft: 10,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#9f9ca2',
    transition: '0.6s',
    textAlign: 'start',
  },
  active: {
    color: '#293d4d',
  },
  activeLine: {
    height: 5,
    backgroundColor: '#58abc8',
    position: 'absolute',
    top: -2,
    transition: '0.4s',
  },
  divider: {
    opacity: 0.21,
    borderColor: '#979797',
  },
  escalationContainer: {
    width: '50%',
    backgroundColor: '#fff',
    margin: 'auto',
    position: 'relative',
    top: 100,
    boxShadow: '0 3px 3px 0 rgba(10, 60, 95, 0.08)',
    borderRadius: 10,
  },
  escalationSelect: {
    width: 220,
    height: 40,
    marginRight: 20,
    '& :focus': {
      backgroundColor: 'transparent',
    },
  },

  attachmentModal: {
    margin: 'auto',
    height: '400px',
    width: '400px',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  // Prev Requests Component
  prevRequestsLabels: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  prevRequestLabel: {
    marginRight: 37,
    marginTop: 10,
    width: 125,
    textAlign: 'center',
  },
  prevRequestsData: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
    backgroundColor: 'rgb(247,247,247)',
    borderRadius: 4,
    justifyContent: 'space-between',
  },
  prevRequestData: {
    marginRight: 37,
    marginTop: 10,
    width: 125,
    textAlign: 'center',
  },
  searchInput: {
    padding: '11px 14px',
    width: 200,
  },
  formControl: {
    flexDirection: 'row !important',
  },
  attachmentTitle: {
    color: primaryColor,
    fontSize: fontSize.m,
    marginTop: 50,
  },
  attachment: {
    width: 300,
    height: 40,
    paddingLeft: 10,
    marginTop: 20,
  },
  ...ModalStyle(),
});

export default ProcessingRequestsStyle;
