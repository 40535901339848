import { grayColor, infoColor } from 'assets/jss/material-dashboard-react';
import ModalStyle from '../core/modalStyle';

const EscalationReasonsStyle = () => ({
  title: {
    color: infoColor,
    fontSize: '24px',
    display: 'block',
    marginTop: 5,
    marginBottom: 5,
  },
  subtitle: {
    color: grayColor,
    minHeight: 'auto',
    fontWeight: '300',
    textDecoration: 'none',
    display: 'block',
  },
  actionsCell: {
    textAlign: 'end',
  },
  ...ModalStyle(),
});

export default EscalationReasonsStyle;
