/* eslint react/jsx-filename-extension: 0 */
import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import RTL from 'assets/jss/rtl';
import 'assets/css/main.css';
import { Provider } from 'react-redux';
import App from 'app';
import { ReactQueryDevtools } from 'react-query/devtools';
import cyan from '@material-ui/core/colors/cyan';
import orange from '@material-ui/core/colors/orange';
import { store } from './store';
import { QueryClient, QueryClientProvider } from 'react-query';

const theme = createMuiTheme({
  direction: 'rtl',
  palette: {
    primary: cyan,
    secondary: orange,
  },
});

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <RTL>
      <MuiThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MuiThemeProvider>
    </RTL>
  </Provider>,
  document.getElementById('root'),
);
