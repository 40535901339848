import React from 'react';
import PropTypes from 'prop-types';
import { request, URLs } from '../../request';
// @material-ui/core
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import dashboardStyle from 'assets/jss/views/dashboardStyle';
import ProgressButton from '../../components/core/CustomButtons/ProgressButton';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Swal from 'sweetalert2';
import ProcessingAuditRequest from './AuditRequest/ProcessingAuditRequest';

class Dashboard extends React.Component {
  state = {
    totalAudited: null,
    currentTask: null,
  };

  componentDidMount() {
    this.fetchDashboard();
  }

  fetchRequestInfo = () => {
    request.GET(URLs.backoffice.AUDIT_ACTIVE_TASK, {}).then(information => {
      if (information.data.processingTasks) {
        this.setState({
          currentTask: information.data.processingTasks,
          loading: false,
        });
      }
    });
  };

  fetchDashboard = () => {
    request.GET(URLs.backoffice.AUDIT_DASHBOARD).then(res => {
      this.setState({
        totalAudited: res.data.daily_accomplishment,
        currentTask: res.data.current_task,
      });
    });
  };

  handleAssignClicked = () => {
    this.setState({ loading: true });
    let fetchTimeout;
    request.POST(URLs.backoffice.AUDIT_TASK_ASSIGN, {}).then(res => {
      if (res.data === 0) {
        this.setState({ loading: false });
        Swal.fire({
          title: 'عذرا',
          text: 'لا يوجد طلبات للمعالجة',
          type: 'warning',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'حسنا',
        });
      } else {
        fetchTimeout = setTimeout(() => {
          this.fetchRequestInfo();
          clearTimeout(fetchTimeout);
        }, 2500);
      }
    });
  };

  handleTaskFinished = () => {
    this.fetchDashboard();
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    const { totalAudited, currentTask } = this.state;

    if (currentTask) {
      return <ProcessingAuditRequest task={currentTask} onTaskFinished={this.handleTaskFinished} />;
    }

    return (
      <React.Fragment>
        <Grid container direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <p className={`${classes.cardTitle} ${classes.cardTitleEscalation}`}>
                {totalAudited || totalAudited === 0 ? (
                  totalAudited
                ) : (
                  <CircularProgress className={classes.cardTitleEscalation} />
                )}
              </p>
              <strong className={classes.cardCategory}>معدل الانجاز اليومي</strong>
            </Card>
          </GridItem>
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <ProgressButton
            loading={loading}
            onClick={this.handleAssignClicked}
            classes={classes}
            label="إسناد طلب"
          />
        </Grid>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);
