import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import customInputStyle from 'assets/jss/core/customInputStyle';

function CustomInput({ ...props }) {
  const { classes, label, id, labelProps, InputProps, error, success, type, onChange } = props;
  const labelClasses = classNames({
    [` ${classes.labelRootError}`]: error,
    [` ${classes.labelRootSuccess}`]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });
  const marginTop = classNames({
    [classes.marginTop]: label === undefined,
  });
  return (
    <FormControl className={`${classes.formControl}`}>
      {label !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {label}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...InputProps}
        type={type}
        onChange={onChange}
      />
      {error ? <Clear className={`${classes.feedback} ${classes.labelRootError}`} /> : null}
      {success ? <Check className={`${classes.feedback} ${classes.labelRootSuccess}`} /> : null}
    </FormControl>
  );
}
CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  formControlProps: PropTypes.object,
  id: PropTypes.string,
  InputProps: PropTypes.object,
  label: PropTypes.node,
  labelProps: PropTypes.object,
  onChange: PropTypes.func,
  startAdornment: PropTypes.func,
  success: PropTypes.bool,
  type: PropTypes.string,
};
export default withStyles(customInputStyle)(CustomInput);
