import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import GridItem from '../core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CardBody from 'components/core/Card/CardBody';
import Attachment from 'components/core/attachment';
import { getToken } from '../../utils';

class PreviousRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: this.props.requests,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      filtered: nextProps.requests,
    });
  }

  handleSearchChange = e => {
    if (e.target.value === '') {
      this.setState({
        filtered: this.props.requests,
      });
      return;
    }

    let result = [];
    this.props.requests.forEach(value => {
      if (value.request_id === parseInt(e.target.value, 10)) {
        result = [value];
      }
    });
    this.setState({
      filtered: result,
    });
  };

  render() {
    const { classes, user } = this.props;
    return (
      <Grid>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className={classes.header}>
              <Search classes={classes} onChange={this.handleSearchChange} />
            </CardHeader>
            <CardBody>
              <TableHeader classes={classes} />
              {this.state.filtered.map(
                request =>
                  request.request_id !== -1 && (
                    <Request
                      classes={classes}
                      request={request}
                      user={user}
                      download={this.download}
                    />
                  ),
              )}
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    );
  }
}

const Search = ({ classes, onChange }) => (
  <div style={{ flex: 1 }}>
    <OutlinedInput
      placeholder="بحث برقم الطلب"
      inputProps={{ className: classes.searchInput }}
      onChange={onChange}
    />
  </div>
);

const TableHeader = ({ classes }) => (
  <div className={classes.prevRequestsLabels}>
    <span className={`${classes.label} ${classes.prevRequestLabel}`}>رقم الطلب</span>
    <span className={`${classes.label} ${classes.prevRequestLabel}`}>تاريخ الرفع</span>
    <span className={`${classes.label} ${classes.prevRequestLabel}`}>نوع الطلب</span>
  </div>
);

const Request = ({ classes, request, user }) => (
  <div className={classes.prevRequestsData}>
    <div style={{ display: 'flex', flex: 1 }}>
      <span className={classes.prevRequestData}>{request.request_id}</span>
      <span className={classes.prevRequestData} style={{ width: 'auto' }}>
        {request.created_at}
      </span>
      <span>{request.request_type}</span>
    </div>

    <span>
      <a
        href={`${process.env.REACT_APP_EVISA_URL}/api/download/${
          request.id
        }?token=${getToken()}&userId=${user.id_number}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Attachment
          fileId={request.id}
          userId={user.id_number}
          ext={request.mime_type}
          title={request.original_file_name}
          showDownload
        />
      </a>
    </span>
  </div>
);

Search.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
};

TableHeader.propTypes = {
  classes: PropTypes.object,
};

Request.propTypes = {
  classes: PropTypes.object,
  request: PropTypes.array,
  user: PropTypes.object,
};

PreviousRequests.propTypes = {
  classes: PropTypes.object.isRequired,
  requests: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default PreviousRequests;
