import React from 'react';
import PropTypes from 'prop-types';
import attachmentIcon from 'assets/img/attachment.png';
// import downloadIcon from 'assets/img/download.png';
import AttachmentStyle from 'assets/jss/core/attachment';
import withStyles from '@material-ui/core/styles/withStyles';
import Download from './downlaod';
import { getUploadUrl } from '../../request';
// import { getUploadUrl } from '../request';

const Attachment = ({
  classes,
  className,
  title,
  onClick,
  ext,
  style,
  selected,
  fileId,
  userId,
  showDownload = false,
}) => (
  // eslint-disable-next-line
  <div
    onClick={onClick}
    className={className}
    style={{
      border: 'none',
      backgroundColor: 'transparent',
      ...style,
    }}
  >
    <div
      className={`${classes.attachment} ${
        selected ? classes.active : classes.inactive
      } ${className}`}
    >
      <img src={attachmentIcon} alt="file" className={classes.fileIcon} />

      <div className={`${classes.fileName} filename`}>
        {title || `${'اسم الملف.'}${ext ? ext.substring(ext.indexOf('/') + 1) : 'file'}`}
      </div>

      {showDownload && <Download target={getUploadUrl(fileId, userId)} fileName={title} />}
    </div>
  </div>
);

Attachment.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.object,
  ext: PropTypes.string,
  fileId: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  showDownload: PropTypes.bool,
  style: PropTypes.string,
  title: PropTypes.string,
  userId: PropTypes.number,
};

export default withStyles(AttachmentStyle)(Attachment);
