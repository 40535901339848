import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import ButtonAlert from '@material-ui/core/Button';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import LoginStyle from 'assets/jss/views/ListUsersStyle';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import compose from 'recompose/compose';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import CardIcon from 'components/core/Card/CardIcon';
import User from '@material-ui/icons/Person';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { request, URLs } from '../../request';
import Progress from '../../../node_modules/@material-ui/core/CircularProgress/CircularProgress';
import Switch from 'react-switch';
import { PERMISSIONS } from '../../constants';
import { getToken } from '../../utils';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ListUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      delPopUp: false,
      token: getToken(),
      url: process.env.REACT_APP_API_URL,
      delete_id: '',
    };

    this.handleEditUserButton = id => {
      const pageUrl = `${window.location.href}/edit/${id}`;
      window.history.pushState('', '', pageUrl);
    };
  }

  componentDidMount() {
    request.GET(URLs.backoffice.USERS).then(respond => {
      this.setState({
        data: [respond.data.users],
      });
    });
  }

  handleDeletePopup = event => {
    this.setState({ delPopUp: true, delete_id: event.currentTarget.value });
  };

  handleDelPopupClose = () => {
    this.setState({ delPopUp: false });
  };

  handleSnakClose = () => {
    this.setState({ error: false });
  };

  updateUser = updatedUser => {
    const arr = this.state.data.map(subArr =>
      subArr.map(user => {
        if (user.id === updatedUser.id) {
          return updatedUser;
        }
        return user;
      }),
    );
    this.setState({ data: [...arr] });
  };

  showActivation = () => {
    return (
      this.props.auth.isRoot ||
      this.props.auth.userPermissions.includes(PERMISSIONS.ACTIVATE_DEACTIVATE_USERS.KEY)
    );
  };

  handleToggleActivation = (id, value) =>
    request.POST(URLs.backoffice.USER_ACTIVATION, { user_id: id, is_active: value }).then(res => {
      this.updateUser(res.data);
    });

  handleDelUserButton() {
    const { url, token } = this.state;
    axios
      .delete(`${url}/v1/users/${this.state.delete_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(respond => {
        this.setState({
          data: [respond.data],
          delPopUp: false,
        });
      })
      .catch(err => {
        this.setState({
          error: true,
          error_message: err.response.data.message,
          delPopUp: false,
        });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.error}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={this.state.error_message}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnakClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="info"
                icon
                title="المستخدمين"
                subtitle="تجد هنا قائمة من المستخدمين المضافين"
              >
                <CardIcon color="info" isTableIcon>
                  <User />
                </CardIcon>

                <div style={{ display: 'inline-block', float: 'left' }}>
                  <Link to="/users/add">
                    <Button color="info" style={{ marginTop: 20, fontWeight: 600 }} link>
                      إضافة مستخدم جديد
                    </Button>
                  </Link>
                </div>
              </CardHeader>
              <CardBody>
                <Grid container justify="center">
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>الأسم</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>البريد الإلكتروني</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>رقم الهاتف</TableCell>
                        {this.showActivation() && (
                          <TableCell style={{ fontWeight: 'bold' }}>تفعيل</TableCell>
                        )}
                        <TableCell style={{ fontWeight: 'bold' }} />
                      </TableRow>
                    </TableHead>
                    {this.state.data.length ? (
                      <TableBody>
                        {this.state.data.map(el => (
                          <React.Fragment key={el}>
                            {Object.entries(el).map(([key, value]) => (
                              <TableRow key={key}>
                                <TableCell>{value.name}</TableCell>
                                <TableCell>{value.email}</TableCell>
                                <TableCell>{value.mobile}</TableCell>
                                {this.showActivation() && (
                                  <TableCell>
                                    <span dir="ltr">
                                      <Switch
                                        onChange={() =>
                                          this.handleToggleActivation(value.id, !value.is_active)
                                        }
                                        checked={value.is_active}
                                        onColor="#28a745"
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        height={20}
                                        width={40}
                                      />
                                    </span>
                                  </TableCell>
                                )}
                                <TableCell>
                                  <Link
                                    to={{
                                      pathname: `/users/edit/${value.id}`,
                                      value: { id: value.id },
                                    }}
                                  >
                                    <Button
                                      color="info"
                                      className={classes.button}
                                      onClick={e => this.handleEditUserButton(value.id, e)}
                                      id={value.id_number}
                                      link
                                    >
                                      <Edit color="secondary" />
                                    </Button>
                                  </Link>
                                  <span />
                                  {value.id !== 1 ? (
                                    <React.Fragment>
                                      <Button
                                        color="danger"
                                        className={classes.button}
                                        id={value.id}
                                        key={value.id}
                                        value={value.id}
                                        onClick={this.handleDeletePopup}
                                        link
                                      >
                                        <Delete color="error" />
                                      </Button>

                                      <Dialog
                                        open={this.state.delPopUp}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={this.handleDelPopupClose}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                      >
                                        <DialogTitle id="alert-dialog-slide-title">
                                          {'هل أنت متأكد ؟'}
                                        </DialogTitle>
                                        <DialogActions>
                                          <ButtonAlert
                                            onClick={() => this.handleDelUserButton()}
                                            variant="outlined"
                                            color="primary"
                                          >
                                            نعم
                                          </ButtonAlert>
                                          <ButtonAlert
                                            onClick={this.handleDelPopupClose}
                                            variant="outlined"
                                            color="secondary"
                                          >
                                            لا
                                          </ButtonAlert>
                                        </DialogActions>
                                      </Dialog>
                                    </React.Fragment>
                                  ) : null}

                                  <Link
                                    to={{
                                      pathname: `/users/${value.id}`,
                                      value: { id: value.id },
                                    }}
                                  >
                                    <Button link>
                                      <VisibilityIcon />
                                    </Button>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    ) : null}
                  </Table>
                  {!this.state.data.length ? (
                    <Progress style={{ alignSelf: 'center', marginTop: 20 }} />
                  ) : null}
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </div>
    );
  }
}

ListUsers.propTypes = {
  auth: PropTypes.shape({
    isRoot: PropTypes.bool.isRequired,
    userPermissions: PropTypes.array.isRequired,
  }),
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};

const mapStateToProps = state => {
  return {
    auth: {
      userPermissions: state.auth.userPermissions,
      isRoot: state.auth.isRoot,
    },
  };
};

export default compose(
  withStyles(LoginStyle),
  connect(mapStateToProps),
)(ListUsers);
