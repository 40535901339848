/**
 * history.data: contains attachments of all requests, two requests may have the same attachment
 * attachmentsIds: contains a list of attachments ids, which were attached to the current request
 * in this method, we iterate over the list of history.data, if the attachment id is in the list
 * of attachmentsIds then we check if that id has been seen before (not duplicated) if not
 * then add it to the list of attachments
 *
 * TODO: Maybe have an endpoint to get the current request attachments directly
 *  instead of doing this
 */
export const getRequestAttachmentsFromHistoryWithoutDuplication = (history, attachmentsIds) => {
  const attachmentSeen = new Set();
  const attachments = [];
  history.data.forEach(attachment => {
    if (
      attachmentsIds.find(id => parseInt(id, 10) === parseInt(attachment.id, 10)) > -1 &&
      !attachmentSeen.has(attachment.id)
    ) {
      attachments.push(attachment);
      attachmentSeen.add(attachment.id);
    }
  });

  return attachments;
};

export const prepareRequestTypeLabel = TYPE => {
  if (TYPE === 'NOTICE') {
    return 'إصدار إشعار';
  }
  if (TYPE === 'VISA') {
    return 'إصدار تأشيرة';
  }
  if (TYPE === 'DL_TRANSFER') {
    return 'نقل خدمات';
  }
  return '';
};
