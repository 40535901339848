/* eslint-disable import/prefer-default-export */

/**
 *
 * Truncate a long string for easy reading
 *
 * @param {String} str The string needed to be truncated
 * @param {Number} n The charachter limit
 * @param {Boolean} useWordBoundary specify if you want to cut at the last word
 */
export const truncate = (str, n, useWordBoundary) => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1);
  return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString}...`;
};
