import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MediaViewerStyle from 'assets/jss/core/mediaViewer';
import withStyles from '@material-ui/core/styles/withStyles';
import { getUploadUrl } from '../../request';
import ImageZoom from 'react-medium-image-zoom';

const MAX_SCALE = 4.5;
const STEP = 0.5;

// eslint-disable-next-line no-undef
const openImages = src => {
  window.open(src, '_blank');
};
const MediaViewer = ({ classes, className, fileId, userId, mimeType, hideTools }) => {
  const [scale, setScale] = useState(1);

  const src = getUploadUrl(fileId, userId);
  const ext = mimeType && mimeType.split('/')[1];
  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.mediaContainer}>
        {ext !== 'pdf' ? (
          <React.Fragment>
            <ImageZoom
              image={{
                src,
                alt: 'Financial Aid Evidence',
                className: 'img',
                style: { transform: `scale(${scale}) ` },
              }}
              zoomImage={{
                src,
                alt: 'Financial Aid Evidence',
              }}
            />
          </React.Fragment>
        ) : (
          <iframe src={src} title="financial aid evidence" />
        )}
      </div>
      {ext !== 'pdf' && !hideTools ? (
        <div className={classes.tools}>
          <button type="button" onClick={() => setScale(Math.min(scale + STEP, MAX_SCALE))}>
            <i className="material-icons">add</i>
          </button>
          <button type="button" onClick={() => setScale(1)}>
            <i className="material-icons">refresh</i>
          </button>
          <button type="button" onClick={() => setScale(Math.max(scale - STEP, 1))}>
            <i className="material-icons">remove</i>
          </button>
          <button type="button" onClick={() => openImages(src)}>
            <i className="material-icons">assignment</i>
          </button>
        </div>
      ) : null}
    </div>
  );
};

MediaViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.object,
  fileId: PropTypes.string,
  hideTools: PropTypes.bool,
  mimeType: PropTypes.string,
  userId: PropTypes.number,
};

export default withStyles(MediaViewerStyle)(MediaViewer);
