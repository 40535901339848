const createLabel = (min, max) => {
  if (max === Number.MAX_SAFE_INTEGER) {
    return `من ${min} فما فوق`;
  }

  return `من ${min} إلى ${max - 1}`;
};

const createRange = (min, max) => {
  return {
    label: createLabel(min, max),
    value: min,
  };
};

const modifyPivots = pivots => {
  return [...new Set(pivots)];
};

export const createRanges = pivots => {
  const modifiedPivots = modifyPivots(pivots);
  const result = [];
  modifiedPivots.sort((a, b) => parseInt(a, 10) > parseInt(b, 10));
  modifiedPivots.forEach((pivot, index) => {
    if (index !== modifiedPivots.length - 1) {
      const range = createRange(pivot, modifiedPivots[index + 1]);
      result.push(range);
    } else {
      const range = createRange(pivot, Number.MAX_SAFE_INTEGER);
      result.push(range);
    }
  });

  return result;
};

export const rangeType = (userDetails, isDisable = false) => {
  if (isDisable) {
    return 'disablity';
  }

  const SaudiNationalities = [
    'العربية السعودية',
    'القبائل النازحة',
    'قبائل نازحة / الحليفة',
    'قبائل نازحة/الحليفه',
    'مقيم/نازح',
    'الكويت',
    'الامارات',
    'الامارات  العربية',
    'قطر',
    'عمان',
    'البحرين',
  ];
  if (
    userDetails.isSaudi ||
    userDetails.is_saudi ||
    SaudiNationalities.indexOf(userDetails.nationality.toString()) > -1
  ) {
    return 'saudi';
  }

  return 'foreigners';
};

/**
 * Matches "," or an empty string
 * @param {*} pivotString
 */
export const splitRangeString = pivotString => {
  // trim white spaces
  let cleanString = pivotString.trim();

  // take out ',' at the end or at the begining
  cleanString = cleanString.replace(/,$|^,/, '');

  // take out all the whitespaces
  cleanString = cleanString.replace(/ /g, '');

  // replace ',[non-number]' with ','
  cleanString = cleanString.replace(/,(\D)*/, ',');
  return cleanString.split(/,/);
};
