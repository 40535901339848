const initState = {
  current: localStorage.getItem('system') || 'backoffice',
};

const reducer = (state = initState, { type }) => {
  switch (type) {
    case 'AUDIT_SYSTEM': {
      localStorage.setItem('system', 'audit');
      return { current: 'audit' };
    }
    case 'BACKOFFICE_SYSTEM': {
      localStorage.setItem('system', 'backoffice');
      return { current: 'backoffice' };
    }
    case 'SWITCH_SYSTEM': {
      const isBackoffice = state.current === 'backoffice';
      const switchTo = isBackoffice ? 'audit' : 'backoffice';
      localStorage.setItem('system', switchTo);
      return { current: switchTo };
    }
    default:
      return state;
  }
};
export default reducer;
