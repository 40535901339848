import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import ProcessingRequests from './ProcessingEscalatedRequest';

class EscalatedRequestsGuard extends Component {
  render() {
    this.data = this.props.location.data;
    if (!this.data) {
      return <Redirect to="/escalationRequests" />;
    }

    return <ProcessingRequests {...this.props} />;
  }
}

EscalatedRequestsGuard.propTypes = {
  location: PropTypes.object,
};

export default withRouter(EscalatedRequestsGuard);
