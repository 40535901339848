import { grayColor, infoColor, fontSize } from 'assets/jss/material-dashboard-react';
import ModalStyle from '../core/modalStyle';

const EscalationReasonsStyle = () => ({
  title: {
    color: infoColor,
    fontSize: fontSize.xl,
    margin: '10px 15px 0 15px',
  },
  subtitle: {
    color: grayColor,
    minHeight: 'auto',
    fontWeight: '300',
    textDecoration: 'none',
    margin: '0 15px',
  },
  actionsCell: {
    textAlign: 'end',
  },
  ...ModalStyle(),
});

export default EscalationReasonsStyle;
