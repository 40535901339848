import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Email from '@material-ui/icons/Email';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardBody from 'components/core/Card/CardBody';
import CardFooter from 'components/core/Card/CardFooter';
import LoginStyle from 'assets/jss/views/LoginStyle';
import PropTypes from 'prop-types';
import TextField from 'components/core/CustomInput/CustomInput';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { request } from '../../request';
import compose from 'recompose/compose';

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      openSent: false,
      openEmailError: false,
    };

    this.handleEmailForgetChange = this.handleEmailForgetChange.bind(this);
    this.handleSearchTermSubmit = this.handleSearchTermSubmit.bind(this);
    this.handleClose = () => {
      this.setState({ openSent: false });
      this.setState({ openEmailError: false });
    };
  }

  handleEmailForgetChange(evt) {
    this.setState({ email: evt.target.value });
  }

  handleSearchTermSubmit() {
    const email = this.state.email;
    const url = process.env.REACT_APP_API_URL;

    request
      .POST(`${url}/v1/password/email`, {
        email,
      })
      .then(() => {
        this.setState({ openSent: true });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardBody>
              <Grid container>
                <GridItem xs={12} sm={12} md={12}>
                  <p
                    style={{
                      textAlign: 'center',
                      fontWeight: 500,
                      fontSize: 32,
                      margin: '20px 0',
                    }}
                  >
                    استعادة كلمة المرور
                  </p>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    label="البريد الإلكتروني"
                    id="emailForget"
                    type="email"
                    fullWidth
                    margin="normal"
                    onChange={this.handleEmailForgetChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </Grid>
            </CardBody>
            <CardFooter plain>
              <Button color="primary" onClick={this.handleSearchTermSubmit}>
                إرسال
              </Button>
              <Button color="gray" onClick={this.props.onCancel}>
                إلغاء
              </Button>
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={this.state.openSent}
                autoHideDuration={6000}
                onClose={this.handleClose}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">تم إرسال البريد الإلكتروني.</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleClose}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={this.state.openEmailError}
                autoHideDuration={6000}
                onClose={this.handleClose}
                ContentProps={{
                  'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">البريد المدخل غير صحيح.</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleClose}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </CardFooter>
          </Card>
        </GridItem>
      </Grid>
    );
  }
}

ForgetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func,
};
export default compose(withStyles(LoginStyle))(ForgetPassword);
