import { grayColor, infoColor } from 'assets/jss/material-dashboard-react';

const ListUsers = () => ({
  title: {
    color: infoColor,
    fontSize: '24px',
    margin: '0 15px',
  },
  subtitle: {
    color: grayColor,
    minHeight: 'auto',
    fontWeight: '300',
    textDecoration: 'none',
    margin: '0 15px',
  },
});
export default ListUsers;
