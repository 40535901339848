export const VALIDATE_AUTH = data => {
  return {
    type: 'VALIDATE_AUTH',
    data,
  };
};

export const LOGIN = data => {
  localStorage.setItem('auth', JSON.stringify(data));
  return {
    type: 'LOGIN',
    data,
  };
};

export const REFRESH_TOKEN = data => {
  localStorage.setItem('Token', data);
  return {
    type: 'REFRESH_TOKEN',
    data,
  };
};
export const LOGOUT = () => {
  localStorage.clear();
  return { type: 'LOGOUT' };
};
