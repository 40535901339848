import React from 'react';
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
// core components
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid/Grid';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/core/CustomButtons/Button';
import EscalatedRequestsStyle from 'assets/jss/views/EscalatedRequestsStyle';
import MenuItem from '@material-ui/core/MenuItem';
import CardIcon from 'components/core/Card/CardIcon';
import Icon from '@material-ui/icons/Assignment';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { request, URLs } from '../../request';
import { ROWS_PER_PAGE, REQUEST_TYPES } from '../../constants';
import { getCurrentUserData } from '../../actions';
import { bindActionCreators } from 'redux';

class ListEscalatedRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      name: '',
      users: [],
      currentPage: 1,
      reasons: [],
    };
    this.handleName = this.handleName.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentUserData();
    request
      .GET(URLs.backoffice.AGENTS, {
        include_system: true,
      })
      .then(respond => {
        this.setState({
          users: respond.data.users,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });

    request
      .GET(URLs.backoffice.ESCALATED_REQUESTS, { page: this.state.currentPage })
      .then(({ data }) => {
        this.setState({
          loading: false,
          data: data.data,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });

    request
      .GET(URLs.backoffice.ESCALATION_REASONS_CATEGORIES, {
        include_system: true,
      })
      .then(res => {
        this.setState({ reasons: res.data });
      });
  }

  handleName = event => {
    this.setState({ name: event.target.value });
  };

  handleType = event => {
    this.setState({ type: event.target.value });
  };

  handleReason = event => {
    this.setState({ reason: event.target.value });
  };

  handleFromDateChange = e =>
    this.setState({ from: e.target.value }, () => this.handleSearchSubmit());

  handleToDateChange = e => this.setState({ to: e.target.value }, () => this.handleSearchSubmit());

  handleSearchSubmit = () => {
    request
      .GET(URLs.backoffice.ESCALATED_REQUESTS, {
        page: 1,
        name: this.state.name,
        from: this.state.from,
        to: this.state.to,
        type: this.state.type,
        escalation_reason: this.state.reason,
      })
      .then(({ data }) => {
        this.setState({
          loading: false,
          data: data.data,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleOnChangePage = (event, page) => {
    this.setState({ currentPage: page });
  };

  handleOnAssign = escalatedRequest => () => {
    request
      .POST(URLs.backoffice.ASSIGN_ESCALATION, { id: escalatedRequest.id })
      .then(response => {
        const updatedEscalationRequest = response.data.escalatedRequest;
        // Immutably change 'assignation' inside one of the requests inside the 'data' state
        // for more info lookup https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
        this.setState(prevState => ({
          data: prevState.data.map(escalationRequest => {
            if (escalationRequest.id !== updatedEscalationRequest.id) {
              return escalationRequest;
            }

            return { ...escalationRequest, assignation: updatedEscalationRequest.assignation };
          }),
        }));
      })
      .catch(() => {});
  };

  handleOnUnassign = escalatedRequest => () => {
    request
      .POST(URLs.backoffice.UNASSIGN_ESCALATION, { id: escalatedRequest.id })
      .then(response => {
        const updatedEscalationRequest = response.data.escalatedRequest;
        // Immutably change 'assignation' inside one of the requests inside the 'data' state
        // for more info lookup https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
        this.setState(prevState => ({
          data: prevState.data.map(escalationRequest => {
            if (escalationRequest.id !== updatedEscalationRequest.id) {
              return escalationRequest;
            }

            return { ...escalationRequest, assignation: updatedEscalationRequest.assignation };
          }),
        }));
      })
      .catch(() => {});
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Grid container>
              <GridItem xs={2} sm={2} md={2}>
                <h5>اسم الموظف</h5>
                <Select
                  style={{ display: 'flex', flex: 1 }}
                  onChange={this.handleName}
                  value={this.state.name}
                >
                  <MenuItem value="">None</MenuItem>
                  {this.state.users.map($val => (
                    <MenuItem value={$val.username}>{$val.name}</MenuItem>
                  ))}
                </Select>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <h5>نوع الطلب</h5>
                <Select
                  style={{ display: 'flex', flex: 1 }}
                  onChange={this.handleType}
                  value={this.state.type}
                >
                  <MenuItem value="">الجميع</MenuItem>
                  {Object.keys(REQUEST_TYPES).map(type => (
                    <MenuItem key={type} value={REQUEST_TYPES[type].KEY}>
                      {REQUEST_TYPES[type].LABEL}
                    </MenuItem>
                  ))}
                </Select>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <h5>سبب التصعيد</h5>
                <Select
                  style={{ display: 'flex', flex: 1 }}
                  onChange={this.handleReason}
                  value={this.state.reason}
                >
                  <MenuItem value="">الجميع</MenuItem>
                  {this.state.reasons.map($val => (
                    <MenuItem value={$val.id}>{$val.title}</MenuItem>
                  ))}
                </Select>
              </GridItem>

              <GridItem xs={4} sm={4} md={4} style={{ marginTop: '50px' }}>
                <Button color="primary" onClick={this.handleSearchSubmit} justIcon>
                  <SearchIcon />
                </Button>
              </GridItem>
            </Grid>
            <Card>
              <CardHeader icon title="طلبات التصعيد" subtitle="تجد هنا قائمة من طلبات التصعيد">
                <CardIcon color="info" isTableIcon>
                  <Icon />
                </CardIcon>
                <div style={{ float: 'left', display: 'flex', marginLeft: 25 }}>
                  <div style={{ marginLeft: 20 }}>
                    <h6 className={classes.filterLabel}>تاريخ البداية</h6>
                    <TextField
                      style={{ margin: 'inline-block', fontSize: 12 }}
                      id="date"
                      type="date"
                      onChange={this.handleFromDateChange}
                      inputProps={{ style: { fontSize: 12, color: 'grey' } }}
                      value={this.state.from}
                    />
                  </div>
                  <div>
                    <h6 className={classes.filterLabel}>تاريخ النهاية</h6>
                    <TextField
                      style={{ display: 'inline-block' }}
                      id="date"
                      type="date"
                      onChange={this.handleToDateChange}
                      inputProps={{ style: { fontSize: 12, color: 'grey' } }}
                      value={this.state.to}
                    />
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <Grid container justify="center">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {this.state.loading === true ? (
                    <CircularProgress className={classes.progress} />
                  ) : !this.state.data.length ? (
                    <h3>لا يوجد طلبات</h3>
                  ) : (
                    <React.Fragment>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>رقم الطلب</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>نوع الطلب</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>سبب التصعيد</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>وقت التصعيد</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>الموظف المصعد</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>تفاصيل الطلب</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }} />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.data.map(el => (
                            <TableRow key={el.id}>
                              <TableCell>{el.processing_request.reference_id}</TableCell>
                              <TableCell>
                                {REQUEST_TYPES[el.processing_request.type].LABEL}
                              </TableCell>
                              <TableCell>
                                {el.escalation_reason ? el.escalation_reason.title : null}
                              </TableCell>
                              <TableCell style={{ direction: 'ltr' }}>
                                {new Intl.DateTimeFormat('en-GB', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: '2-digit',
                                  hour12: true,
                                  hour: 'numeric',
                                  minute: 'numeric',
                                }).format(new Date(el.created_at))}
                              </TableCell>
                              <TableCell>{el.escalator.name}</TableCell>
                              <TableCell>
                                <Link
                                  to={{
                                    pathname: `/processingEscalated/${el.id}`,
                                    data: { id: el.id },
                                  }}
                                >
                                  <Button color="primary" className={classes.button} link>
                                    تفاصيل الطلب
                                  </Button>
                                </Link>
                              </TableCell>
                              <TableCell>
                                <Assignation
                                  classes={classes}
                                  element={el}
                                  onAssign={this.handleOnAssign(el)}
                                  onUnassign={this.handleOnUnassign(el)}
                                  currentUser={this.props.user}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              count={this.state.data.length}
                              rowsPerPage={ROWS_PER_PAGE}
                              page={this.state.currentPage}
                              onChangePage={this.handleOnChangePage}
                              rowsPerPageOptions={[]}
                              labelDisplayedRows={({ from, to, count }) =>
                                ` ${from}-${to} من أصل ${count}`
                              }
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </React.Fragment>
                  )}
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </div>
    );
  }
}

const Assignation = ({ classes, element, onAssign, onUnassign, currentUser }) => {
  if (!element.assignation) {
    return (
      <Button color="primary" onClick={onAssign} className={classes.button} link>
        بدء المعالجة
      </Button>
    );
  }

  if (currentUser !== null && currentUser.id === element.assignation.assigned_to.id) {
    return (
      <Button color="danger" onClick={onUnassign} classes={classes} link>
        توقف عن المعالجة
      </Button>
    );
  }

  return (
    <Button color="gray" onClick={onUnassign} classes={classes} disabled link>
      تحت معالجة شخص آخر
    </Button>
  );
};

Assignation.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  element: PropTypes.object.isRequired,
  onAssign: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({ getCurrentUserData }, dispatch);

ListEscalatedRequests.propTypes = {
  classes: PropTypes.object.isRequired,
  getCurrentUserData: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
  user: PropTypes.object.isRequired,
};
export default compose(
  withStyles(EscalatedRequestsStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ListEscalatedRequests);
