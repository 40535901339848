const evisa = {
  HISTORY: '{EVISA_BASE}/api/history/{user_id}/{type}',
  REJECTION_REASONS: '{EVISA_BASE}/api/reject_reasons',
  REQUEST_REJECTON_REASONS: '{EVISA_BASE}/api/request/rejection_reasons/{type}/{id}',
  GIOS: '{EVISA_BASE}/api/gio_offices',
  FARMER_VISA_REQUESTS:
    /* eslint-disable-next-line max-len */
    '{EVISA_BASE}/api/farmer_visa_requests?page={page}&request_id={request_id}&status={status}&created_at={created_at}&created_from={created_from}&created_to={created_to}&source={source}&id_number={id_number}',
};

export default evisa;
