import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import UsersStyle from 'assets/jss/views/UserDetailsStyle';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import { request, URLs } from '../../request';
import Grid from '@material-ui/core/Grid';
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import CardBody from 'components/core/Card/CardBody';
import CardHeader from 'components/core/Card/CardHeader';
import { AUDIT_ACTIONS, PERMISSIONS, REQUEST_TYPES } from '../../constants';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import SubmitButton from '../../components/core/CustomButtons/SubmitButton';
import Swal from 'sweetalert2';

class UserDetails extends React.Component {
  state = {
    information: {
      email: null,
      name: null,
      username: null,
      mobile: null,
      idNumber: null,
    },
    permissions: [],
    recordHistory: [],
    tasks: [],
    loading: true,
  };

  componentDidMount() {
    const id = this.props.match.params.id;

    const promises = [];

    promises.push(request.GET(URLs.backoffice.USERS, { user_id: id }));
    promises.push(request.GET(URLs.backoffice.GET_USER_TASK, { user_id: id }));
    promises.push(request.GET(URLs.backoffice.AUDIT_HISTORY, { module: 'Users', id }));

    Promise.all(promises)
      .then(response => {
        this.setState({
          information: {
            email: response[0].data.email,
            name: response[0].data.name,
            username: response[0].data.username,
            mobile: response[0].data.mobile,
            idNumber: response[0].data.id_number,
          },
          permissions: response[0].data.granted,
          tasks: response[1].data.task,
          recordHistory: response[2].data,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleOnCancel = taskId => () => {
    const id = this.props.match.params.id;

    request
      .POST(URLs.backoffice.CANCEL_USER_TASK, {
        user_id: id,
        task_id: taskId,
      })
      .then(() => {
        Swal.fire({
          title: 'تم إلغاء المهمة',
          text: 'تم إلغاء مهمة المستخدم',
          type: 'success',
          confirmButtonText: 'تحديث الصفحة',
        }).then(() => {
          window.location.reload();
        });
      });
  };

  render() {
    if (this.state.loading) {
      return <CircularProgress className={this.props.classes.progress} />;
    }

    return (
      <Grid>
        <GridItem xs={12} sm={12} md={12}>
          <UserInformationCard {...this.state.information} classes={this.props.classes} />
          <PermissionsCard permissions={this.state.permissions} classes={this.props.classes} />
          <TasksCard
            tasks={this.state.tasks}
            classes={this.props.classes}
            onCancel={this.handleOnCancel}
          />
          <AuditsCard audits={this.state.recordHistory} classes={this.props.classes} />
        </GridItem>
      </Grid>
    );
  }
}

const TasksCard = ({ tasks, classes, onCancel }) => {
  return (
    <Card>
      <CardHeader className={classes.header}>
        <p className={classes.title}>المهام المسندة</p>
      </CardHeader>
      <CardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
        {tasks.length === 0 && <h3>لم يقم المستخدم بإسناد أي طلب</h3>}
        {tasks.length !== 0 && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>رقم الطلب</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>نوع الطلب</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>وقت الإسناد</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map(task => (
                <TableRow key={task.id}>
                  <TableCell>{task.request.reference_id}</TableCell>
                  <TableCell>{REQUEST_TYPES[task.request.type].LABEL}</TableCell>
                  <TableCell>{task.created_at}</TableCell>
                  <TableCell>
                    <SubmitButton
                      color="danger"
                      className={classes.button}
                      onClick={onCancel(task.id)}
                    >
                      إلغاء الطلب
                    </SubmitButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardBody>
    </Card>
  );
};

const AuditsCard = ({ audits, classes }) => (
  <Card>
    <CardHeader className={classes.header}>
      <p className={classes.title}>الأحداث المتعلقة</p>
    </CardHeader>
    <CardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
      {audits.length === 0 ? (
        <h3>لا يوجد</h3>
      ) : (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                الحدث
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                تاريخ الحدث
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                بواسطة
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                تفاصيل
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {audits.map(his => (
              <TableRow>
                <TableCell className={classes.cell}>{AUDIT_ACTIONS[his.event]}</TableCell>
                <TableCell className={classes.lgCell}>{his.datetime}</TableCell>
                <TableCell className={classes.cell}>{his.user}</TableCell>
                <TableCell
                  className={classes.lgCell}
                  dangerouslySetInnerHTML={{ __html: his.details }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </CardBody>
  </Card>
);

const PermissionsCard = ({ permissions, classes }) => (
  <Card>
    <CardHeader className={classes.header}>
      <p className={classes.title}>الصلاحيات</p>
    </CardHeader>
    <CardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
      {permissions.map(permission => (
        <PermissionBlock key={permission} permission={permission} classes={classes} />
      ))}
    </CardBody>
  </Card>
);

const PermissionBlock = ({ permission, classes }) => (
  <span className={classes.permissionBlock}>{PERMISSIONS[permission].LABEL}</span>
);

const UserInformationCard = ({ email, mobile, idNumber, username, classes }) => (
  <Card>
    <CardHeader className={classes.header}>
      <p className={classes.title}>معلومات المستخدم</p>
    </CardHeader>
    <CardBody style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Info label="اسم المستخدم" value={username} classes={classes} />
      <Info label="رقم الهوية" value={idNumber} classes={classes} />
      <Info label="رقم الهاتف" value={mobile} classes={classes} />
      <Info label="البريد الإلكتروني" value={email} classes={classes} />
    </CardBody>
  </Card>
);

const Info = ({ label, value, classes }) => (
  <div className={classes.infoContainer}>
    <p className={classes.label}>{label}</p>
    <p className={classes.data}>{value}</p>
  </div>
);

UserDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.object.isRequired,
};

Info.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

UserInformationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  idNumber: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

PermissionBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  permission: PropTypes.string.isRequired,
};

PermissionsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
};

TasksCard.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
};

AuditsCard.propTypes = {
  audits: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    access_token: state,
  };
};

export default compose(
  withStyles(UsersStyle),
  connect(mapStateToProps),
  withRouter,
)(UserDetails);
