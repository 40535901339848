import React from 'react';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import { FormControl, IconButton, InputLabel } from '@material-ui/core';
// import LastPageIcon from '@material-ui/icons/LastPage';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

const PaginationActions = ({ classes, count, page, rowsPerPage, theme, onChangePage }) => {
  const lastPage = Math.ceil(count / rowsPerPage) - 1;

  // const handleFirstPageClick = event => {
  //     onChangePage(event, 0)
  // }

  // const handleLastPageClick = event => {
  //     onChangePage(event, Math.max(0, lastPage))
  // }

  const handleNextPageClick = event => {
    onChangePage(event, page + 1);
  };

  const handleBackPageClick = event => {
    onChangePage(event, page - 1);
  };

  return (
    <div className={classes.root}>
      {/* <IconButton
            onClick={handleFirstPageClick}
            disabled={page === 0}
            aria-label="First Page"
          >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton> */}
      <IconButton onClick={handleBackPageClick} disabled={page === 0} aria-label="Previous Page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <PageButtons page={page} lastPage={lastPage} onChangePage={onChangePage} />
      <IconButton onClick={handleNextPageClick} disabled={page >= lastPage} aria-label="Next Page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      {/* <IconButton
            onClick={handleLastPageClick}
            disabled={page >= lastPage}
            aria-label="Last Page"
          >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton> */}
    </div>
  );
};

const PageButtons = ({ page, lastPage, onChangePage }) => {
  const pages = [];

  for (let index = 0; index <= lastPage; index += 1) {
    pages.push(index);
  }

  return (
    <FormControl>
      <InputLabel htmlFor="page-native-simple">الصفحة</InputLabel>
      <Select
        value={page}
        onChange={event => onChangePage(event, event.target.value)}
        inputProps={{
          name: 'page',
          id: 'page-native-simple',
        }}
      >
        {pages.map(p => (
          <MenuItem value={p}>{p + 1}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(actionsStyles, { withTheme: true })(PaginationActions);
