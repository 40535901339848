import InquiriesStyle from './InquiriesStyle';
import AuditRequestStyle from './AuditRequestStyle';

const AuditReportsStyle = () => ({
  ...AuditRequestStyle(),
  ...InquiriesStyle(),
  status_approved: {
    backgroundColor: '#53A653',
    paddingTop: '6px;',
  },
  status_rejected: {
    backgroundColor: 'rgb(233,110,110)',
    paddingTop: '6px;',
  },

  label: {
    display: 'inline-block',
    textAlign: 'center',
    color: '#9B9B9B',
    width: '16%',
  },
});

export default AuditReportsStyle;
