import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import MediaViewer from 'components/core/mediaViewer';
import Attachment from 'components/core/attachment';

class AnswerCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAttachment: this.props.attachments[0]
        ? this.props.attachments[0]
        : { isUndefined: true },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.selectedAttachment.isUndefined) {
      this.setState({
        selectedAttachment: nextProps.attachments[0]
          ? nextProps.attachments[0]
          : { isUndefined: true },
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.attachmentContainer}>
        <div>
          <CardHeader className={classes.header}>
            <p className={classes.title}>تفاصيل المرفقات</p>
          </CardHeader>
          <CardBody>
            <div>
              <p className={classes.label}>اختر التعريف و حدود الراتب بناء على المرفق </p>
            </div>
            <div>
              <div style={{ display: 'flex' }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    value={this.props.chosenType}
                    onChange={this.props.onTypeChange}
                    input={<OutlinedInput name="type" />}
                    className={classes.attachmentSelect}
                  >
                    <MenuItem value={0}>
                      <span style={{ color: '#9f9ca2' }}>نوع المرفق</span>
                    </MenuItem>
                    {this.props.attachmentTypes.map((type, index) => (
                      <MenuItem value={index + 1}>{type}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    value={this.props.chosenOption}
                    onChange={this.props.onValueChange}
                    input={<OutlinedInput name="type" />}
                    className={classes.attachmentSelect}
                  >
                    <MenuItem value={-1}>
                      <span style={{ color: '#9f9ca2' }}>الحدود</span>
                    </MenuItem>
                    {this.props.attachmentOptions.map(option => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <div>
                  <p className={classes.attachmentTitle}>المرفقات</p>
                  <div>
                    {this.props.attachments.map((attachment, index) => (
                      <Attachment
                        className={classes.attachment}
                        title={attachment.original_file_name}
                        fileId={attachment.id}
                        userId={this.props.userIdNumber}
                        selected={this.state.selectedAttachment.id === attachment.id}
                        style={index === 0 && { marginTop: 0 }}
                        onClick={() => this.setState({ selectedAttachment: attachment })}
                      />
                    ))}
                  </div>
                </div>
              </FormControl>
            </div>
          </CardBody>
        </div>
        <div className={classes.mediaViewerContainer}>
          <MediaViewer
            fileId={this.state.selectedAttachment.id}
            mimeType={this.state.selectedAttachment.mime_type}
            name={this.state.selectedAttachment.original_file_name}
            userId={this.props.userIdNumber}
            className={classes.mediaViewer}
          />
        </div>
      </Card>
    );
  }
}

AnswerCard.propTypes = {
  attachmentOptions: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  attachmentTypes: PropTypes.array.isRequired,
  chosenOption: PropTypes.number.isRequired,
  chosenType: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  userIdNumber: PropTypes.string.isRequired || PropTypes.number.isRequired,
};

export default AnswerCard;
