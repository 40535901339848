import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// import ZoomIn from '@material-ui/icons/ZoomIn';
import ProcessingRequestsStyle from 'assets/jss/views/ProcessingRequestsStyle';
// core components
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import axios from 'axios';
import { request, URLs } from '../../request';
import EscalationForm from '../../components/Forms/EscalationForm';
import ModalButton from '../../components/core/CustomButtons/ModalButton';
import CurrentRequest from '../../components/Requests/CurrentRequest';
import RejectForm from '../../components/Forms/RejectForm';

class ProcessingCurrentRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reasons: [],
    };
  }

  handleCategoryChange = category => {
    this.setState({ reasons: [] });

    if (category === 0) {
      return;
    }

    request.GET(URLs.backoffice.ESCALATION_REASONS, { category_id: category }).then(res => {
      this.setState({ reasons: res.data[0].reason });
    });
  };

  handleSubmit = (option, range, type) => {
    const bodyParameters = {
      task_id: this.props.task.id,
      processing_request_id: this.props.id,
      financial_option: option,
      range,
      type: this.props.type,
      range_type: type,
    };
    request.POST(URLs.backoffice.SUBMIT_REQUEST_ANSWER, bodyParameters).then(() => {
      this.props.refresh();
    });
  };

  handleRejection = reasons => {
    const id = this.props.task.id;
    const bodyParameters = {
      id,
      task_id: this.props.task.id,
      processing_request_id: this.props.id,
      rejection_reasons: reasons,
      type: this.props.type,
    };
    request.POST(URLs.backoffice.SUBMIT_REQUEST_REJECT, bodyParameters).then(() => {
      this.props.refresh();
    });
  };

  handleEscalation = (category, reason, details) => {
    const bodyParameters = {
      task_id: this.props.task.id,
      processing_request_id: this.props.id,
      escalation: category,
      escalation_reasons: reason,
      escalation_details: details,
      type: this.props.type,
    };
    request.POST(URLs.backoffice.SUBMIT_REQUEST_ANSWER, bodyParameters).then(() => {
      this.props.refresh();
    });
  };

  render() {
    const {
      classes,
      userInformation,
      visaInformation,
      isDisable = false,
      categories,
      attachments,
      referenceId,
      rejectReasons,
    } = this.props;
    const selectReason = [];
    rejectReasons.map(item => selectReason.push({ name: item.label, id: item.id }));
    const Actions = (
      <React.Fragment>
        <ModalButton label="تصعيد" outline classes={classes} type="danger">
          <EscalationForm
            onCategoryChange={this.handleCategoryChange}
            onSubmit={this.handleEscalation}
            categories={categories}
            reasons={this.state.reasons}
            classes={classes}
          />
        </ModalButton>
        <ModalButton label="رفض" type="danger" classes={classes} outline>
          <RejectForm
            classes={classes}
            onSubmit={this.handleRejection}
            rejectReasons={rejectReasons}
            selectReason={selectReason}
          />
        </ModalButton>
      </React.Fragment>
    );

    return (
      <CurrentRequest
        userIdNumber={userInformation.id_number}
        id={referenceId}
        title="تفاصيل الطلب"
        attachments={attachments}
        classes={classes}
        disabilityDetails={{
          isDisable,
          responsibleId: isDisable ? JSON.parse(visaInformation.isDisable).Responsible_ID : null,
          responsibleName: isDisable
            ? JSON.parse(visaInformation.isDisable).Responsible_Name
            : null,
        }}
        userDetails={userInformation}
        onSubmit={this.handleSubmit}
        dateCreated={this.props.dateCreated}
        actions={Actions}
      />
    );
  }
}

ProcessingCurrentRequests.propTypes = {
  attachments: PropTypes.array,
  categories: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dateCreated: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  id: PropTypes.string,
  isDisable: PropTypes.object,
  referenceId: PropTypes.object,
  refresh: PropTypes.func,
  rejectReasons: PropTypes.array,
  task: PropTypes.object,
  type: PropTypes.string.isRequired,
  userInformation: PropTypes.object,
  visaInformation: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    access_token: state,
  };
};
export default compose(
  withStyles(ProcessingRequestsStyle),
  connect(mapStateToProps),
)(ProcessingCurrentRequests);
