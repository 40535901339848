import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import GridItem from 'components/core/Grid/GridItem';

const RequestDates = ({ created, escalated }) => (
  <Grid>
    {created && (
      <GridItem>
        <span>تاريخ الرفع: </span>
        <span> {created} </span>
      </GridItem>
    )}
    {escalated && (
      <GridItem>
        <span>تاريخ التصعيد: </span>
        <span> {escalated} </span>
      </GridItem>
    )}
  </Grid>
);

RequestDates.propTypes = {
  created: PropTypes.string,
  escalated: PropTypes.string,
};

export default RequestDates;
