const initState = null;

const reducer = (state = initState, { type, data }) => {
  switch (type) {
    case 'GET_CURRENT_USER':
      return data;
    case 'LOGOUT':
      return null;
    default:
      return state;
  }
};

export default reducer;
